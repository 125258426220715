<template>
  <div>
    <van-tabs swipeable>
      <van-tab
        title="平安产险附加法定传染病集中治疗观察津贴保险（互联网版）条款"
      >
        <travel-infectious></travel-infectious>
      </van-tab>
      <van-tab title="平安产险特定危重型传染病保险（互联网版）条款">
        <travel-critically></travel-critically>
      </van-tab>
    </van-tabs>
    <bottom-button></bottom-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class Disclaimer extends Vue {
   static name = "Disclaimer";
}
export default Disclaimer;
</script>
<style lang="scss" src="../index.scss" scoped></style>
