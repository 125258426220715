<template>
  <div class="document">
    <p class="font-weight-bold text-align">中国平安财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">
      平安产险特定危重型传染病保险（互联网版）条款
    </p>
    <p class="font-weight-bold text-align">注册号：C00001732612021120909223</p>
    <p class="font-weight-bold text-align">责任免除</p>
    <p>下列情形下，保险人不承担给付保险金责任：</p>
    <p>
      （一）被保险人在保险期间开始前以及首次投保保险期间开始后保险单载明等待期内：
    </p>
    <p>1．确诊罹患本保险合同约定的特定传染病；</p>
    <p>2．疑似罹患本保险合同约定的特定传染病；</p>
    <p>3．因与本保险合同约定的特定传染病病人或疑似病人密切接触而被隔离的；</p>
    <p>（二）投保人、被保险人的故意行为；</p>
    <p>
      （三）被保险人患有遗传性疾病、先天性疾病、先天性畸形、变形或染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD-10）》为准）；
    </p>
    <p>（四）被保险人主动吸食或注射毒品的；</p>
    <p>（五）核爆炸、核辐射或者核污染；</p>
    <p>（六）被保险人未经释义医院确诊感染法定传染病的；</p>
    <p>（七）确诊罹患非本保险合同约定的特定传染病；</p>
    <p>（八）被保险人未遵医嘱服用、涂用、注射药物；</p>
    <p class="font-weight-bold text-align">中国平安财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">
      平安产险交通工具意外伤害保险（互联网版）条款
    </p>
    <p class="font-weight-bold text-align">注册号：C00001732312021120910133</p>
    <p class="font-weight-bold text-align">责任免除</p>
    <p>
      因下列原因造成被保险人身故、伤残或医疗费用支出的，保险人不承担给付保险金责任：
    </p>
    <p>
      发生事故后48小时内拨打阳光财险客服热线95510，阳光财险将为您提供理赔指引服务。
    </p>
    <p>
      （二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外
    </p>
    ；
    <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>
    <p>（四）被保险人妊娠、流产、分娩、疾病、药物过敏、中暑、猝死；</p>
    <p>（五）被保险人未遵医嘱，私自服用、涂用、注射药物；</p>
    <p>（六）核爆炸、核辐射或核污染；</p>
    <p>（七）被保险人犯罪或拒捕；</p>
    <p>（八）被保险人从事高风险运动或参加职业或半职业体育运动；</p>
    <p>（九）被保险人违反承运人或交通安全部门关于安全乘坐的规定。</p>
    <p>
      被保险人在下列期间遭受伤害导致身故、伤残或医疗费用支出的，保险人不承担给付保险金责任：
    </p>
    <p>（一）战争、军事行动、暴动或武装叛乱期间；</p>
    <p>（二）被保险人主动吸食或注射毒品期间；</p>
    <p>（三）被保险人非以乘客的身份置身于任何交通工具期间；</p>
    <p>（四）被保险人乘坐非商业营运的火车、轮船或汽车期间。</p>
    <p>第十条 下列费用，保险人不承担给付保险金责任：</p>
    <p>
      （一）保险单签发地社会医疗保险或其他公费医疗管理部门规定的自费项目，如自费药品费用等；
    </p>
    <p>（二）因椎间盘膨出和突出造成被保险人支出的医疗费用；</p>
    <p>
      （三）营养费、康复费、辅助器具费、整容费、美容费、修复手术费、牙齿整形费、牙齿修复费、镶牙费、护理费、交通费、伙食费、误工费、丧葬费。
    </p>
    <p class="font-weight-bold text-align">中国平安财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">
      平安产险附加法定传染病集中治疗观察津贴保险（互联网版）条款
    </p>
    <p class="font-weight-bold text-align">注册号：C00001732522021122438073</p>
    <p class="font-weight-bold text-align">责任免除</p>
    <p>发生下列情形之一的，保险人不承担给付保险金责任：</p>
    <p>
      （一）保险期间开始前、或在本附加保险合同生效之日起的等待期满以前（含等待期最后一天）：
    </p>
    <p>1．被保险人确诊罹患法定传染病；</p>
    <p>2．被保险人疑似罹患法定传染病，在等待确诊结果；</p>
    <p>3．被保险人因与确诊病人或疑似病人密切接触而被隔离的；</p>
    <p>
      （一）投保本附加保险合同前或出行前旅行出发地、途经地或目的地已被我国政府、当地政府或疾病预防控制机构列为中、高风险地区的；
    </p>
    <p>（二）非经疾病预防控制中心或政府相关部门要求的自愿隔离和自愿观察；</p>
    <p>（三）居家隔离治疗、居家医学观察。</p>
    <p>因下列原因导致的保险事故，保险人不承担给付保险金责任：</p>
    <p>（一）投保人、被保险人的故意行为；</p>
    <p>
      （二）被保险人违反《中华人民共和国传染病防治法》或相关疫情防控法律法规、行政条令条例；
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class TravelDisclaimer extends Vue {
  static name = "travel-disclaimer";
}
export default TravelDisclaimer;
</script>
<style lang="scss" src="./index.scss" scoped></style>
