import { helpers } from "@/plugins";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Loading, Overlay, Empty, Field } from "vant";
import "lib-flexible";
import VueResource from 'vue-resource';


Vue.config.productionTip = false;

Vue.use(helpers);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Field);
Vue.use(VueResource);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
