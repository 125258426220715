<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加意外伤害骨折保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432322021122029723</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同附加于含意外伤害保险责任的各类个人类人身保险合同（以下简称“主险合同”）。本保险合同由保险条款、投保单、保险单、保险凭证以及批单等组成。凡涉及本保险合同的约定，均应采用书面形式。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>
      &#12288;&#12288;第二条
      在本保险合同的保险期间内，被保险人遭受意外伤害，并因此致本保险合同所附《意外伤害骨折和关节脱位类型与保险金给付比例表》（简称《给付表》）所列骨折或关节脱位之一的，保险人按保险单所载的该被保险人保险金额及该项骨折或关节脱位所对应的给付比例给付保险金。<span
        >如被保险人的骨折或关节脱位类型不在所附《给付表》之列，保险人不承担给付保险金责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;被保险人因同一意外伤害事故导致一项以上骨折或关节脱位时，保险人给付其中比例较高一项的保险金。
    </p>
    <p>
      &#12288;&#12288;<span
        >保险人对于被保险人保险金的给付总额，以保险单所载明的该被保险人的保险金额为限。一次或累计给付的保险金达到保险金额时，保险人对该被保险人的上述保险责任终止。</span
      >
    </p>

    <p class="font-weight-bold text-align">&#12288;&#12288;责任免除</p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;第三条　本保险合同的责任免除如下：</p>
      <p>
        &#12288;&#12288;一、主险合同无效或失效，保险人不负任何给付保险金责任。
      </p>
      <p>
        &#12288;&#12288;二、主险合同中列明的“责任免除”事项，也适用于本保险合同。
      </p>
      <p>&#12288;&#12288;三、下列情形下，保险人不负任何给付保险金责任：</p>
      <p>&#12288;&#12288;（一）被保险人投保前已有的既往症；</p>
      <p>
        &#12288;&#12288;（二）被保险人先天性关节脱位、病理性脱位、习惯性脱位、陈旧性脱位或复发性脱位；
      </p>
      <p>
        &#12288;&#12288;（三）被保险人病理性骨折（指有病骨骼遭受轻微外力即发生的骨折）或被诊断为骨质疏松并因该病症而导致的骨折或关节脱位。
      </p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额和保险费</p>
    <p>
      &#12288;&#12288;第四条
      本保险合同的保险金额由投保人、保险人双方约定，并在保险单中载明。保险金额一经确定，中途不得变更。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金申请与给付</p>
    <p>
      &#12288;&#12288;第五条
      保险金申请人向保险人申请给付保险金时，应提交以下材料。保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。
    </p>
    <p>&#12288;&#12288;一、保险金给付申请书；</p>
    <p>&#12288;&#12288;二、保险单原件；</p>
    <p>&#12288;&#12288;三、保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;四、中华人民共和国境内二级以上（含二级）或保险人认可的医疗机构出具的骨折或关节脱位诊断证明、相关的病历记录、X
      光片或CT 光片及其他医学影像诊断报告书或手术证明；
    </p>
    <p>
      &#12288;&#12288;五、事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>
      &#12288;&#12288;六、保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;七、若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;条款适用</p>
    <p>
      &#12288;&#12288;第六条
      本保险合同所记载事项，如与主险合同相抵触之处，以本保险合同为准，未尽事宜，适用主险合同的规定。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>
      &#12288;&#12288;第七条 除另有约定外，本保险合同中的下列词语具有如下含义：
    </p>
    <p>
      &#12288;&#12288;骨折：指由于意外事故单独且直接导致骨的完整性及连续性的破坏且相应骨的完全断裂，包括发生于椎体的压缩性骨折，但不包括骨的不完全断裂，如裂缝骨折、青枝骨折、颅盖骨线形骨折。
    </p>
    <p>&#12288;&#12288;</p>
    <p>&#12288;&#12288;给付表：意外伤害骨折和关节脱位类型与保险金给付比例表</p>
    <p>&#12288;&#12288;骨折或关节脱位项目 骨折类型 给付比例</p>
    <p>&#12288;&#12288;</p>
    <p>&#12288;&#12288;颧骨、上颌骨、鼻骨骨折 开放性骨折（注3） 8%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 5%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 3%</p>
    <p>&#12288;&#12288;下颌骨骨折 开放性骨折（注3） 12%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 10%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 5%</p>
    <p>&#12288;&#12288;颅骨（注5）骨折 凹陷骨折（须经手术治疗） 10%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 8%</p>
    <p>
      &#12288;&#12288;肋骨（注12）、胸骨骨折
      多处骨折（注2），至少一处为开放性骨折（注3） 10%
    </p>
    <p>&#12288;&#12288; 单处开放性骨折 （注3） 8%</p>
    <p>&#12288;&#12288; 多处闭合性骨折 （注4） 5%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 3%</p>
    <p>&#12288;&#12288;锁骨、肩胛骨骨折 开放性骨折（注3） 20%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 15%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 8%</p>
    <p>
      &#12288;&#12288;椎骨（注10）骨折（包括颈椎、胸椎、腰椎骨折，但不包括骶骨或尾骨骨折）
      椎体压缩性骨折（注11）且棘突、横突或椎弓根骨折 30%
    </p>
    <p>&#12288;&#12288; 椎体压缩性骨折（注11）或棘突、横突或椎弓根骨折 15%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 8%</p>
    <p>&#12288;&#12288;骨盆（注1）骨折 开放性骨折（注3） 60%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 40%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 15%</p>
    <p>&#12288;&#12288;尾骨骨折 开放性骨折（注3） 8%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 3%</p>
    <p>
      &#12288;&#12288;肱骨、桡骨、尺骨骨折（但不包括桡骨远端骨折） 桡尺骨双骨折
      30%
    </p>
    <p>&#12288;&#12288; 开放性骨折（注3） 25%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 20%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 10%</p>
    <p>&#12288;&#12288;桡骨远端骨折 开放性骨折（注3） 15%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 8%</p>
    <p>&#12288;&#12288;腕骨（注6）骨折 开放性骨折（注3） 20%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 15%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 8%</p>
    <p>&#12288;&#12288;掌骨（注7）、指骨（注14）骨折 开放性骨折（注3） 8%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 3%</p>
    <p>&#12288;&#12288;股骨骨折 股骨颈骨折 50%</p>
    <p>&#12288;&#12288; 开放性骨折（注3） 40%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 25%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 12%</p>
    <p>&#12288;&#12288;胫骨、腓骨骨折 胫腓骨双骨折 35%</p>
    <p>&#12288;&#12288; 开放性骨折（注3） 30%</p>
    <p>&#12288;&#12288; 闭合性骨折（注4）行切开复位（注17） 25%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 10%</p>
    <p>
      &#12288;&#12288;跖骨（注8）、跗骨（注9）、趾骨（注13）、跟骨骨折
      开放性骨折（注3） 8%
    </p>
    <p>&#12288;&#12288; 本项目下其他骨折 3%</p>
    <p>&#12288;&#12288;髌骨、踝关节骨折 开放性骨折（注3） 20%</p>
    <p>&#12288;&#12288; 本项目下其他骨折 10%</p>
    <p>&#12288;&#12288;上肢关节脱位（注15） 肘关节脱位 15%</p>
    <p>&#12288;&#12288; 肩关节脱位 15%</p>
    <p>&#12288;&#12288;下肢关节脱位（注15） 膝关节脱位 25%</p>
    <p>&#12288;&#12288; 髋关节脱位 50%</p>
    <p>&#12288;&#12288;关节替换 人工全髋关节替换 100%</p>
    <p>&#12288;&#12288; 人工全膝关节替换(单膝) 50%</p>
    <p>&#12288;&#12288;注：</p>
    <p>
      &#12288;&#12288;1.
      骨盆作为同一块骨处理，包括耻骨、髂骨、坐骨、骶骨，不包括尾骨。
    </p>
    <p>&#12288;&#12288;2. 多处骨折指同一骨上有一处以上的骨折。</p>
    <p>
      &#12288;&#12288;3.
      开放性骨折指骨折附近的皮肤和粘膜破裂，骨折处与外界相通。
    </p>
    <p>
      &#12288;&#12288;4. 闭合性骨折指骨折时骨折处皮肤或粘膜完整，不与外界相通。
    </p>
    <p>
      &#12288;&#12288;5. 颅骨作为同一块骨处理，包括额、顶、枕、筛、颞、蝶骨。
    </p>
    <p>&#12288;&#12288;6. 所有同侧腕骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;7. 所有同侧掌骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;8. 所有同侧跖骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;9. 所有同侧跗骨作为同一块骨处理。</p>
    <p>
      &#12288;&#12288;10.
      所有椎骨作为同一块骨处理，包括椎体、棘突、横突和椎弓根。
    </p>
    <p>&#12288;&#12288;11. 压缩性骨折指因外力导致松质骨因压缩而变形。</p>
    <p>&#12288;&#12288;12. 所有肋骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;13. 所有同侧趾骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;14. 所有同侧指骨作为同一块骨处理。</p>
    <p>&#12288;&#12288;15. 关节脱位特指施行切开复位手术的关节脱位。</p>
    <p>
      &#12288;&#12288;16.
      因意外事故单独或直接导致肢体的断离则按照断离处骨的开放性骨折给付，肢体断离处远端任何骨的骨折将不获给付
    </p>
    <p>
      &#12288;&#12288;17.
      切开复位指施行手术，切开骨折部的软组织，暴露骨折断端，在直视下将骨折复位。不包括清创术、植骨、骨头切除、骨片切除、假体置换术、陈旧性骨折切开复位或陈旧性关节脱位切开复位等。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentFracture extends Vue {
   static name = "accident-fracture";
}
export default AccidentFracture;
</script>
<style lang="scss" src="./index.scss" scoped></style>
