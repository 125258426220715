<template>
  <van-row class="insure-title">
    <van-col span="1" class="number" v-if="value.orderNumber">{{
      value.orderNumber
    }}</van-col>
    <van-col span="5" class="title">{{ value.title }}</van-col>
    <van-col span="6" class="relation" v-if="value.relation">
      （{{ value.relation }}）
    </van-col>
  </van-row>
</template>
<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import { components } from "./options";

@Component({
  components,
})
class InsureTitle extends Vue {
  static name = "insure-title";
  @Prop({ type: Object, default: () => {} }) value;
}
export default InsureTitle;
</script>
<style lang="scss" src="./index.scss" scoped></style>
