<template>
  <van-goods-action class="box">
    <van-goods-action-button @click="action" type="primary" :text="text" />
  </van-goods-action>
</template>

<script>
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { components } from "./options";

@Component({
  components,
})
class BottomButton extends Vue {
  static name = "bottom-button";
  @Prop({ type: String, default: "确认以上无问题" }) text;
  @Emit()
  action() {
    if (!this.$listeners.action) {
      this.$router.back();
    }
  }
}
export default BottomButton;
</script>

<style lang="scss" scope>
.box {
  padding: 20px;
  .van-goods-action-button--last {
    border-radius: 3px;
    height: 48px;
    @include fontSizeColor(18px, #fff, $bold);
  }
}
</style>
