<template>
  <div class="document">
    <p class="font-weight-bold text-align">保险公司信息披露</p>
    <p>1.本产品为阳光财险家庭财产险，由阳光财产保险股份有限公司承保，该公司在北京、山东、江苏、黑龙江、重庆、河南、上海、广东、深圳、天津、青岛、辽宁、河北、海南、广西、湖南、浙江、贵州、四川、青海、云南、陕西、湖北、吉林、新疆、宁波、山西、大连、甘肃、安徽、江西、福建、内蒙古、厦门、宁夏、西藏设有分公司。</p>
    <p>2.本产品适用条款：家庭财产综合保险A款条款（2015版）（阳光财险）（备-家财）[2015]（主）13号、附加管道破裂及水渍第三者责任保险A款条款（2015版）（阳光财险）（备-责任）[2015]（附）6号、附加管道破裂及水渍损失保险A款条款（2015版）（阳光财险）（备-家财）[2015]（附）5号。</p>
    <p>3.销售区域：本产品保障区域限于中国境内，不包括港澳台地区。</p>
    <p>【服务流程】</p>
    <p>1.保单形式：网上投保为你提供电子保单，根据《民法典》规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。或登录'http://wecare.sinosig.com/common/html'，对电子保单的真实性进行验证。</p>
    <p>2.发票形式：本产品提供电子发票，电子发票效力等同于纸质发票，报销可将电子发票打印后直接报销。如您需要发票，请拨打95510进行申请。</p>
    <p>3.承保形式：如你填写个人投保信息，阳光产险在收到投保信息后进行核保，若核保通过后，阳光产险收到保费后出具保单，保险合同成立；若核保不通过，则投保人无需支付保费，保险合同不成立。</p>
    <p>4.保单退保：请拨打95510进行申请，会有专人和您联系。请按要求提供相关资料，审核通过后完成办理。</p>
    <p>5.保单批改：请拨打95510进行申请，会有专人和您联系。请按要求提供相关资料，审核通过后完成办理。</p>
    <p>6.理赔流程：</p>
    <p>第1步：理赔报案</p>
    <p>发生事故后48小时内拨打阳光财险客服热线95510，阳光财险将为您提供理赔指引服务。</p>
    <p>第2步：提交材料</p>
    <p>根据阳光财险的要求准备理赔材料，将材料寄送至阳光财险。</p>
    <p>第3步：材料审核</p>
    <p>阳光财险收到完备的材料后，及时进行审核和调查。</p>
    <p>第4步：完成理赔</p>
    <p>审核通过后支付理赔款至保险金申请人指定账户。</p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class message extends Vue {
  static name = "message";
}
export default message;
</script>
<style lang="scss" src="./index.scss" scoped></style>
