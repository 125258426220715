<template>
  <div class="document">
    <p class="font-weight-bold text-align">投保须知</p>
    <p>【基本信息】</p>
    <p>
      <span>1) 条款适用:</span
      >本产品适用条款为中国太平洋财产保险股份有限公司《个人账户资金损失保险条款》（注册号：H00001432112016121354851）。
    </p>
    <p>
      <span>2) 保单形式:</span
      >网上投保为你提供电子保单，根据《中华人民共和国民法典》规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。你可以关注“太平洋保险e服务”微信公众号或登录www.cpic.com.cn自助查询，对电子保单的真实性进行验证。
    </p>
    <p>
      <span>3) 发票形式:</span
      >本产品仅提供电子发票，电子发票效力等同于纸质发票，报销可将电子发票打印后直接报销。
    </p>
    <p>
      <span>4) 偿付能力告知:</span
      >太平洋产险最近季度偿付能力符合监管要求，详情请参见我司官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      <span>5) 风险综合评价:</span
      >太平洋产险风险管理能力评估结果位居行业前列，详情请参见我司官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      <span
        >6)
        本产品投保咨询、保单查询及客户投诉，均可拨打太平洋全国统一客服电话95500。</span
      >
    </p>

    <p>【产品说明】</p>
    <p>
      <span
        >1)
        在保险期间内，对于被保险人的个人账户在挂失或冻结前72小时以外的损失，保险人不负责赔偿；</span
      >
    </p>
    <p>
      <span>2) 投保人：</span
      >本产品投保人年龄为年满18周岁有完全民事行为能力，且对被保险人具有可保利益的自然人；
    </p>
    <p>
      <span>3) 被保险人：</span
      >本保险的被保险人为年龄在16-100周岁（皆含）、合同载明的个人账户的所有人和信用卡附属卡的合法持有人；。
    </p>
    <p>
      <span>4) 受益人：</span
      >本保险合同的身故受益人为法定受益人，其他保险责任的受益人为被保险人本人；
    </p>
    <p><span>5) 购买份数：</span>每位被保险人仅限投保一份，多投无效；</p>
    <p><span>6) 保险期限：</span>本产品的保险期间为3个月；</p>
    <p><span>7) 犹豫期：</span>本产品无犹豫期，请您慎重选择投保。</p>
    <p>
      <span
        >8）本产品所有的页面文字描述为展示作用，具体保障方案等信息以投保后生成的电子保单为准，保险公司保留在法律规定范围内的解释权利。</span
      >
    </p>

    <p>【服务流程】</p>
    <p>
      <span>1、投保流程：</span
      >在线投保——填写投保信息——核保通过并支付——查收保单。
    </p>
    <p>
      <span>2、保单查询：</span
      >1）投保成功后我们将通过您预留的电子邮箱地址为您发送电子保单；2）投保完成30分钟后，可拨打太平洋保险客服电话95500-1-3进行保单查询；3）进入太平洋保险官方网站（http://www.cpic.com.cn/），进行保单查询。
    </p>
    <p>
      <span>3、变更：</span
      >若您需要批改保单，请投保人（仅限投保人）提供个人身份证件（身份证、护照等）的扫描/拍照件，并填写客服发送的申请材料（需要投保人填写信息、签字确认后发送扫描/拍照件）后，我们在3个工作日内为您办理批改申请。
    </p>
    <p><span>4、退保：</span></p>
    <p>
      （1）请拨打太保产险全国统一客服电话：95500，转太保产险人工客服，或线下柜面进行办理；
    </p>
    <p>
      （2）需要提供投保人身份证正反面照片、银行卡照片，并填写太保产险客服发送的申请材料；
    </p>
    <p>（3）保费退回方式：投保人银行卡（需要提供银行卡号、开户行信息）</p>
    <p>
      （4）保费退回时间：保费将于办理成功后30个工作日内，退还到投保人提供的本人银行账户。
    </p>
    <p>温馨提示：</p>
    <p>1.保单生效日前申请退保：保险公司全额退还保险费。</p>
    <p>
      2.保单生效日后申请退保，按照所购买保险产品的保险合同条款规定退还部分保费，这会造成您的一定损失。
    </p>
    <p>
      <span>5、理赔流程：</span
      >出险报案（全国统一客服热线：95500-1-3）——专业理赔指引——-递交索赔材料——案件审核处理——赔款到账。
    </p>
    <p><span>6、发票开具：</span></p>
    <p>1）投保成功后我们将通过您预留的电子邮箱地址为您发送电子发票；</p>
    <p>2）可拨打太平洋保险客服电话95500-1-3申请开具发票。</p>
    <p>
      3）开具发票默认为电子发票，电子发票与纸质发票具有同等法津效力。若您须开具纸质发票，保险公司另提供快递服务，该项服务所产生的快递费，在签收邮件时由您自行承担。
    </p>
    <p>4）发票抬头类型仅支持个人且发票抬头默认为投保人。</p>

    <div class="font-weight-bold">
      <p>【其他事项】</p>
      <p>
        如您投保本产品，在投保本产品前您应履行相应的如实告知义务，具体如下：投保人或被保险人应如实填写投保信息，并就保险公司提出的询问据实告知，否则保险公司有权根据《中华人民共和国保险法》第十六条的规定处理；订立保险合同时，保险公司就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险公司决定是否同意承保或者提高保险费率的，保险公司有权解除合同。投保人故意不履行如实告知义务的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费。投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，但退还保险费。
      </p>
      <p>
        如您投保本产品，基于提供保险服务、提高服务质量的需要，您授权：本公司及本公司的合作机构在承保前后核实投保信息的真实性、调查获取被保险人与保险有关的相关信息(包括但不限于健康情况、诊疗情况、既往病史等)，如本公司经前述核查发现您存在未如实告知情况的，本公司将依法解除保险合同；本公司向与具有必要合作关系的机构提供您的信息（包括但不限于投保、承保、理赔、医疗等）；本公司及本公司的合作机构可对您的信息进行合理使用，可通过知悉您信息的机构查询与您有关的全部信息。为确保信息安全，本公司及其本公司的合作机构应采取有效措施，并承担保密义务。
      </p>
      <p>
        在必要情形下第三方可能接触并使用您的个人信息，包括得到授权的太保产险员工、以及执行与我们的业务、营销活动和数据整理有关工作的其他公司或人员。所有此类人员及公司均需遵守相关保密协议，同时也需遵守国家关于个人信息保护有关法律法规，以确保您的个人信息随时得到保护。除上述用途外，太保产险不会将您的个人信息用于任何未经您同意的用途。除了我们的业务合作伙伴、我们的法律顾问、外部审计机构或按照法律规定、监管规定或法庭裁决之外，我们不会将所接受的任何个人信息泄露、篡改、毁损、出售或者提供给任何第三方。
      </p>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class PersonalPropertyNotice extends Vue {
      static name = "personal-property-notice";
}
export default PersonalPropertyNotice;
</script>
<style lang="scss" src="./index.scss" scoped></style>
