<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加意外伤害医疗保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432522021122437143</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;第一部分 基本条款</p>
    <p class="font-weight-bold">&#12288;&#12288;第一条 合同效力</p>
    <p>
      &#12288;&#12288;本保险合同附加于含意外伤害保险责任的各类个人类人身保险合同（以下简称“主险合同”），依主险合同投保人的申请，经保险人审核同意而订立。主险合同效力终止，本保险合同效力亦终止；主险合同无效，本保险合同亦无效。主险合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以主险合同为准。
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第二条 被保险人</p>
    <p>&#12288;&#12288;凡主险合同的被保险人，均可作为本保险合同的被保险人。</p>
    <p class="font-weight-bold">&#12288;&#12288;第三条 受益人</p>
    <p>
      &#12288;&#12288;除另有约定外，本保险合同的保险金受益人为被保险人本人。
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第四条 合同终止</p>
    <p>&#12288;&#12288;发生下列情形之一，本保险合同即时终止：</p>
    <p>&#12288;&#12288;一、主险合同无效、解除、终止或保险期间届满；</p>
    <p>&#12288;&#12288;二、本保险合同保险期间届满；</p>
    <p>&#12288;&#12288;三、本保险合同约定的其他情形。</p>
    <p>
      &#12288;&#12288;主险合同无效、解除、终止而导致本保险合同终止的，保险人向投保人退还未满期净保险费，<span
        >但保险人已根据本保险合同约定给付保险金的除外；主险合同按约定不退还保险费的，本保险合同也不退还未满期净保险费。</span
      >
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第二部分 保险责任和责任免除
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第五条 保险责任</p>
    <p>
      &#12288;&#12288;在本保险合同的保险期间内，被保险人遭受主险合同责任范围内的意外伤害，在保险人认可的医疗机构治疗所支出的符合本保险单签发地政府社会医疗保险主管部门规定可以报销的合理且必要的医疗费用，保险人按下列约定承担保险金给付责任：
    </p>
    <p>
      &#12288;&#12288;一、保险人对于每次事故的医疗费用，在保险金额内按以下公式计算应给付的意外伤害医疗保险金。保险期间届满被保险人治疗仍未结束的，保险人继续承担保险金给付保险责任，除另有约定外，住院治疗者最长至意外伤害发生之日起第一百八十日止，门诊治疗者最长至意外伤害发生之日起第十五日止。
    </p>
    <p>
      &#12288;&#12288;<span
        >应当给付的保险金=(被保险人发生的医疗费用的有效金额-免赔额)*给付比例</span
      >
    </p>
    <p>&#12288;&#12288;说明：</p>
    <p>
      &#12288;&#12288;（1）被保险人发生的医疗费用的有效金额=被保险人每次就诊发生的合理且必要的属于保险责任范围内的医疗费用-被保险人从公费医疗、基本医疗保险、城乡居民大病保险取得的医疗费用补偿金额总和-被保险人从工作单位、保险人在内的商业保险公司等任何其他途径取得的医疗费用补偿金额总和。多次就诊被保险人累计发生的医疗费用的有效金额等于单次就诊被保险人发生的医疗费用的有效金额相加。
    </p>
    <p>
      &#12288;&#12288;（2）免赔额：指每次事故免赔金额。<span
        >除另有约定外，本合同的免赔额为100元。</span
      >
    </p>
    <p>
      &#12288;&#12288;（3）给付比例：指医疗费用给付比例，<span
        >除另有约定外，本合同的给付比例为80%。若被保险人已从保险人在内的商业保险机构获得医疗费用补偿，则给付比例较保险单约定的给付比例增加5个百分点，但同时，增加后的给付比例最高不超过100%。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >免赔额、给付比例分别按照被保险人是否享有社会医疗保险或公费医疗保障由投保人和保险人在投保时约定。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >二、在保险期间内，保险人按照本条约定对被保险人所负的意外伤害医疗保险金给付责任以保险单所载被保险人的意外伤害医疗保险金额为限，一次或累计给付的保险金达到意外伤害医疗保险金额时，保险人对被保险人的本条保险责任终止。</span
      >
    </p>
    <p>
      &#12288;&#12288;三、本保险合同为费用补偿型保险合同，适用医疗费用补偿原则。<span
        >若被保险人除本保险合同外还可从其它保险计划（包括但不限于社会医疗保险、公费医疗、工作单位、保险人在内的任何商业保险机构等）获得医疗费用补偿，保险人以意外伤害医疗保险金额为限，对被保险人获得补偿后的医疗费用的余额按照合同约定给付保险金。</span
      >
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第六条 责任免除</p>
    <div>
      <p>
        &#12288;&#12288;一、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。
      </p>
      <p>
        &#12288;&#12288;二、下列情形或者下列费用，保险人不负任何给付保险金责任：
      </p>
      <p>&#12288;&#12288;（一）非因主险合同所列意外伤害事故而发生的治疗；</p>
      <p>
        &#12288;&#12288;（二）用于矫形、整容、美容、器官移植或修复、安装及购买残疾用具（如轮椅、假肢、助听器、假眼、配镜等）的费用；
      </p>
      <p>
        &#12288;&#12288;（三）被保险人体检、疗养、心理咨询或康复治疗的费用；
      </p>
      <p>&#12288;&#12288;（四）被保险人在非保险人认可的医疗机构的治疗费用；</p>
      <p>
        &#12288;&#12288;（五）交通费、食宿费、生活补助费，及被保险人的误工补贴费。
      </p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;第三部分 保险期间</p>
    <p class="font-weight-bold">&#12288;&#12288;第七条 保险期间</p>
    <p>
      &#12288;&#12288;除另有约定外，本保险合同保险期间为一年，自保险单载明的起始日零时开始，至约定的终止日24时止。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第四部分 保险金的申请
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第八条 保险金的申请</p>
    <p>
      &#12288;&#12288;一、保险金申请人向保险人申请给付保险金时，应提交以下材料。<span
        >保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span
      >
    </p>
    <p>&#12288;&#12288;（一）保险金给付申请书；</p>
    <p>&#12288;&#12288;（二）保险单原件；</p>
    <p>&#12288;&#12288;（三）保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;（四）保险人认可的医疗机构出具的附有病理检查、化验检查及其他医疗仪器检查报告的医疗诊断证明、病历及医疗、医药费原始单据、结算明细表和处方；<span
        >如投保时被保险人有社会基本医疗保险或公费医疗，但保险金申请人未能提供社会基本医疗保险或公费医疗保障的医疗费用的补偿证明，保险人均视同被保险人未参加社会基本医疗保险或公费医疗保障的情况给付保险金，给付比例将按保险单约定的给付比例降低20个百分点；</span
      >
    </p>
    <p>
      &#12288;&#12288;（五）事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>
      &#12288;&#12288;（六）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;（七）若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p>
      &#12288;&#12288;二、保险人认为保险金申请人提供的有关索赔的证明和资料不完整的，将及时一次性通知补充提供。
    </p>
    <p>
      &#12288;&#12288;三、被保险人支出医疗费用并提出索赔申请后，应向保险人提交相关病历记录及医疗费用收据原件。当赔付金额未达实际支出医疗费用的全额时，索赔申请人可以书面形式向保险人申请发还收据原件。保险人在加盖印戳并注明已赔付金额后发还收据原件。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第五部分 投保人解除保险合同
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;第九条
      若投保人解除主险合同，本保险合同一并解除，合同解除后，保险人参照主险合同有关规定退还本保险合同的未满期净保险费。如投保人申请解除本保险合同，须填写解除合同申请书并向保险人提供下列资料：
    </p>
    <p>&#12288;&#12288;一、保险合同；</p>
    <p>&#12288;&#12288;二、投保人的有效身份证件。</p>
    <p>
      &#12288;&#12288;自保险人收到解除合同申请书时起，本保险合同终止。保险人自收到解除合同申请书之日起
      30 日内向投保人退还本保险合同的未满期净保险费。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;第六部分 其他事项</p>
    <p class="font-weight-bold">&#12288;&#12288;第十条 注意事项</p>
    <p>
      &#12288;&#12288;一、被保险人须在保险人认可的医疗机构治疗。意外伤害急救不受此医疗机构级别的限制，但经急救情况稳定后，须转入保险人认可的医疗机构治疗。
    </p>
    <p>
      &#12288;&#12288;二、被保险人因医疗条件限制，确需转院治疗，必须有转出医院主治医师以上级别人员签署的会诊报告及转院证明。
    </p>
    <p>
      &#12288;&#12288;三、本保险合同所记载事项，如与主险合同相抵触之处，以本保险合同为准，未尽事宜，适用主险合同的规定。
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第十一条 释义</p>
    <p>&#12288;&#12288;除另有约定外，本保险合同中的下列词语具有如下含义：</p>
    <p>
      &#12288;&#12288;合理且必要的医疗费用：指意外伤害治疗期间发生的符合保险单签发地政府当时适用的《基本医疗保险药品目录》、《基本医疗保险诊疗项目目录》、《基本医疗保险医疗服务设施项目范围》及相关规定的医疗费用，<span
        >不含以下费用：</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >一、按规定使用某些药品、进行特殊检查和特殊治疗时，需个人先行自付一定比例的医疗费用；</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >二、按规定转外就医需个人提高自负一定比例的医疗费用；</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >三、基本医疗保险制度规定以外的个人自费的医疗费用。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>保险人认可的医疗机构:</span>保险公司在保险单、批单或者批注中列明的医疗机构。未约定定点医院的，则指中华人民共和国境内（港、澳、台地区除外）合法经营的二级以上（含二级）公立医院。
    </p>
    <p>
      &#12288;&#12288;社会医疗保险
      ：指包括城镇职工基本医疗保险、城镇居民基本医疗保险、新型农村合作医疗等政府举办的基本医疗保障项目。
    </p>
    <p>
      &#12288;&#12288;未满期净保险费：未满期净保险费＝净保险费×[1-(保险单已经过天数/保险期间天数)]=保险费×（1-费用比例）×[1-(保险单已经过天数/保险期间天数)]。经过日数不足一日的按一日计算。
    </p>
    <p>
      &#12288;&#12288;净保险费指投保人所交纳的保险费扣除每张保险单平均承担的保险人各项费用（含营业费用、代理费、各项税金、保险保障基金等）后的余额，费用比例同主险规定。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentMedical extends Vue {
   static name = "accident-medical";
}
export default AccidentMedical;
</script>
<style lang="scss" src="./index.scss" scoped></style>
