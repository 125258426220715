<template>
  <div class="document">
    <p class="font-weight-bold text-align">保险公司信息披露</p>
    <p>1.承保主体：本产品由中国平安财产保险股份有限公司上海分公司（以下简称“保险公司”）承保，面向全国（不含港澳台）进行销售，保险公司已具备全流程线上服务的能力。目前保险公司在全国地区均设有分支机构，<span>保险公司会在用户服务和时效上提供竭力保障，任何疑问可拨打保险公司7*24小时客户服务热线95511。投保人本人已明确知悉并确认。</span></p>
    <p>2.合同形式：保险合同以电子保险合同的形式订立,您可通过预留的邮箱、"平安好生活APP-我的-我的保单"、"平安保险商城公众号-用服务-更多保单查询”、"平安保险商城移动官网我的-保单查询"查阅确认电子保险合同。</p>
    <p>3.保险费的支付方式：为更好地保障投保人的合法权益,本投保流程须由投保人本人确认,并通过投保人本人的银行卡、微信或支付宝等方式完成支付(具体支付方式以产品投保流程中的实际展示为准)。</p>
    <p>4.发票形式：本产品提供电子发票，电子发票效力等同于纸质发票。报销时，可将电子发票打印后直接报销。如需纸质发票请联系客服，邮寄费用由投保人自行承担。</p>
    
    <p>5.如实告知：在投保本产品前您应履行相应的如实告知义务。根据《中华人民共和国保险法》第十六条的规定，订立保险合同时，保险公司就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险公司决定是否同意承保或者提高保险费率的，保险公司有权解除合同。<span>投保人故意不履行如实告知义务的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费。投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，但退还保险费。</span></p>
    <p>6.相关授权：基于提供保险服务、提高服务质量的需要，本人同意授权：</p>
    <p><span>（1）保险公司及保险公司的合作机构在承保前或承保后以必要方式核实投保信息的真实性,可通过知悉投保人/被保险人信息的机构以法律允许的方式调查获取与保险服务有关的必要个人信息(包括但不限于与投保、承保、理赔相关的健康情况、诊疗情况、既往病史等)；</span></p>
    <p><span>（2）保险公司向与保险公司具有必要合作关系的机构提供上述个人信息；</span></p>
    <p><span>（3）保险公司及保险公司的合作机构可对上述个人信息仅限保单及其服务相关用途范围内进行合理必要使用；</span></p>
    <p><span>（4）为确保信息安全，保险公司及保险公司的合作机构根据上述用途使用上述个人信息时应严格按照法律关于个人信息保护的规定，采取有效措施承担保密义务；</span></p>
    <p><span>（5）保险公司通过短信、Email等联系方式提供保险服务及产品信息。</span></p>
    <p>7.信息安全：目前保险公司互联网保险业务，均已采用加密传输协议（https）或证书方式进行信息加密传输，通过技术和管理手段，对涉及投保人和被保险人的信息安全加以保障，保护用户个人信息安全。</p>
    <p>8.个人信息保护：保险公司严格遵守现行的关于个人信息、数据及隐私保护的法律法规，采取充分的技术手段和制度管理，保护您提供给保险公司的个人信息、数据和隐私不受到非法的泄露或披露给未获授权的第三方。在必要情形下第三方可能接触并使用您的个人信息，包括得到授权的保险公司员工、以及不时执行与相关业务、营销活动和数据整理有关工作的其他公司或人员。所有此类人员及公司均需遵守相关保密协议，同时也需遵守国家关于个人信息保护有关法律法规，以确保您的个人信息随时得到保护。除上述用途外，保险公司不会将您的个人信息用于任何未经您同意的用途。除了保险公司的业务合作伙伴、法律顾问、外部审计机构或按照法律法规规定或法庭裁决之外，保险公司不会将所收集的任何个人信息泄露、篡改、毁损、出售或者提供给任何第三方。</p>
    <p>9.综合偿付能力：截至2021年三季度, 中国平安财产保险股份有限公司上海分公司（综合偿付能力充足率为284.55%,满足监管要求;我公司最近一期的风险综合评级结果为A类。</p>
    <p>10.投保咨询和保单查询：为保证您的合法权益，有关保单的任何查询、投诉、咨询以及投保、承保、理赔、保全、退保的办理流程及保险赔款、退保金、保险金的支付方式，您可以通过“平安好生活”APP自助服务或拨打客服热线95511进行咨询。</p>
    <p></p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class CarInsuranceDisclosure extends Vue {
  static name = "carInsurance-disclosure";
}
export default CarInsuranceDisclosure;
</script>
<style lang="scss" src="./index.scss" scoped></style>
