import * as TYPES from "@/store/action-types";

const productMutations = {
  [TYPES.PRODUCT_QUERY](state, payload = null) {
    state.productQuery = payload;
  },
  [TYPES.SET_PRODUCT](state, payload = null) {
    state.productInfo = payload;
  },
  [TYPES.CURRENT_PLAN](state, payload = 0) {
    state.planIndex = payload;
  },
};

export default productMutations;
