<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加猝死保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432322021120910923</p>
    <p>
      &#12288;&#12288;第一条
      本附加险附加于各类个人意外伤害保险合同，依主险合同投保人的申请，经保险人审核同意而订立。本附加险合同作为主险合同的组成部分，主险合同效力终止，本附加险合同效力亦同时终止；主险合同无效，本附加险合同亦无效。主险合同与本附加险合同相抵触之处，以本附加险合同为准。本附加险合同未约定事项，以主险合同为准。
    </p>
    <p>
      &#12288;&#12288;第二条
      被保险人在本附加险合同保险期间内猝死的，保险人按本附加险合同约定的保险金额给付身故保险金。<span
        >主险合同或本附加险合同仅可索赔一项。本主险合同或本附加险合同赔付后，本合同中该被保险人的保险责任终止。</span
      >
    </p>
    <p>
      &#12288;&#12288;未成年被保险人的各保险合同约定的死亡给付保险金额总和需符合
      《关于父母为其未成年子女投保以死亡为给付保险金条件人身保险有关问题的通知》（保监发〔2015〕90号）的规定。
    </p>
    <p>
      &#12288;&#12288;“猝死”指突然发生急性疾病或症状，且因此突发疾病或症状直接、单独导致被保险人在发病后24小时内身故。“猝死”包含各类直接致死原因无法确定的。
    </p>
    <div class="font-weight-bold">
      <p>
        &#12288;&#12288;第三条
        因下列原因之一，直接或间接造成被保险人身故的，保险人不负任何给付保险金责任：
      </p>
      <p>
        &#12288;&#12288;一、主险合同中列明的“责任免除”事项（但涉及本保险合同所指的猝死责任除外），也适用于本附加险合同；
      </p>
      <p>
        &#12288;&#12288;二、被保险人在本附加险合同生效日之前罹患的已知或应该知道的有关疾病或症状，或已知慢性病的急性发作、复发、合并症及并发症；
      </p>
      <p>
        &#12288;&#12288;三、被保险人罹患特定传染病、职业病、地方病、心理疾病；
      </p>
      <p>
        &#12288;&#12288;四、被保险人患有遗传性疾病、先天性畸形、变形或者染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD-10）》为准）。
      </p>
    </div>
    <p>
      &#12288;&#12288;第四条
      保险金申请人向保险人申请给付保险金时，除提交主险合同约定的各项证明和材料外，还应提交二级以上（含二级）或者保险人认可的其他医疗机构或公安机关出具的死亡证明。
    </p>
    <p>&#12288;&#12288;第五条 释义</p>
    <p>&#12288;&#12288;除另有约定外，本保险合同中的下列词语具有如下含义：</p>
    <p>
      &#12288;&#12288;<span>特定传染病：</span>根据中华人民共和国相关法律规定的法定传染病甲类和乙类发生暴发流行疫情的情况，相关法律发生调整，则本定义作相应调整。
    </p>
    <p>
      &#12288;&#12288;<span>职业病：</span>在生产环境或劳动过程中，一种或几种对健康有害的因素引起的疾病。职业病范围以国家正式颁布的种类为准。
    </p>
    <p>
      &#12288;&#12288;<span>地方病：</span>在一定地区或人群中发生的疾病。新病例来自本地。与地方的地质、地貌、水土、气候等因素密切相关，并在条件类似的地区蔓延流行。以当地地方病防治机构的公布为准。
    </p>
    <p>
      &#12288;&#12288;<span>遗传性疾病：</span>指生殖细胞或受精卵的遗传物质（染色体和基因）发生突变（或畸变）所引起的疾病，通常具有由亲代传至后代的垂直传递的特征。
    </p>
    <p>
      &#12288;&#12288;<span>先天性畸形、变形或染色体异常：</span>指被保险人出生时就具有的畸形、变形或染色体异常。先天性畸形、变形和染色体异常依照世界卫生组织《疾病和有关健康问题的国际统计分类》（ICD-10）确定。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentDeath extends Vue {
  static name = "accident-death";
}
export default AccidentDeath;
</script>
<style lang="scss" src="./index.scss" scoped></style>
