import { GoodsAction, GoodsActionButton } from "vant";
import LoadingOverlay from "@/components/LoadingOverlay";
import { registerComponent } from "@/utils";

export const components = registerComponent({
  GoodsActionButton,
  GoodsAction,
  LoadingOverlay,
});

export default { components };
