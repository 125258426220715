<template>
  <div>
    <van-submit-bar
      v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
      :price="Number(price) * 100"
      label="金额： "
      text-align="left"
      :button-text="text"
      button-type="primary"
      @submit="action"
      suffix-label="元"
    >
      <p
        style="
          transform: scale(0.8, 0.8);
          background: #f1f1f1;
          padding: 3px 6px;
          position: absolute;
          border-radius: 8px;
          left: 35%;
          top: 8px;
        "
        @click="checkBtn"
      >
        明细
      </p>
    </van-submit-bar>
    <van-submit-bar
      v-else
      :price="Number(price) * 100"
      label="保费： "
      text-align="left"
      :button-text="text"
      button-type="primary"
      @submit="action"
      suffix-label="元"
    />
    <van-action-sheet v-model="show" title="明细">
      <div class="modulBottom">
        <div>
          <p>服务权益</p>
          <p v-if="Number(price) == 13">12元</p>
          <p v-if="Number(price) == 35">32元</p>
          <p v-if="Number(price) == 60">54元</p>
          <p v-if="Number(price) == 90">80元</p>
        </div>
        <div>
          <p>交通工具意外险</p>
          <p v-if="Number(price) == 13">1元</p>
          <p v-if="Number(price) == 35">3元</p>
          <p v-if="Number(price) == 60">6元</p>
          <p v-if="Number(price) == 90">10元</p>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { components } from "./options";
const ProductModule = namespace("product");
const ProductState = ProductModule.State;
@Component({
  components,
})
class SubmitButton extends Vue {
  static name = "submit-button";
  show = false;
  @ProductState("productInfo") productInfo;

  @Prop({ type: String, default: "我要投保" }) text;
  @Prop({ type: Number | String, default: 0 }) price;

  @Emit()
  action() {
    console.log(this);
  }
  checkBtn() {
    this.show = true;
  }
}
export default SubmitButton;
</script>

<style lang="scss" scope>
.van-submit-bar {
  padding: 8px 0;

  .van-submit-bar__text {
    color: #ed171f !important;
    .van-submit-bar__price {
      color: #ed171f !important;
    }
  }
  .van-button--round {
    border-radius: 2px;
    width: 180px;
  }
}
.modulBottom {
  padding: 16px 16px 10px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    p {
      padding: 0 15px;
    }
  }
}
</style>
