export function mergeOptions(options = {}, target) {
  let merges = {};
  for (let key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      if (target[key]) {
        merges[key] = options[key];
        Object.assign(merges[key], target[key]);
        Object.assign(target, merges);
      } else {
        target[key] = options[key];
      }
    }
  }
  return target;
}

export function registerComponent(components) {
  const register = {};
  for (let key in components) {
    if (Object.prototype.hasOwnProperty.call(components, key)) {
      if (components[key].name) {
        register[components[key].name] = components[key];
      }
    }
  }
  return register;
}
