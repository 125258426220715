import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import Insure from "@/views/insure/index.vue";
import { beforeEachHooks } from "./hooks";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    title: "京东",
    component: Home,
    meta: {
      title: "产品介绍",
    },
  },
  {
    path: "/insure",
    name: "Insure",
    meta: {
      title: "投保流程",
    },
    component: () => import("@/views/insure/index.vue"),
    // component: () =>
    //   import(/* webpackChunkName: "insure" */ "@/views/insure/index.vue")
    // ),
  },
  {
    path: "/insureInfo",
    name: "InsureInfo",
    meta: {
      title: "投保信息确认",
    },
    component: () => import("@/views/insureInfo/index.vue"),
  },
  {
    path: "/particulars",
    name: "Particulars",
    meta: {
      title: "查看保障详情",
    },
    component: () => import("@/views/particulars/index.vue"),
  },
  {
    path: "/surePay",
    name: "SurePay",
    meta: {
      title: "确认支付",
    },
    component: () => import("@/views/surePay/index.vue"),
  },
  {
    path: "/payResult",
    name: "PayResult",
    meta: {
      title: "支付结果",
    },
    component: () => import("@/views/payResult/index.vue"),
  },
  {
    path: "/succer",
    name: "succer",
    meta: {
      title: "领取结果",
    },
    component: () => import("@/views/succer/index.vue"),
  },
  //H014二维码领取
  {
    path: "/toReceive",
    name: "toReceive",
    meta: {
      title: "权益兑换",
    },
    component: () => import("@/views/toReceive/index.vue"),
  },
  //阳光财 家财 H002
  {
    path: "/familyProperty/disclaimer",
    name: "disclaimer2",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/familyProperty/disclaimer.vue"),
  },
  {
    path: "/familyProperty/insuranceClause",
    name: "insuranceClause2",
    meta: {
      title: "保险条款",
    },
    component: () =>
      import("@/views/document/familyProperty/insuranceClause.vue"),
  },
  {
    path: "/familyProperty/insuranceNotice",
    name: "insuranceNotice2",
    meta: {
      title: "投保须知",
    },
    component: () =>
      import("@/views/document/familyProperty/insuranceNotice.vue"),
  },
  {
    path: "/familyProperty/disclosure",
    name: "disclosure2",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/familyProperty/disclosure.vue"),
  },
  {
    path: "/familyProperty/policy",
    name: "policy2",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/familyProperty/policy.vue"),
  },
  //太平洋 账户安全 H003
  {
    path: "/personalProperty/disclaimer",
    name: "disclaimer3",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/personalProperty/disclaimer.vue"),
  },
  {
    path: "/personalProperty/insuranceClause",
    name: "insuranceClause3",
    meta: {
      title: "保险条款",
    },
    component: () =>
      import("@/views/document/personalProperty/insuranceClause.vue"),
  },
  {
    path: "/personalProperty/insuranceNotice",
    name: "insuranceNotice3",
    meta: {
      title: "投保须知",
    },
    component: () =>
      import("@/views/document/personalProperty/insuranceNotice.vue"),
  },
  {
    path: "/personalProperty/disclosure",
    name: "disclosure3",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/personalProperty/disclosure.vue"),
  },
  {
    path: "/personalProperty/policy",
    name: "policy3",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/personalProperty/policy.vue"),
  },
  //平安 平安出行抗疫保(互联网版) H004
  {
    path: "/travel/disclaimer",
    name: "disclaimer4",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/travel/disclaimer.vue"),
  },
  {
    path: "/travel/insuranceClause",
    name: "insuranceClause4",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/travel/insuranceClause.vue"),
  },
  {
    path: "/travel/insuranceNotice",
    name: "insuranceNotice4",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/travel/insuranceNotice.vue"),
  },
  {
    path: "/travel/disclosure",
    name: "disclosure4",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/travel/disclosure.vue"),
  },
  {
    path: "/travel/policy",
    name: "policy4",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/travel/policy.vue"),
  },
  // 太平洋 国民意外险 H001
  {
    path: "/accident/disclaimer",
    name: "disclaimer1",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/accident/disclaimer.vue"),
  },
  {
    path: "/accident/insuranceClause",
    name: "insuranceClause1",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/accident/insuranceClause.vue"),
  },
  {
    path: "/accident/insuranceNotice",
    name: "insuranceNotice1",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/accident/insuranceNotice.vue"),
  },
  {
    path: "/accident/disclosure",
    name: "disclosure1",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/accident/disclosure.vue"),
  },
  {
    path: "/accident/policy",
    name: "policy1",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/accident/policy.vue"),
  },
  //全部保险
  {
    path: "/common/informed",
    name: "customerInformed",
    meta: {
      title: "客户告知书",
    },
    component: () => import("@/views/document/common/Informed.vue"),
  },
  // 疫苗
  {
    path: "/vaccine/insuranceNotice",
    name: "insuranceNotice5",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/vaccine/insuranceNotice.vue"),
  },
  {
    path: "/vaccine/insuranceClause",
    name: "insuranceClause5",
    meta: {
      title: "责任描述",
    },
    component: () => import("@/views/document/vaccine/insuranceClause.vue"),
  },
  {
    path: "/vaccine/disclaimer",
    name: "disclaimer5",
    meta: {
      title: "常见问题",
    },
    component: () => import("@/views/document/vaccine/disclaimer.vue"),
  },
  {
    path: "/vaccine/disclosure",
    name: "disclosure5",
    meta: {
      title: "责任免除",
    },
    component: () => import("@/views/document/vaccine/disclosure.vue"),
  },
  {
    path: "/vaccine/policy",
    name: "policy5",
    meta: {
      title: "理赔说明",
    },
    component: () => import("@/views/document/vaccine/policy.vue"),
  },
  {
    path: "/vaccine/privacy",
    name: "privacy5",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/vaccine/privacy.vue"),
  },
  // 银行卡
  {
    path: "/bankcard/insuranceNotice",
    name: "insuranceNotice7",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/bankcard/insuranceNotice.vue"),
  },
  {
    path: "/bankcard/insuranceClause",
    name: "insuranceClause7",
    meta: {
      title: "责任描述",
    },
    component: () => import("@/views/document/bankcard/insuranceClause.vue"),
  },
  {
    path: "/bankcard/disclaimer",
    name: "disclaimer7",
    meta: {
      title: "常见问题",
    },
    component: () => import("@/views/document/bankcard/disclaimer.vue"),
  },
  {
    path: "/bankcard/disclosure",
    name: "disclosure7",
    meta: {
      title: "责任免除",
    },
    component: () => import("@/views/document/bankcard/disclosure.vue"),
  },
  {
    path: "/bankcard/policy",
    name: "policy7",
    meta: {
      title: "理赔说明",
    },
    component: () => import("@/views/document/bankcard/policy.vue"),
  },
  {
    path: "/bankcard/privacy",
    name: "privacy7",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/bankcard/privacy.vue"),
  },
  //赠险 H011
  {
    path: "/receive/disclaimer",
    name: "disclaimer11",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/receive/disclaimer.vue"),
  },
  {
    path: "/receive/insuranceClause",
    name: "insuranceClause11",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/receive/insuranceClause.vue"),
  },
  {
    path: "/receive/insuranceNotice",
    name: "insuranceNotice11",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/receive/insuranceNotice.vue"),
  },
  {
    path: "/receive/disclosure",
    name: "disclosure11",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/receive/disclosure.vue"),
  },
  {
    path: "/receive/policy",
    name: "policy11",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/receive/policy.vue"),
  },
  //平安家财险 H012
  {
    path: "/sinopec/disclaimer",
    name: "disclaimer12",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/sinopec/disclaimer.vue"),
  },
  {
    path: "/sinopec/insuranceClause",
    name: "insuranceClause12",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/sinopec/insuranceClause.vue"),
  },
  {
    path: "/sinopec/insuranceNotice",
    name: "insuranceNotice12",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/sinopec/insuranceNotice.vue"),
  },
  {
    path: "/sinopec/disclosure",
    name: "disclosure12",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/sinopec/disclosure.vue"),
  },
  {
    path: "/sinopec/policy",
    name: "policy12",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/sinopec/policy.vue"),
  },
  //出行险 H013
  {
    path: "/traffic/insuranceClause",
    name: "insuranceClause13",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/traffic/insuranceClause.vue"),
  },
  {
    path: "/traffic/insuranceNotice",
    name: "insuranceNotice13",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/traffic/insuranceNotice.vue"),
  },
  {
    path: "/traffic/disclaimer",
    name: "disclaimer13",
    meta: {
      title: "权益服务规则",
    },
    component: () => import("@/views/document/traffic/disclaimer.vue"),
  },
  {
    path: "/traffic/disclosure",
    name: "disclosure13",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/traffic/disclosure.vue"),
  },
  {
    path: "/traffic/policy",
    name: "policy13",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/traffic/policy.vue"),
  },
  //平安驾车无忧意外保险 H014
  {
    path: "/carInsurance/disclaimer",
    name: "disclaimer15",
    meta: {
      title: "免责声明",
    },
    component: () => import("@/views/document/carInsurance/disclaimer.vue"),
  },
  {
    path: "/carInsurance/insuranceClause",
    name: "insuranceClause15",
    meta: {
      title: "保险条款",
    },
    component: () => import("@/views/document/carInsurance/insuranceClause.vue"),
  },
  {
    path: "/carInsurance/insuranceNotice",
    name: "insuranceNotice15",
    meta: {
      title: "投保须知",
    },
    component: () => import("@/views/document/carInsurance/insuranceNotice.vue"),
  },
  {
    path: "/carInsurance/disclosure",
    name: "disclosure15",
    meta: {
      title: "信息披露",
    },
    component: () => import("@/views/document/carInsurance/disclosure.vue"),
  },
  {
    path: "/carInsurance/policy",
    name: "policy15",
    meta: {
      title: "隐私政策",
    },
    component: () => import("@/views/document/carInsurance/policy.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Object.values(beforeEachHooks).forEach((hook) => {
  router.beforeEach(hook);
});

export default router;
