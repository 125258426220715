<template>
  <div class="document">
    <p class="font-weight-bold text-align">投保须知</p>
    <p></p>
    <p>1、本保险每位被保险人限投1份，多投无效；</p>
    <p>2、本保险承保年龄为1-80周岁，超出年龄范围无效；</p>
    <p>3、本保险保障范围为在中华人民共和国境内（不含港澳台）发生的保险事故；</p>
    <p>
      4、本保险保障的传染病为《中华人民共和国传染病防治法》中规定的甲类及乙类传染病；
    </p>
    <p>
      5、被保险人投保本产品前14天内或保险期间内，被保险人的出发地、途经地或目的地包括中华人民共和国境外地区、港澳台地区、或已被我国政府、当地政府或疾病预防控制机构列为中、高风险地区之一的，不属于保险责任；
    </p>
    <p>
      6、被保险人因集中医学观察导致的隔离，本保险按每日250元，就实际隔离天数给付隔离津贴，最高不超过14天；
    </p>
    <p>7、本保险等待期为3天，等待期内发生的保险事故，不属于保险责任；</p>
    <p>8、以上未尽事宜以本保险适用条款为准。</p>
    <p class="font-weight-bold text-align">投保人声明</p>
    <p></p>
    <p>
      一、本投保人声明以上陈述及各项填写信息属实，且没有隐瞒任何重大事实以影响贵公司评估风险或接受本投保申请。
    </p>
    <p>
      二、本投保人已阅读本产品适用条款及投保须知的各项内容，<span>特别就条款中有关责任免除和投保人、被保险人义务的内容进行详细阅读，</span>对贵公司就保险合同的内容说明和提示完全理解，没有异议，同意投保，本人知晓所有保险责任均以本保险合同所载为准。
    </p>
    <p>
      三、根据《中华人民共和国民法典》规定，数据电文是合法的合同表现形式。本人接受以中国平安财产保险股份有限公司提供的电子保单作为本投保书成立的合法有效凭证，具有完全证据效力。
    </p>
    <p></p>
    <!-- <p class="font-weight-bold text-align">共享条款</p>
    <p>
      &#12288;&#12288;<span>为了进一步提升您的用户体验，或是为了向您提供更为智能化、个性化的服务。我们可能会与关联方共享您的个人信息。您可以自主选择是否授权我们进行该等共享，或联系我们取消授权。</span>如果您拒绝授权该等业务功能，并不会影响您使用其它功能或服务。
    </p>
    <p>&#12288;&#12288;以下为相关业务功能的具体描述：</p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >改善产品与提升服务：为了向您提供有温度的金融服务，我们将依据您金融支付、医疗健康、衣食住行不同场景的实际需求，持续改善您的产品体验，我们共享您主动提供、使用服务中中产生的相关信息，用于相关产品的持续优化与改善，产品包括金融服务生态圈产品和汽车服务生态圈产品，例如，通过对客户开展群体分析，作为开发新产品、下架旧产品、调整服务方式的参考。</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >市场调研：我们可能对产品或服务、以及其他类似的产品或服务进行市场调研并共享相关信息，从而了解您对产品或服务的需求、偏好与建议。例如，我们可能向您发送调研问卷，邀请您参与反馈。</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >内容推荐：我们可能共享您的个人信息、特征标签或用户画像，从而向您推荐您可能感兴趣的资讯、活动或其他内容，便于您了解相关信息。</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block"
        >营销推广：我们可能共享您的个人信息、特征标签或用户画像，从而向您发送您可能感兴趣的广告、营销活动通知、商业性信息等内容，推广我们的产品或服务。</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;在共享您的个人信息之前以及共享的过程中，我们将充分评估该等共享的合法性、正当性、必要性，并采用适当的管理措施与技术措施，以保障您的个人信息安全。关联公司如果想要改变本隐私政策声明的个人信息使用目的，将再次征求您的授权同意。
    </p>
    <p style="display: flex">
      &#12288;&#12288;除了取得您的同意的情形，我们还会在法律法规允许的范围内共享您的个人信息，包括：
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >为订立或者履行个人作为一方当事人的合同所必需；</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >为履行法定职责或者法定义务所必需；</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >依法在合理的范围内处理已公开的个人信息；</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span
      >
    </p>
    <p style="display: flex">
      &#12288;&#12288;<span style="disply: block">•&#12288;</span
      ><span style="disply: block; font-weight: normal"
        >法律、行政法规规定的其他情形。</span
      >
    </p> -->
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class TravelNotice extends Vue {
  static name = "travel-notice";
}
export default TravelNotice;
</script>
<style lang="scss" src="./index.scss" scoped></style>
