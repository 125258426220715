import * as TYPES from "@/store/action-types";
import { getOrder } from "@/api";
import { isEmpty } from "@/utils/tools";
import { Toast } from "vant";

const orderActions = {
  async [TYPES.GET_ORDER]({ commit }, payload) {
    if (!isEmpty(payload)) {
      const orderInfo = await getOrder(payload);
      commit(TYPES.SET_ORDER, orderInfo);
    } else {
      Toast.fail("参数缺失!");
    }
  },
};

export default orderActions;
