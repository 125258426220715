<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      《家庭财产综合保险A款条款（2015版）》责任免除
    </p>
    <p>第一条 下列原因造成的损失、费用，保险人不负责赔偿：</p>
    <p>
      （一）核辐射、核爆炸、核污染等任何放射性污染，大气污染、土地污染、水污染及其他各种非放射性污染，但因本保险合同责任范围内的事故造成的污染不在此限；
    </p>
    <p>（二）地震、海啸、火山爆发，及其次生灾害；</p>
    <p>
      （三）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、盗窃、抢劫；
    </p>
    <p>（四）行政行为或司法行为；</p>
    <p>
      （五）投保人、被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员的故意、违法行为，或未按使用说明、操作规范或相关国家标准擅自改装、改造保险标的的行为。
    </p>
    <p>第二条 下列损失、费用，保险人也不负责赔偿：</p>
    <p>（一）保险标的遭受保险事故引起的任何间接损失；</p>
    <p>
      （二）家用电器因使用过度、超电压、短路、断路、漏电、自身发热、烘烤等原因所造成本身的损毁；
    </p>
    <p>
      （三）保险标的本身缺陷、保管不善、使用不当、变质、霉烂、受潮、虫咬、自然磨损、自然损耗、自燃、烘焙导致的本身的损失；
    </p>
    <p>
      （四）保险标的在保险单载明地址的房屋外遭受的损失，但安装在房屋外的空调器和太阳能热水器等家用电器的室外设备除外；
    </p>
    <p>
      （五）坐落在蓄洪区、行洪区，或在江河岸边、低洼地区以及防洪堤以外当地常年警戒水位线以下的家庭财产，由于洪水所造成的一切损失；
    </p>
    <p>
      （六）保险事故造成的玻璃单独破损，但与玻璃为一体的其他保险标的一并发生损失的情形除外；
    </p>
    <p>
      （七）本保险合同约定的免赔额或按本保险合同中载明的免赔率计算的免赔额；
    </p>
    <p>（八）其他不属于保险合同责任范围内的损失和费用。</p>

    <p class="font-weight-bold text-align">
      《附加管道破裂及水渍损失保险A款条款（2015版）》责任免除
    </p>
    <p>第一条 下列原因造成的损失，保险人不负责赔偿：</p>
    <p>
      （一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
    </p>
    <p>
      （二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
    </p>
    <p>（三）水暖管安装、检修、试水、试压；</p>
    <p>（四）施工造成的水暖管破裂。</p>
    <p>第二条 下列损失、费用，保险人不负赔偿责任：</p>
    <p>（一）水暖管破裂致使供水中断造成的损失；</p>
    <p>
      （二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
    </p>
    <p>（三）其他不属于本附加险合同责任范围内的损失和费用。</p>
    <p class="font-weight-bold text-align">
      《附加管道破裂及水渍第三者责任保险A款条款（2015版）》责任免除
    </p>
    <p>第一条 下列原因造成的损失，保险人不负责赔偿：</p>
    <p>
      （一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
    </p>
    <p>
      （二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
    </p>
    <p>（三）水暖管安装、检修、试水、试压；</p>
    <p>（四）施工造成的水暖管破裂。</p>
    <p>第二条 下列损失、费用，保险人不负赔偿责任：</p>
    <p>（一）水暖管破裂致使供水中断造成的损失；</p>
    <p>
      （二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
    </p>
    <p>（三）其他不属于本附加险合同责任范围内的损失和费用。</p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class FamilyPropertyDisclaimer extends Vue {
  static name = "family-property-disclaimer";
}
export default FamilyPropertyDisclaimer;
</script>
<style lang="scss" src="./index.scss" scoped></style>
