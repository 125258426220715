import {
  Notify,
  ActionSheet,
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  GoodsActionButton,
  GoodsAction,
  Tab,
  Tabs,
  Checkbox,
  Divider,
  Button,
  Popup,
} from "vant";
import LoadingOverlay from "@/components/LoadingOverlay";
import SubmitButton from "@/components/SubmitButton";
import { registerComponent } from "@/utils";
import insuranceClause from "@/views/document/travel/insuranceClause.vue";
import insuranceClausetwo from "@/views/document/accident/insuranceClausetwo.vue";

import travelInfectious from "@/components/documents/travelInfectious";
import travelCritically from "@/components/documents/travelCritically";
import travelNotice from "@/components/documents/travelNotice";
import travelDisclaimer from "@/components/documents/travelDisclaimer";
import accidentAmbulance from "@/components/documents/accidentAmbulance";
import accidentBenefit from "@/components/documents/accidentBenefit";
import accidentDeath from "@/components/documents/accidentDeath";
import accidentDisclaimer from "@/components/documents/accidentDisclaimer";
import accidentFracture from "@/components/documents/accidentFracture";
import travelDisclosure from "@/components/documents/travelDisclosure";
import travelPolicy from "@/components/documents/travelPolicy";
import accidentNotice from "@/components/documents/accidentNotice";
import familyPropertyNotice from "@/components/documents/familyPropertyNotice";
import personalPropertyNotice from "@/components/documents/personalPropertyNotice";
import familyPropertyClause from "@/components/documents/familyPropertyClause";
import personalPropertyClause from "@/components/documents/personalPropertyClause";
import familyPropertyDisclaimer from "@/components/documents/familyPropertyDisclaimer";
import personalPropertyDisclaimer from "@/components/documents/personalPropertyDisclaimer";
import disclosuresafe from "@/components/documents/disclosuresafe";
import message from "@/components/documents/message";
import vaccineInformation from '@/components/documents/vaccineInformation'
import vaccineDescription from '@/components/documents/vaccineDescription'
import vaccinePolicy from '@/components/documents/vaccinePolicy'
import vaccineDisclaimer from '@/components/documents/vaccineDisclaimer'
import vaccineDisclosure from '@/components/documents/vaccineDisclosure'
import bankcardInformation from '@/components/documents/bankcardInformation'
import bankcardDescription from '@/components/documents/bankcardDescription'
import bankcardPolicy from '@/components/documents/bankcardPolicy'
import bankcardDisclaimer from '@/components/documents/bankcardDisclaimer'
import bankcardDisclosure from '@/components/documents/bankcardDisclosure'
import sinopecInfectious from "@/components/documents/sinopecInfectious";
import sinopecCritically from "@/components/documents/sinopecCritically";
import sinopecDisclaimer from "@/components/documents/sinopecDisclaimer";
import sinopecNotice from "@/components/documents/sinopecNotice";
import sinopecDisclosure from "@/components/documents/sinopecDisclosure";
import sinopecPolicy from "@/components/documents/sinopecPolicy";
import sinopecDisease from "@/components/documents/sinopecDisease";
import trafficInfectious from "@/components/documents/trafficInfectious";
import trafficCritically from "@/components/documents/trafficCritically";
import trafficDisclaimer from "@/components/documents/trafficDisclaimer";
import trafficNotice from "@/components/documents/trafficNotice";
import trafficDisclosure from "@/components/documents/trafficDisclosure";
import trafficPolicy from "@/components/documents/trafficPolicy";
import carInsuranceInfectious from "@/components/documents/carInsuranceInfectious";
import carInsuranceCritically from "@/components/documents/carInsuranceCritically";
import carInsuranceDisclaimer from "@/components/documents/carInsuranceDisclaimer";
import carInsuranceNotice from "@/components/documents/carInsuranceNotice";
import carInsuranceDisclosure from "@/components/documents/carInsuranceDisclosure";
import carInsurancePolicy from "@/components/documents/carInsurancePolicy";
import carInsuranceDisease from "@/components/documents/carInsuranceDisease";

export const components = registerComponent({
  Button,
  ActionSheet,
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  GoodsActionButton,
  GoodsAction,
  Tab,
  Tabs,
  Checkbox,
  Divider,
  Notify,
  LoadingOverlay,
  SubmitButton,
  Popup,
  insuranceClause,
  travelInfectious,
  travelCritically,
  travelNotice,
  travelDisclaimer,
  travelDisclosure,
  travelPolicy,
  accidentAmbulance,
  accidentBenefit,
  accidentDeath,
  accidentDisclaimer,
  accidentFracture,
  accidentNotice,
  familyPropertyNotice,
  personalPropertyNotice,
  insuranceClausetwo,
  familyPropertyClause,
  personalPropertyClause,
  familyPropertyDisclaimer,
  personalPropertyDisclaimer,
  disclosuresafe,
  message,
  vaccineInformation,
  vaccineDescription,
  vaccinePolicy,
  vaccineDisclaimer,
  vaccineDisclosure,
  bankcardInformation,
  bankcardDescription,
  bankcardPolicy,
  bankcardDisclaimer,
  bankcardDisclosure,
  sinopecInfectious,
  sinopecCritically,
  sinopecDisclaimer,
  sinopecNotice,
  sinopecDisclosure,
  sinopecPolicy,
  sinopecDisease,
  trafficInfectious,
  trafficCritically,
  trafficDisclaimer,
  trafficNotice,
  trafficDisclosure,
  trafficPolicy,
  carInsuranceInfectious,
  carInsuranceCritically,
  carInsuranceDisclaimer,
  carInsuranceNotice,
  carInsuranceDisclosure,
  carInsurancePolicy,
  carInsuranceDisease,
});

export default { components };
