const orderGetter = {
  coverUrl(state) {
    if (!state.productInfo || !state.productInfo.titlePicture) return "";
    return state.productInfo.titlePicture;
  },

  productPlans: (state) => {
    if (!state.productInfo) return [];
    const productPlans = state.productInfo.productPlans || [];
    return productPlans;
  },

  productIntroduces: (state) => {
    if (!state.productInfo) return [];
    const productIntroduces = state.productInfo.productIntroduces;
    if (productIntroduces) {
      const { productFeatures, commonProblem, insuranceLaw } =
        productIntroduces;
      return [productFeatures, commonProblem, insuranceLaw].filter(Boolean);
    }
    return [];
  },
  currentPlan(state) {
    if (!state.productInfo || !state.productInfo.productPlans) return {};
    return state.productInfo.productPlans[state.planIndex] || {};
  },
};

export default orderGetter;
