<template>
  <div class="document">
    <!-- 投保须知 -->
    <img :src="this.image" alt="" width="100%" height="100%" />
    <p style="height: 1rem; width: 100%"></p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { getProduct } from "@/api/order";

@Component({})
class SinopecNotice extends Vue {
  image = "";
  static name = "sinopec-notice";
  created() {
    getProduct().then((res) => {
      console.log(res);
      this.image = res.clausePicture.liabilityExemption;
    });
  }
}
export default SinopecNotice;
</script>
<style lang="scss" src="./index.scss" scoped></style>
