<template>
  <div>
    <van-tabs swipeable>
      <van-tab title="公共场所个人责任保险条款"
        ><accident-liability></accident-liability
      ></van-tab>
      <van-tab title="猝死保险（互联网）条款"
        ><accident-death></accident-death
      ></van-tab>
      <van-tab title="个人特定传染病住院津贴保险（互联网）条款"
        ><accident-infectious></accident-infectious
      ></van-tab>
      <van-tab title="君安行人身意外伤害保险（互联网）条款"
        ><accident-injury></accident-injury
      ></van-tab>
      <van-tab title="扩展高风险活动保险（互联网）条款"
        ><accident-high-risk></accident-high-risk
      ></van-tab>
      <van-tab title="意外伤害骨折保险（互联网）条款"
        ><accident-fracture></accident-fracture
      ></van-tab>
      <van-tab title="意外伤害救护车费用保险（互联网）条款"
        ><accident-ambulance></accident-ambulance
      ></van-tab>
      <van-tab title="意外伤害医疗保险（互联网）条款"
        ><accident-medical></accident-medical
      ></van-tab>
      <van-tab title="意外伤害住院津贴保险（互联网）条款"
        ><accident-benefit></accident-benefit
      ></van-tab>
      <van-tab title="个人人身意外伤害保险A款（互联网）条款"
        ><accident-injury-a></accident-injury-a
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class InsuranceClausetwo extends Vue {
  static name = "InsuranceClausetwo";
}
export default InsuranceClausetwo;
</script>
<style lang="scss" src="../index.scss" scoped></style>
