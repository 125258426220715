import * as dayjs from "dayjs";
import { userBehavior } from "@/api";

//默认加载初始化一次开始时间
let startTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
let endTime = null;

const dotRoutes = ["", "Home", "Insure", "SurePay"];

export const dotHooks = {
  beforeRouteEnter(to, form, next) {
    startTime = null;
    next((vm) => {
      const currentRoute = vm.$route.name;
      if (dotRoutes.includes(currentRoute)) {
        startTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      }
    });
  },
  async beforeRouteLeave(to, form, next) {
    endTime = null;
    const currentRoute = this.$route.name;
    const stayPage = dotRoutes.indexOf(currentRoute);
    if (stayPage > -1) {
      endTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      await userBehavior({
        endTime,
        startTime,
        stayPage: String(stayPage),
      });
    }
    next();
  },
};
