<template>
  <!-- 新冠疫苗无忧投保须知 -->
  <div class="document">
    <img :src="this.image" alt="" width="100%" height="100%" />
    <p style="height: 1rem; width: 100%"></p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { getProduct } from "@/api/order";
@Component({})
class VaccineInformation extends Vue {
  image = "";
  static name = "vaccine-information";
  created() {
    getProduct().then((res) => {
      this.image = res.clausePicture.insuranceLaw;
    });
  }
}
export default VaccineInformation;
</script>
<style lang="scss" scoped></style>