import axios from "axios";
import store from "@/store";
import * as TYPES from "@/store/action-types";
import { isEmpty } from "@/utils/tools";
import { Toast } from "vant";

class HttpRequest {
  constructor() {
    this.basURL = "/";
    this.timeout = 10000;
    this.queue = {};
    this.whiteList = ["/api/common/userBehavior"];
  }

  setInterceptor(instance, url) {
    instance.interceptors.request.use((config) => {
      this.updateLoading(url, true);
      // 排除可回溯请求头
      if (url.indexOf("glutton") > -1) {
        return config;
      }
      const { productQuery } = store.state.product;
      if (!isEmpty(productQuery)) {
        config.headers = {
          ...config.headers,
          ...productQuery,
        };
      } else {
        throw Error("产品参数错误!");
      }
      let CancelToken = axios.CancelToken;
      config.cancelToken = new CancelToken((c) => {
        store.commit(TYPES.SET_TOKEN, c);
      });
      return config;
    });
    instance.interceptors.response.use(
      (response) => {
        this.updateLoading(url, false);
        const data = response.data;
        // 可回溯请求特殊处理
        if (response.status === 200 && data.occurredTime) {
          return data.body;
        }
        if (response.status === 200 && data.success) {
          const resoult = data.data || data;
          return resoult;
        }
        // 白名单过滤请求失败
        if (response.status === 200 && this.whiteList.includes(url)) {
          const msg = data.message || data.msg;
          msg && !data.success && Toast.fail(msg);
          return data;
        }
        return this.fails(data);
      },
      (err) => {
        this.updateLoading(url, false);
        if (this.whiteList.includes(url)) {
          return err;
        }
        const errorData = err.data || err;
        return this.fails(errorData);
      }
    );
  }

  updateLoading(url, state) {
    if (!state) delete this.queue[url];
    if (this.isEmptyQueue()) {
      store.commit(TYPES.LOADING, state);
    }
    state && (this.queue[url] = true);
  }

  fails(data) {
    Toast.fail(data.message || data.msg || "服务器错误");
    return Promise.reject(data);
  }
  isEmptyQueue() {
    return Object.keys(this.queue).length === 0;
  }
  request(options) {
    let instance = axios.create();
    let config = {
      basURL: this.basURL,
      timeout: this.timeout,
      ...options,
    };
    this.setInterceptor(instance, options.url);
    return instance(config);
  }
  get(url, data) {
    return this.request({
      url,
      method: "get",
      ...data,
    });
  }
  post(url, data = {}) {
    return this.request({
      url,
      method: "post",
      data,
    });
  }
}

export default new HttpRequest();
