<template>
  <div class="document">
    <p class="font-weight-bold text-align">中国平安财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">平安产险附加法定传染病集中治疗观察津贴保险（互联网版）条款</p>
    <p class="font-weight-bold text-align">注册号：C00001732522021122438073</p>
    <p></p>
    <p class="font-weight-bold text-align">总则</p>
    <p>&#12288;&#12288;<span>第一条</span> 本附加保险合同须附加于各种旅行类意外伤害保险主险合同（以下简称“主保险合同”）。主保险合同所附条款、投保单、保险单、保险凭证以及批单等，凡与本附加保险合同相关者，均为本附加保险合同的构成部分。凡涉及本附加保险合同的约定，均应采用书面形式。</p>
    <p>&#12288;&#12288;<span>若主保险合同与本附加保险合同的条款互有冲突，则以本附加保险合同的条款为准。本附加保险合同未尽事宜，以主保险合同的条款规定为准。</span></p>
    <p>&#12288;&#12288;<span>第二条 本附加保险合同适用的“旅行”仅限于中华人民共和国境内（不包括港澳台地区）的旅行，即出发地、路线全程、途经地、目的地均在中华人民共和国境内（不包括港澳台地区）。</span></p>
    <p>&#12288;&#12288;<span>第三条</span> 关于本附加保险合同投保的法定传染病，约定如下：</p>
    <p>&#12288;&#12288;（一）法定传染病，一般指《中华人民共和国传染病防治法》规定的传染病，国务院卫生行政部门根据传染病暴发、流行情况和危害程度，可以决定增加、减少或者调整传染病病种并予以公布。</p>
    <p>&#12288;&#12288;<span>（二）本附加保险合同可选择投保第（一）款所述法定传染病中的一种或多种，由投保人、保险人在投保时协商确定，具体以保险合同载明为准。</span></p>
    <p>&#12288;&#12288;<span>若保险合同中未载明的，则本附加保险合同投保的“法定传染病”指第（一）款所述法定传染病。</span></p>
    <p>&#12288;&#12288;<span>前述两种情况下，本附加保险合同投保的法定传染病，以下均简称为“法定传染病”。</span></p>
    <p>&#12288;&#12288;<span>第四条 本保险合同仅限于互联网渠道销售。</span></p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任 </p>
    <p>&#12288;&#12288;<span>第五条</span> 本附加保险合同的保险责任为可选保险责任。<span>投保人可选择投保下列保险责任中的一项或两项，并在保险合同中载明。本附加保险合同适用的等待期、隔离治疗窗口期、医学观察窗口期，由投保人、保险人协商确定，并在保险合同中载明。若保险合同未单独约定隔离治疗窗口期、医学观察窗口期的，则默认为十五日。</span></p>
    <p>&#12288;&#12288;<span>（一）集中隔离治疗津贴保险责任</span></p>
    <p>&#12288;&#12288;在本附加保险合同保险期间内，且在本附加保险合同生效之日起的<span>等待期满以后（不含等待期最后一天），发生以下情形导致被保险人接受住院隔离或临时病室隔离的集中隔离治疗：</span></p>
    <p>&#12288;&#12288;1、被保险人在<span>旅行期间</span>因疑似或确诊感染法定传染病而在旅行出发地、途经地或目的地被集中隔离治疗；或</p>
    <p>&#12288;&#12288;2、被保险人于完成该次旅行后直接返回至其日常居住地、日常工作地或政府指定地点之日起的<span>隔离治疗窗口期内，</span>或保险期间届满后的<span>隔离治疗窗口期内（两者以最先发生的时间为准）</span>，因该次旅行被疑似或确诊感染法定传染病而被集中隔离治疗；</p>
    <p>&#12288;&#12288;保险人按照本附加保险合同的约定对被保险人按本附加保险合同约定的集中隔离治疗津贴保险金额一次性给付<span>“集中隔离治疗津贴保险金”，对该被保险人的本项保险责任终止。本项保险责任仅适用于住院隔离或临时病室隔离形式的集中隔离治疗，不包括居家隔离治疗。 </span></p>
    <p>&#12288;&#12288;<span>（二）集中医学观察津贴保险责任</span></p>
    <p>&#12288;&#12288;在本附加保险合同保险期间内，且在本附加保险合同生效之日起的<span>等待期满以后（不含等待期最后一天），发生以下情形导致被保险人接受集中医学观察：</span></p>
    <p>&#12288;&#12288;1、被保险人在旅行期间因与确诊或疑似法定传染病病例密切接触、有疑似法定传染病症状、疑似罹患法定传染病或政府规定的其他需要集中医学观察的情形而在旅行出发地、途经地或目的地被集中医学观察；或</p>
    <p>&#12288;&#12288;2、被保险人于完成该次旅行后直接返回至其日常居住地、日常工作地或政府指定地点之日起的<span>医学观察窗口期内</span>，或保险期间届满后的<span>医学观察窗口期内（两者以最先发生的时间为准）</span>，因该次旅行与确诊或疑似法定传染病病例密切接触、有疑似法定传染病症状、疑似罹患法定传染病或政府规定的其他需要集中医学观察的情形而被集中医学观察；</p>
    <p>&#12288;&#12288;保险人按照本附加保险合同的约定对被保险人按本附加保险合同约定的集中医学观察津贴保险金额一次性给付<span>“集中医学观察津贴保险金”，对该被保险人的本项保险责任终止。本项保险责任仅适用于集中医学观察，不包括居家医学观察。</span></p>
    <div class="font-weight-bold">
      <p class="font-weight-bold text-align">&#12288;&#12288;责任免除</p>
      <p>&#12288;&#12288;<span>第六条</span> 发生下列情形之一的，保险人不承担给付保险金责任：</p>
      <p>&#12288;&#12288;（一）保险期间开始前、或在本附加保险合同生效之日起的等待期满以前（含等待期最后一天）：</p>
      <p>&#12288;&#12288;1．被保险人确诊罹患法定传染病；</p>
      <p>&#12288;&#12288;2．被保险人疑似罹患法定传染病，在等待确诊结果；</p>
      <p>&#12288;&#12288;3．被保险人因与确诊病人或疑似病人密切接触而被隔离的；</p>
      <p>&#12288;&#12288;（二）投保本附加保险合同前或出行前旅行出发地、途经地或目的地已被我国政府、当地政府或疾病预防控制机构列为中、高风险地区的；</p>
      <p>&#12288;&#12288;（三）非经疾病预防控制中心或政府相关部门要求的自愿隔离和自愿观察；</p>
      <p>&#12288;&#12288;（四）居家隔离治疗、居家医学观察。</p>
      <p>&#12288;&#12288;<span>第七条</span> 因下列原因导致的保险事故，保险人不承担给付保险金责任：</p>
      <p>&#12288;&#12288;（一）投保人、被保险人的故意行为；</p>
      <p>&#12288;&#12288;（二）被保险人违反《中华人民共和国传染病防治法》或相关疫情防控法律法规、行政条令条例；</p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额</p>
    <p>&#12288;&#12288;<span>第八条 保险金额是保险人承担给付保险金责任的最高限额。</span>本附加保险合同的集中隔离治疗津贴保险金额、集中医学观察津贴保险金额，由投保人、保险人双方协商确定，并在保险合同中载明。</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险期间与续保</p>
    <p>&#12288;&#12288;<span>第九条</span> 本附加保险合同保险期间由保险人和投保人协商确定，以保险单载明的起讫时间为准，<span>但最长不超过一年、也不得小于主保险合同的保险期间。</span></p>
    <p>&#12288;&#12288;<span>本附加保险合同遵循“不保证续保条款”：“本产品保险期间为一年（或不超过一年）。</span>保险期间届满，投保人需要重新向保险公司申请投保本产品，并经保险人同意，交纳保险费，获得新的保险合同。”</p>
    <p>&#12288;&#12288;<span>若发生下列情形之一的，本合同不再接受续保：</span></p>
    <p>&#12288;&#12288;<span>（一）本产品统一停售；</span></p>
    <p>&#12288;&#12288;<span>（二）被保险人身故；</span></p>
    <p>&#12288;&#12288;<span>（三）投保人对于保险人就投保人或者被保险人的有关情况提出的询问未履行如实告知义务，足以影响保险人决定是否同意承保，保险人已经解除保险合同的；</span></p>
    <p>&#12288;&#12288;<span>（四）投保人未如实告知,被保险人不符合投保条件或存在欺诈情形的；</span></p>
    <p class="font-weight-bold text-align">&#12288;&#12288;等待期</p>
    <p>&#12288;&#12288;<span>第十条 本保险合同的等待期由投保人和保险人双方约定，并在保险单中载明，最长不得超过一百八十天；如未载明的，则默认为三十天。</span></p>
    <p>&#12288;&#12288;因意外伤害发生保险责任的，无等待期。</p>
    <p>&#12288;&#12288;如为连续不间断续保的（同一被保险人连续在本保险人处投保本条款为基础的保险合同，保险期间衔接不中断），从第二期保险合同起的连续不间断续保保险合同不适用等待期。</p>
    <p>&#12288;&#12288;<span>若保险合同未连续不间断续保，则需重新适用等待期。</span></p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金申请与给付</p>
    <p>&#12288;&#12288;<span>第十一条</span> 保险金申请人向保险人申请给付保险金时，应提交以下材料。保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。<span>如保险人对保险金申请材料存疑，有权要求被保险人在保险人指定或认可的医疗机构进行复检确认；被保险人应予配合。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span></p>
    <p>&#12288;&#12288;（一）集中隔离治疗津贴保险金申请</p>
    <p>&#12288;&#12288;1．保险金给付申请书；</p>
    <p>&#12288;&#12288;2．保单号或其他有效保险凭证；</p>
    <p>&#12288;&#12288;3．被保险人、保险金申请人的身份证明；</p>
    <p>&#12288;&#12288;4．疾病预防控制机构或医疗机构出具的疑似或确认感染法定传染病的证明；</p>
    <p>&#12288;&#12288;5．疾病预防控制机构或医疗机构或政府相关部门出具的集中隔离治疗证明，且证明应注明采取何种隔离治疗措施（如住院隔离、临时病室隔离）；</p>
    <p>&#12288;&#12288;6．保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；</p>
    <p>&#12288;&#12288;7．若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。</p>
    <p>&#12288;&#12288;（二）集中医学观察津贴保险金申请</p>
    <p>&#12288;&#12288;1．保险金给付申请书；</p>
    <p>&#12288;&#12288;2．保单号或其他有效保险凭证；</p>
    <p>&#12288;&#12288;3．被保险人、保险金申请人的身份证明；</p>
    <p>&#12288;&#12288;4．疾病预防控制机构或医疗机构或政府相关部门出具的集中医学观察证明，且证明应注明采取何种医学观察措施（如集中医学观察）；</p>
    <p>&#12288;&#12288;5．如属于“政府规定的其他需要集中医学观察的情形”，应提供政府相关书面规定、确认属于相应情形的证明材料；</p>
    <p>&#12288;&#12288;6．保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；</p>
    <p>&#12288;&#12288;7．若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>&#12288;&#12288;<span>第十二条</span> 投保人和保险人可以协商变更合同内容。</p>
    <p>&#12288;&#12288;变更保险合同的，应当由保险人在保险单或者其他保险凭证上批注或附贴批单，或者投保人和保险人订立变更的书面协议。</p>
    <p>&#12288;&#12288;<span>第十三条</span> 在本保险合同成立后，投保人可以书面形式通知保险人解除合同，<span>但保险人已根据本保险合同约定给付保险金的除外。</span></p>
    <p>&#12288;&#12288;投保人解除本保险合同时，应提供下列证明文件和资料：</p>
    <p>&#12288;&#12288;（一）保险合同解除申请书；</p>
    <p>&#12288;&#12288;（二）保险单；</p>
    <p>&#12288;&#12288;（三）保险费交付凭证；</p>
    <p>&#12288;&#12288;（四）投保人身份证明。</p>
    <p>&#12288;&#12288;<span>投保人要求解除本保险合同，自保险人接到保险合同解除申请书之时起，本保险合同的效力终止。保险人收到上述证明文件和资料之日起三十日内退还保险单的现金价值，其计算方法如下：</span></p>
    <p>&#12288;&#12288;<span>保险单现金价值=净保费×[1－（保险期间已经过天数/保险期间天数）]，经过天数不足一天的按一天计算。</span></p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>&#12288;&#12288;<span>第十四条</span></p>
    <p>&#12288;&#12288;【隔离治疗】指按照《中华人民共和国传染病防治法》等法律法规、行政命令规定，接受疾病预防控制机构、医疗机构有关传染病的隔离治疗，即将传染病人、传染病病毒携带者收留在指定的处所，限制其活动并进行治疗，直到消除传染病传播的危险。</p>
    <p>&#12288;&#12288;【窗口期】是指从感染病原体到能检测出相应的抗体的这一段时间，由于检测不到抗体，因此在临床上无法确诊，但是在血液中已带有一定量的病原体，即已经发生了感染，且在血中还未检测到病原体或抗体的时间段。</p>
    <p>&#12288;&#12288;【医学观察】指按照《中华人民共和国传染病防治法》等法律规定，接受疾病预防控制机构、医疗机构有关传染病的医学观察（又称为“留验”），即在传染病最长潜伏期内，将传染病病人、疑似传染病病人的密切接触者、有感染风险的人，收留在指定场所进行诊察、检验或采取其他预防措施。</p>
    <p>&#12288;&#12288;【日常居住地】指被保险人离开住所地时已连续居住了三个月以上的日常住所，以投保人投保时申报的被保险人地址为准。</p>
    <p>&#12288;&#12288;【日常工作地】指被保险人已连续工作了三个月以上的工作合同的履行地。</p>
    <p>&#12288;&#12288;【疑似感染法定传染病】根据国务院卫生行政部门发布的《&lt;中华人民共和国传染病防治法>规定管理的传染病诊断标准&gt;》，符合疑似传染病患者诊断标准。</p>
    <p>&#12288;&#12288;【确诊感染法定传染病】根据国务院卫生行政部门发布的《&lt;中华人民共和国传染病防治法>规定管理的传染病诊断标准&gt;》，符合确诊传染病患者诊断标准。</p>
    <p>&#12288;&#12288;【旅行期间】指从离开被保险人日常居住地或日常工作地（“去程”）开始，至返回被保险人日常居住地、日常工作地或政府指定地点（“回程”）为止的期间。</p>
    <p>&#12288;&#12288;其他释义参照主保险合同条款。</p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class TravelInfectious extends Vue {
   static name = "travel-infectious";
}
export default TravelInfectious;
</script>
<style lang="scss" src="./index.scss" scoped></style>
