import request from "@/utils/request";

// let i = 0;
// // 获取产品信息
// export const getProduct = () => {
//   i++;
//   if (i > 3) i = 0;
//   return request.post(`/api/getProduct${i ? i : ""}`);
// };

// 获取产品信息
export const getProduct = (params) => {
  return request.post(`/api/common/menu`, params);
};

// 太保接口
export const insureProduct = (params) => {
  return request.post(`/api/common/bigTree/createOrder`, params);
};

// 平安领取接口H011
export const receivePingan = (params) => {
  return request.post(`/api/presentability/Pingan`, params);
};
// 平安领取接口 H015
export const receivesPingan = (params) => {
  return request.post(`/api/presentability/withOutToken/Pingan`, params);
};

// 区域接口
export const areaSelect = (params) => {
  return request.post(`/api/common/areaSelect`, params);
};

// 出行险免费手机号接口
export const telNumber = (params) => {
  return request.post(`/api/common/giftCard`, params);
};

// 出行险付费手机号接口
export const payTelNumber = (params) => {
  return request.post(`/api/common/charge/giftCard`, params);
};
