import request from "@/utils/request";

// 查询订单信息
export const getOrder = (params) => {
  return request.post(`/api/common/getPolicyData`, params);
};

// 订单支付兑换tn
export const payAppTrans = (params) => {
  return request.post(`/api/payAppTrans/common/appTransReqToPay`, params);
};
export const getProduct = (params) => {
  return request.post(`/api/common/menu`, params);
};
export const lklAppTrans = (params) => {
  return request.post(`/api/payAppTrans/common/LKLToPay`, params);
};
// 出单
export const sendOrder = (params) => {
  return request.post(`/api/common/sendOrder`, params);
};
