import * as TYPES from "./action-types";

const globalModule = {
  state: {
    loading: false,
    tokens: [],
    hasShow: true,
  },
  mutations: {
    [TYPES.LOADING](state, loading) {
      state.loading = loading;
    },
    [TYPES.SET_TOKEN](state, token) {
      state.tokens = [...state.tokens, token];
    },
    [TYPES.CLEAN_TOKEN](state) {
      state.tokens.forEach((token) => token());
      state.tokens = [];
    },
    [TYPES.HAS_SHOW](state, payload) {
      state.hasShow = payload;
    },
  },
  actions: {},
};

export default globalModule;
