<template>
  <div class="insure">
    <loading-overlay></loading-overlay>
    <!-- <van-nav-bar title="投保信息" left-arrow @click-left="onClickLeft" /> -->
    <div class="contant">
      <!-- <div class="insure-title">我要投保</div> -->
      <insure-title v-model="titleOne" />
      <insure-form
        :notice="this.$route.query.code"
        v-model="insureForm"
        ref="insureForm"
      >
        <template v-slot:titleTwo>
          <insure-title v-model="titleTwo" />
        </template>
        <template v-slot:titleThree>
          <insure-title v-model="titleThree" />
        </template>
      </insure-form>
    </div>
    <submit-button
      @action="submit"
      :price="currentPlan.planAmount"
      text="下一步"
      html="下一步"
    ></submit-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import options from "./options";
import { State, namespace } from "vuex-class";
import { Toast } from "vant";

const ProductModule = namespace("product");
const ProductGetters = ProductModule.Getter;

const InsureModule = namespace("insure");
const InsureMutation = InsureModule.Mutation;
@Component({
  ...options,
})
class Insure extends Vue {
  @InsureMutation("FORM") saveForm;
  @State("product") product;
  @ProductGetters("currentPlan") currentPlan;
  titleOne = {
    orderNumber: "1",
    title: "保障期限",
  };
  titleTwo = {
    orderNumber: "2",
    title: "本人信息",
    relation: "投保人",
  };
  titleThree = {
    orderNumber: "3",
    title: "为谁投保",
    relation: "被保人",
  };
  insureForm = {
    date: "保险起期",
    name: "姓名",
    idCard: "身份证",
    phone: "手机号码",
    email: "电子邮箱",
    carNum:"车牌号码",
    relation: "关系",
    inSuredName: "姓名",
    inSuredIdCard: "身份证",
  };
  created() {
    this.getProductCode();
    // this.list = this.$route.query.code;
    console.log(this.$route.query.code);
  }
  getProductCode() {
    if (
      this.product.productQuery.productCode === "H001" ||
      this.product.productQuery.productCode === "H003" ||
      this.product.productQuery.productCode === "H004"
    ) {
      this.insureForm = {
        ...this.insureForm,
        inSuredphone: "手机号",
      };
    }
    if (this.product.productQuery.productCode === "H002" || this.product.productQuery.productCode === "H012") {
      this.insureForm = {
        ...this.insureForm,
        inSuredphone: "手机号",
        address: "地址",
        productCode: this.product.productQuery.productCode,
      };
    }
  }
  async submit() {
    await this.$refs.insureForm.onSubmit();
    if (this.$refs.insureForm.form.visilbleForm) {
      this.saveForm(this.$refs.insureForm.form);
      this.$router.push({
        path: "/insureInfo",
        query: {
          code: this.$route.query.code,
        },
      });
    } else {
      Toast.fail("请输入正确投保信息");
    }
  }
  onClickLeft() {
    this.$router.go(-1);
  }
}
export default Insure;
</script>
<style lang="scss" src="./index.scss" scoped></style>
