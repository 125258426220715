import BottomButton from "@/components/BottomButton";
import travelInfectious from "@/components/documents/travelInfectious";
import travelCritically from "@/components/documents/travelCritically";
import travelDisclaimer from "@/components/documents/travelDisclaimer";
import travelNotice from "@/components/documents/travelNotice";
import travelDisclosure from "@/components/documents/travelDisclosure";
import travelPolicy from "@/components/documents/travelPolicy";

import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  Tab,
  Tabs,
  BottomButton,
  travelInfectious,
  travelCritically,
  travelDisclaimer,
  travelNotice,
  travelDisclosure,
  travelPolicy,
});

export default { components };
