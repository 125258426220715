<template>
  <div class="document">
    <p class="font-weight-bold text-align">投保须知</p>
    <p class="font-weight-bold">【基本信息】</p>
    <p>
      <span>1)条款适用:</span
      >本产品适用条款为中国太平洋财产保险股份有限公司《个人人身意外伤害保险A款(互联网单证通用)》（注册号：C00001432312022022417463)、《附加君安行人身意外伤害保险（互联网）》（注册号：C00001432322021122029373）、《附加意外伤害住院津贴保险条款（互联网》（注册号：C00001432522021121011953）、《附加意外伤害医疗保险（互联网微保专用）》（注册号：C00001432522021121011943）、《附加意外伤害救护车费用保险条款（互联网）》（注册号：C00001432322021122029513）、《附加个人特定传染病住院津贴保险（互联网）》（注册号：C00001432522021121011963）、《附加意外伤害骨折保险条款（互联网）》（注册号：C00001432322021122029723）、《附加公共场所个人责任保险》（（太保财险）（备-责任）[2014]（附）20号）、《附加猝死保险（互联网）》（注册号：C00001432322021120910923）。《附加扩展高风险活动（互联网）》（注册号：C00001432322021122029953）。
    </p>
    <p></p>
    <p>
      <span>2)保单形式:</span
      >网上投保为你提供电子保单，根据《中华人民共和国合同法》第十一条规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。你可以关注“太平洋保险e服务”微信公众号或登录www.cpic.com.cn自助查询，对电子保单的真实性进行验证。
    </p>
    <p>
      <span>3)发票形式:</span
      >本产品仅提供电子发票，电子发票效力等同于纸质发票，报销可将电子发票打印后直接报销。
    </p>
    <p>
      <span>4)偿付能力告知:</span
      >太平洋产险最近季度偿付能力符合监管要求，详情请参见我司官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      <span>5)风险综合评价:</span
      >太平洋产险风险管理能力评估结果位居行业前列，详情请参见我司官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      6)本产品投保咨询、保单查询及客户投诉，均可拨打太平洋全国统一客服电话95500；
    </p>
    <p></p>
    <p class="font-weight-bold">【产品说明】</p>
    <p>
      <span>1)投保人:</span
      >本产品投保人年龄为年满18周岁有完全民事行为能力，且对被保险人具有可保利益的自然人；
    </p>
    <p>
      <span>2)被保险人:</span
      >本产品被保险人年龄限16-65周岁身体健康且能正常出行的人士（限1-3类职业）。职业类别参照《中国太平洋财产保险股份有限公司职业分类表（2008版）》。；
    </p>
    <p>
      <span>3)受益人:</span
      >本保险合同的身故受益人为法定受益人，其他保险责任的受益人为被保险人本人；
    </p>
    <p><span>4)购买份数:</span>每位被保险人仅限投保一份，多投无效；</p>
    <p>
      <span>5)保险期限:</span
      >本产品的保险期间为1年，网上投保成功后最早T+3日零时生效；
    </p>
    <p><span>6)犹豫期:</span>本产品无犹豫期，请你慎重选择投保。</p>
    <p><span>7)特别约定:</span></p>
    <div class="font-weight-bold">
      <p>
        1、本产品意外伤害医疗费用保障扣除100元人民绝对免赔后，在保险责任范围内（按条款规定属于医保范围内可报销合理必要用药）按照100%的比例赔付。
      </p>
      <p>
        2、本保险适用范围为中国
        境内（不含港澳台）,各类交通工具意外仅承保以乘客身份乘坐客运机动车、客运轨道交通车辆以及乘坐非营运的机动车的自然人，否则不予理赔。
      </p>
      <p>
        3、本保险仅限16-65周岁，职业类别为1-3类，且身体健康，能正常出行的人士投保。
      </p>
      <p>
        4、意外骨折保险金按照本保险合同所附《意外伤害骨折和关节脱位类型与保险金给付比例表》按比例赔付。
      </p>
      <p>
        5、任何年龄在18周岁以下的被保险人，如果其以死亡为给付责任的保险金额（包括在所有商业保险公司所购买的保险）超出中国保监会所规定的限额（对于被保险人不满10周岁的，死亡给付保险金额总和不得超过人民币20万元；被保险人已满10周岁但未满18周岁的，死亡给付保险金额总和不得超过人民币50万元，航空意外死亡保险金不受此限），保险公司就超过限制部分不承担保险金给付责任。
      </p>
      <p>
        6、本产品同一被保险人只可购买一份。同一被保险人购买多份的，保险人对该被保险人承担的保险责任以一份为限。
      </p>
      <p>
        7、本产品中针对意外伤害住院津贴保险保障，其中计划A，每天赔偿限额50元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过30天；计划B，每天赔偿限额50元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；计划C，每天赔偿限额80元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；计划D，每天赔偿限额100元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；
      </p>
      <p>
        8、医院限定：本保单约定的医疗机构是指在中华人民共和国境内二级以上（含二级）公立医院，但不包括：1）北京市的平谷区、密云区、怀柔区所有医院；2）江苏省的徐州市、南通市所有医院；3）天津市的滨海、静海地区所有医院；4）辽宁省的铁岭市所有医院、辽宁中医药大学附属第二医院、中国人民解放军第二0五医院；5）吉林省的长春中医药大学附属医院、长春市中医院、四平市第一人民医院、四平市中医医院、四平市梨树县第一人民医院；6）黑龙江省中医医院；7）河北省的青县、青龙县、东光县、廊坊市固安县所有医院、邯郸市馆陶县人民医院；8）河南省的信阳市所有医院、洛阳市中心医院、洛阳市第十一人民医院、洛阳市第二中医院、洛阳东方医院（河南科技大学第三附属医院）、洛阳市新安县人民医院、焦作市博爱县中医院、郑州市中牟县中医院、新乡市中医院、新乡市原阳县中医院、新乡市原阳县人民医院；9）山东省的禹城市、栖霞市、潍坊市高密县的所有医院、莱州市人民医院、莱州市中医院、莱州市郭家店中心卫医院、滨州市中心医院、青岛市即墨区中医医院、莱阳市中医医院、济宁市金乡县人民医院；10）四川省的宜宾市所有医院、邛崃市医疗中心医院、内江市中医医院、内江市第二人民医院、雅安市雨城区人民医院、雅安市第二人民医院；11）贵州省的遵义市绥阳县中医院；12）广东省的湛江市第二中医医院、茂名市电白区人民医院；13）湖南省的衡阳市中医正骨医院；14）河北省承德市兴隆县中医院；15）所有私营社保定点医院。
      </p>
      <p>
        8)本产品所有的页面文字描述为展示作用，具体保障方案等信息以投保后生成的电子保单为准，保险公司保留在法律规定范围内的解释权利。
      </p>
    </div>
    <p></p>
    <p class="font-weight-bold">【服务流程】</p>
    <p>
      <span>1、投保流程:</span
      >在线投保——填写投保信息——核保通过并支付——查收保单。
    </p>
    <p>
      <span>2、保单查询:</span
      >1）投保成功后我们将通过您预留的电子邮箱地址为您发送电子保单；2）投保完成30分钟后，可拨打太平洋保险客服电话95500-1-3进行保单查询；3）进入太平洋保险官方网站（http://www.cpic.com.cn/），进行保单查询。
    </p>
    <p>
      <span>3、变更:</span
      >若您需要批改保单，请投保人（仅限投保人）提供个人身份证件（身份证、护照等）的扫描/拍照件，并填写客服发送的申请材料（需要投保人填写信息、签字确认后发送扫描/拍照件）后，我们在3个工作日内为您办理批改申请。
    </p>
    <p><span>4、退保:</span></p>
    <p>
      （1）请拨打太保产险全国统一客服电话：95500，转太保产险人工客服，或线下柜面进行办理；
    </p>
    <p>
      （2）需要提供投保人身份证正反面照片、银行卡照片，并填写太保产险客服发送的申请材料；
    </p>
    <p>（3）保费退回方式：投保人银行卡（需要提供银行卡号、开户行信息）</p>
    <p>
      （4）保费退回时间：保费将于办理成功后30个工作日内，退还到投保人提供的本人银行账户。
    </p>
    <p class="font-weight-bold">温馨提示：</p>
    <p>1.保单生效日前申请退保：保险公司全额退还保险费。</p>
    <p>
      2.保单生效日后申请退保，按照所购买保险产品的保险合同条款规定退还部分保费，这会造成您的一定损失。
    </p>
    <p class="font-weight-bold">
      5、理赔流程：出险报案（全国统一客服热线：95500-1-3）——专业理赔指引——-递交索赔材料——案件审核处理——赔款到账。
    </p>
    <p class="font-weight-bold">6、发票开具：</p>
    <p>1）投保成功后我们将通过您预留的电子邮箱地址为您发送电子发票；</p>
    <p>2）可拨打太平洋保险客服电话95500-1-3申请开具发票。</p>
    <p>
      3）开具发票默认为电子发票，电子发票与纸质发票具有同等法津效力。若您须开具纸质发票，保险公司另提供快递服务，该项服务所产生的快递费，在签收邮件时由您自行承担。
    </p>
    <p>4）发票抬头类型仅支持个人且发票抬头默认为投保人。</p>
    <p></p>
    <p class="font-weight-bold">【重要告知】</p>
    <p>
      如您投保本产品，在投保本产品前您应履行相应的如实告知义务，具体如下：投保人或被保险人应如实填写投保信息，并就保险公司提出的询问据实告知，否则保险公司有权根据《中华人民共和国保险法》第十六条的规定处理；订立保险合同时，保险公司就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险公司决定是否同意承保或者提高保险费率的，保险公司有权解除合同。投保人故意不履行如实告知义务的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费。投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，但退还保险费。
    </p>
    <p>
      如您投保本产品，基于提供保险服务、提高服务质量的需要，您授权：本公司及本公司的合作机构在承保前后核实投保信息的真实性、调查获取被保险人与保险有关的相关信息(包括但不限于健康情况、诊疗情况、既往病史等)，如本公司经前述核查发现您存在未如实告知情况的，本公司将依法解除保险合同；本公司向与具有必要合作关系的机构提供您的信息（包括但不限于投保、承保、理赔、医疗等）；本公司及本公司的合作机构可对您的信息进行合理使用，可通过知悉您信息的机构查询与您有关的全部信息。为确保信息安全，本公司及其本公司的合作机构应采取有效措施，并承担保密义务。
    </p>
    <p></p>
    <p class="font-weight-bold">【其他信息】</p>
    <p>
      在必要情形下第三方可能接触并使用您的个人信息，包括得到授权的太保产险员工、以及执行与我们的业务、营销活动和数据整理有关工作的其他公司或人员。所有此类人员及公司均需遵守相关保密协议，同时也需遵守国家关于个人信息保护有关法律法规，以确保您的个人信息随时得到保护。除上述用途外，太保产险不会将您的个人信息用于任何未经您同意的用途。除了我们的业务合作伙伴、我们的法律顾问、外部审计机构或按照法律规定、监管规定或法庭裁决之外，我们不会将所接受的任何个人信息泄露、篡改、毁损、出售或者提供给任何第三方。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentNotice extends Vue {
  static name = "accident-notice";
}
export default AccidentNotice;
</script>
<style lang="scss" src="./index.scss" scoped></style>
