import { Field, Calendar, Form, Picker, Popup, Cascader } from "vant";
import { registerComponent } from "@/utils";

export const components = registerComponent({
  Field,
  Calendar,
  Form,
  Picker,
  Popup,
  Cascader,
});

export default { components };
