<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加君安行人身意外伤害保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432322021122029373</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;第一部分 基本条款</p>
    <p>&#12288;&#12288;<span>第一条 合同效力</span></p>
    <p>
      &#12288;&#12288;本保险合同附加于含意外伤害保险责任的各类个人人身保险合同（以下简称“主险合同”），依主险合同投保人的申请，经保险人审核同意而订立。主险合同效力终止，本保险合同效力亦终止；主险合同无效，本保险合同亦无效。主险合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以主险合同为准。
    </p>
    <p>&#12288;&#12288;<span>第二条 被保险人</span></p>
    <p>&#12288;&#12288;凡主险合同的被保险人，均可作为本保险合同的被保险人。</p>
    <p>&#12288;&#12288;<span>第三条 受益人</span></p>
    <p>&#12288;&#12288;本保险合同的受益人包括：</p>
    <p>&#12288;&#12288;一、身故保险金受益人</p>
    <p>
      &#12288;&#12288;订立本保险合同时，被保险人或投保人可指定一人或数人为身故保险金受益人。身故保险金受益人为数人时，应确定其受益顺序和受益份额；未确定受益份额的，各身故保险金受益人按照相等份额享有受益权。
    </p>
    <p>
      &#12288;&#12288;被保险人死亡后，有下列情形之一的，保险金作为被保险人的遗产，由保险人依照《中华人民共和国民法典》的规定履行给付保险金的义务：
    </p>
    <p>&#12288;&#12288;（一）没有指定受益人，或者受益人指定不明无法确定的；</p>
    <p>&#12288;&#12288;（二）受益人先于被保险人死亡，没有其他受益人的；</p>
    <p>
      &#12288;&#12288;（三）受益人依法丧失受益权或者放弃受益权，没有其他受益人的。
    </p>
    <p>
      &#12288;&#12288;受益人与被保险人在同一事件中死亡，且不能确定死亡先后顺序的，推定受益人死亡在先。
    </p>
    <p>
      &#12288;&#12288;受益人故意造成被保险人死亡或者残疾的，或者故意杀害被保险人未遂的，该受益人丧失受益权。
    </p>
    <p>
      &#12288;&#12288;被保险人或投保人可以变更身故保险金受益人，但需书面通知保险人，由保险人在本保险合同上批注。对因身故保险金受益人变更发生的法律纠纷，保险人不承担任何责任。
    </p>
    <p>
      &#12288;&#12288;投保人指定或变更身故保险金受益人的，应经被保险人书面同意。
    </p>
    <p>&#12288;&#12288;二、残疾保险金受益人</p>
    <p>
      &#12288;&#12288;除另有约定外，本保险合同的残疾保险金的受益人为被保险人本人。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第二部分 保险责任和责任免除
    </p>
    <p>&#12288;&#12288;<span>第四条 保险责任</span></p>
    <p>
      &#12288;&#12288;一、在本保险合同的保险期间内，保险人按照与投保人的约定对被保险人遭受的以下风险中的一类或几类承担保险责任：
    </p>
    <p>
      &#12288;&#12288;驾驶意外：被保险人驾驶非营运性质的机动车，在行驶过程中或为维护车辆继续运行（包括加油、加水、故障修理、换胎等）的临时停放过程中在机动车车厢内遭受意外伤害；
    </p>
    <p>
      &#12288;&#12288;乘坐意外：被保险人乘坐他人合法驾驶的非营运性质的机动车，在行驶过程中或为维护车辆继续运行（包括加油、加水、故障修理、换胎等）的临时停放过程中在机动车车厢内遭受意外伤害；
    </p>
    <p>
      &#12288;&#12288;公共交通意外：被保险人以乘客身份乘坐保险单中载明的客运公共交通工具期间遭受的交通意外伤害；其中，被保险人乘坐客运轮船时，该期间指自被保险人踏上轮船甲板时至离开轮船甲板时止；被保险人乘坐客运民航班机时，该期间指自被保险人进入客运民航班机的舱门时起至飞抵目的地走出舱门时止；
    </p>
    <p>
      &#12288;&#12288;本保险合同所称意外伤害，指以外来的、突发的、非本意的和非疾病的客观事件为直接且单独的原因致使身体受到的伤害。
    </p>
    <p>
      &#12288;&#12288;二、在本保险合同的保险期间内，被保险人遭受投保时约定的风险，保险人按下列约定给付保险金。
    </p>
    <p>&#12288;&#12288;（一）身故保险责任</p>
    <p>
      &#12288;&#12288;被保险人自意外伤害发生之日起180日内以该次意外伤害为直接原因身故的，保险人按保险单所载该类风险所对应的该被保险人意外伤害保险金额给付身故保险金。
    </p>
    <p>
      &#12288;&#12288;被保险人因遭受意外伤害事故且自该事故发生日起下落不明，后经中华人民共和国法院宣告死亡的，保险人按保险金额给付身故保险金。但若被保险人被宣告死亡后生还的，保险金申请人应于知道或应当知道被保险人生还后30日内退还保险人给付的身故保险金。
    </p>
    <p>
      &#12288;&#12288;被保险人身故或被宣告死亡前保险人已给付本条款约定的残疾保险金的，身故保险金应扣除已给付的保险金。
    </p>
    <p>&#12288;&#12288;（二）残疾保险责任</p>
    <p>
      &#12288;&#12288;被保险人自意外伤害发生之日起180日内以该次意外伤害为直接原因致《人身保险伤残评定标准及代码》[中国保险监督管理委员会发布(保监发[2014]6号)并经国家标准化委员会备案(JR/T
      0083-2013），下简称《人身保险伤残评定标准及代码》]所列残疾之一的，保险人按保险单所载该类风险所对应的该被保险人意外伤害保险金额及该项残疾所对应的给付比例给付残疾保险金。如自意外伤害发生之日起第180日时治疗仍未结束，按第180日的身体情况进行鉴定，并据此给付残疾保险金。<span
        >如被保险人的残疾程度不在所附《人身保险伤残评定标准及代码》之列，保险人不承担给付残疾保险金责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;人身保险伤残程度等级相对应的保险金给付比例分为十档，伤残程度第一级对应的保险金给付比例为100%，伤残程度第十级对应的保险金给付比例为10%，每级相差10%。
    </p>
    <p>
      &#12288;&#12288;当同一保险事故造成两处或两处以上残疾时，应首先对各处残疾程度分别进行评定，如果几处残疾等级不同，以最重的残疾等级作为最终的评定结论；如果最重的两处或两处以上伤残等级相同，残疾等级在原评定基础上最多晋升一级，最高晋升至第一级。<span
        >同一部位和性质的伤残，不应采用《人身保险伤残评定标准及代码》条文两条以上或者同一条文两次以上进行评定。</span
      >
    </p>
    <p>
      &#12288;&#12288;被保险人如在本次保险事故之前已有残疾，保险人按合并后的残疾程度在《人身保险伤残评定标准及代码》中所对应的给付比例给付残疾保险金，<span
        >但应扣除原有残疾程度在《人身保险伤残评定标准及代码》所对应的给付比例给付残疾保险金。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >（三）保险人对每一被保险人遭遇投保时约定的任一类风险所负的身故保险金、残疾保险金给付责任，以保险单所载该类风险所对应的该被保险人意外伤害保险金额为限，一次或累计给付的保险金达到该类风险所对应的意外伤害保险金额时，保险人对该被保险人所负的该类风险的保险责任终止。</span
      >
    </p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;<span>第五条 责任免除</span></p>
      <p>
        &#12288;&#12288;一、因下列原因之一，直接或间接造成被保险人身故、残疾的，保险人不负任何给付保险金责任：
      </p>
      <p>&#12288;&#12288;（一）投保人对被保险人的故意杀害或伤害；</p>
      <p>
        &#12288;&#12288;（二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；
      </p>
      <p>
        &#12288;&#12288;（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；
      </p>
      <p>
        &#12288;&#12288;（四）被保险人违法、犯罪或者抗拒依法采取的刑事强制措施；
      </p>
      <p>
        &#12288;&#12288;（五）被保险人因疾病导致的伤害，包括但不限于猝死、食物中毒、高原反应、中暑、病毒和细菌感染（意外伤害导致的伤口感染不在此限）；
      </p>
      <p>
        &#12288;&#12288;（六）被保险人因妊娠、流产、分娩导致的伤害，但意外伤害所致的流产或分娩不在此限；
      </p>
      <p>
        &#12288;&#12288;（七）被保险人因药物过敏、整容手术、内外科手术或其他医疗行为导致的伤害；
      </p>
      <p>&#12288;&#12288;（八）被保险人未遵医嘱私自服用、涂用、注射药物；</p>
      <p>
        &#12288;&#12288;（九）被保险人因意外伤害、自然灾害事故以外的原因失踪而被法院宣告死亡的；
      </p>
      <p>
        &#12288;&#12288;（十）被保险人从事高风险运动、参加任何职业或半职业体育运动，包括但不限于各种车辆表演、车辆竞赛或训练等；
      </p>
      <p>&#12288;&#12288;（十一）被保险人不遵守有关安全驾驶或乘坐的规定；</p>
      <p>
        &#12288;&#12288;（十二）被保险人驾驶超载机动车辆，因车辆超载引起的意外事故而遭受的伤害；
      </p>
      <p>
        &#12288;&#12288;（十三）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；
      </p>
      <p>&#12288;&#12288;（十四）恐怖袭击。</p>
      <p>
        &#12288;&#12288;二、下列任一情形下，保险人对被保险人身故、残疾不负任何给付保险金责任：
      </p>
      <p>&#12288;&#12288;（一）被保险人精神失常或精神错乱期间；</p>
      <p>&#12288;&#12288;（二）战争、军事行动、暴动或武装叛乱期间；</p>
      <p>&#12288;&#12288;（三）被保险人醉酒或受毒品、管制药物的影响期间；</p>
      <p>
        &#12288;&#12288;（四）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无有效行驶证的机动车期间。
      </p>
    </div>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第三部分 保险期间和保险金额
    </p>
    <p>&#12288;&#12288;<span>第六条 保险期间</span></p>
    <p>
      &#12288;&#12288;本保险合同保险期间由保险人和投保人协商确定，并在保单中载明。
    </p>
    <p>&#12288;&#12288;<span>第七条 保险金额</span></p>
    <p>&#12288;&#12288;本保险合同保险金额由投保人与保险人在投保时约定。</p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第四部分 保险金的申请和给付
    </p>
    <p>&#12288;&#12288;<span>第八条 保险金的申请</span></p>
    <p>
      &#12288;&#12288;保险金申请人向保险人申请给付保险金时，应提交以下材料。保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。<span
        >保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span
      >
    </p>
    <p>&#12288;&#12288;一、身故保险金申请</p>
    <p>&#12288;&#12288;（一）保险金给付申请书；</p>
    <p>&#12288;&#12288;（二）保险单原件；</p>
    <p>&#12288;&#12288;（三）保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;（四）公安部门出具的被保险人户籍注销证明、中华人民共和国境内二级以上（含二级）或保险人认可的医疗机构出具的被保险人身故证明书。若被保险人为宣告死亡，保险金申请人应提供中华人民共和国法院出具的宣告死亡证明文件；
    </p>
    <p>
      &#12288;&#12288;（五）事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>
      &#12288;&#12288;（六）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;（七）若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p>&#12288;&#12288;二、残疾保险金申请</p>
    <p>&#12288;&#12288;（一）保险金给付申请书；</p>
    <p>&#12288;&#12288;（二）保险单原件；</p>
    <p>&#12288;&#12288;（三）保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;（四）中华人民共和国境内二级以上（含二级）或保险人认可的医疗机构或司法鉴定机构出具的残疾程度鉴定诊断书；
    </p>
    <p>
      &#12288;&#12288;（五）事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>
      &#12288;&#12288;（六）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;（七）若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p>
      &#12288;&#12288;三、保险人认为保险金申请人提供的有关索赔的证明和资料不完整的，将及时一次性通知补充提供。
    </p>
    <p>&#12288;&#12288;<span>第九条 保险金的给付</span></p>
    <p>
      &#12288;&#12288;一、保险人收到被保险人的给付保险金的请求后，应当及时作出是否属于保险责任的核定；情形复杂的，应当在三十日内作出核定，但保险合同另有约定的除外。
    </p>
    <p>
      &#12288;&#12288;二、保险人应当将核定结果通知被保险人；对属于保险责任的，在与被保险人达成给付保险金的协议后十日内，履行给付保险金义务。保险合同对给付保险金的期限有约定的，保险人应当按照约定履行给付保险金的义务。保险人依照前款约定作出核定后，对不属于保险责任的，应当自作出核定之日起三日内向被保险人发出拒绝给付保险金通知书，并说明理由。
    </p>
    <p>
      &#12288;&#12288;三、保险人自收到给付保险金的请求和有关证明、资料之日起六十日内，对其给付的数额不能确定的，应当根据已有证明和资料可以确定的数额先予支付；保险人最终确定给付的数额后，应当支付相应的差额。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;第五部分 其他事项</p>
    <p>&#12288;&#12288;<span>第十条 释义</span></p>
    <p>&#12288;&#12288;除另有约定外，本保险合同中的下列词语具有如下含义：</p>
    <p>
      &#12288;&#12288;保险人：指与投保人签订本保险合同的中国太平洋财产保险股份有限公司。
    </p>
    <p>
      &#12288;&#12288;公共交通工具：主要指具有政府部门登记许可，从事旅客运输的各种公共汽车、大中型出租车、火车（包含其他轨道交通工具）、轮船（包含轮渡）、飞机等正在运营中的交通工具。
    </p>
    <p>
      &#12288;&#12288;高风险运动：指比一般常规性的运动风险等级更高、更容易发生人身伤害的运动，在进行此类运动前需有充分的心理准备和行动上的准备，必须具备一般人不具备的相关知识和技能或者必须在接受专业人士提供的培训或训练之后方能掌握。被保险人进行此类运动时须具备相关防护措施或设施，以避免发生损失或减轻损失，包括但不限于潜水，滑水，滑雪，滑冰，驾驶或乘坐滑翔翼、滑翔伞，跳伞，攀岩运动，探险活动，武术比赛，摔跤比赛，柔道，空手道，跆拳道，马术，拳击，特技表演，驾驶卡丁车，赛马，赛车，各种车辆表演、蹦极。
    </p>
    <p>
      &#12288;&#12288;醉酒：指被保险人血液中的酒精含量大于或者等于80mg/100mL。
    </p>
    <p>&#12288;&#12288;无有效驾驶证驾驶：指下列情形之一：</p>
    <p>&#12288;&#12288;（1）没有取得驾驶资格；</p>
    <p>&#12288;&#12288;（2）驾驶与驾驶证准驾车型不相符合的车辆；</p>
    <p>&#12288;&#12288;（3）持审验不合格的驾驶证驾驶；</p>
    <p>
      &#12288;&#12288;（4）持学习驾驶证学习驾车时，无教练员随车指导，或不按指定时间、路线学习驾车。
    </p>
    <p>&#12288;&#12288;无有效行驶证：指下列情形之一:</p>
    <p>&#12288;&#12288;（1）机动车被依法注销登记的；</p>
    <p>
      &#12288;&#12288;（2）未依法按时进行或通过机动车安全技术检验（有证据证明出险时的机动车符合机动车安全技术条件的除外）。
    </p>
    <p>
      &#12288;&#12288;保险金申请人：指受益人或被保险人的继承人或依法享有保险金请求权的其他自然人。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentInjury extends Vue {
  static name = "accident-injury";
}
export default AccidentInjury;
</script>
<style lang="scss" src="./index.scss" scoped></style>
