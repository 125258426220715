<template>
  <div class="document">
    <p class="font-weight-bold text-align">大树保险经纪隐私政策</p>
    <hr />
    <p><span>概述</span></p>
    <p>
      我们深知个人信息对您的重要性，也感谢您对北京大树保险经纪有限责任公司（以下简称“我们”或“大树保险经纪”）的信任。我们将通过本政策向您说明我们会如何收集、使用、保护、保存及对外提供您的信息，并说明您享有的权利，其中要点如下：
    </p>
    <p>
      1、为了便于您了解您在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。
    </p>
    <p>
      2、为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
    </p>
    <p>
      3、如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。
    </p>
    <p>
      4、如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
    </p>
    <p>
      5、您可以通过本政策介绍的方式访问和管理您的信息、设置隐私功能、注销大树保险经纪账户或进行投诉举报。
    </p>
    <p></p>
    <p>
      <span>您可以根据以下索引阅读相应章节，进一步了解本政策的具体约定：</span>
    </p>
    <p><span>一、我们如何收集和使用您的个人信息</span></p>
    <p><span>二、我们如何使用Cookie、Beacon、Proxy等技术</span></p>
    <p><span>三、我们如何共享、转让、公开披露、委托处理您的个人信息</span></p>
    <p><span>四、我们如何保护和保存您的个人信息</span></p>
    <p><span>五、您如何管理您的个人信息</span></p>
    <p><span>六、未成年人的个人信息保护</span></p>
    <p><span>七、通知和修订</span></p>
    <p><span>八、如何联系我们</span></p>
    <p><span>九、关键词说明</span></p>
    <p></p>
    <p>
      北京大树保险经纪有限责任公司（注册地址：北京市海淀区北清路中关村壹号D1座7层718；常用办公地址：北京市海淀区北清路中关村壹号D1座7层718）（以下简称“我们”）在您使用大树保险经纪服务时，将按照本隐私政策（以下简称“本政策”）收集、使用保护、保存及对外提供您的信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。
    </p>
    <p>
      为了遵守国家法律法规及监管规定（例如：进行实名制管理、履行反洗钱职责、安全管理），也为了向您提供服务及提升服务质量（例如：支持我们设计新服务或完善已有服务功能，为您提供和推荐更为优质或适合的服务），我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账户及资金安全。
    </p>
    <p>
      本政策适用于大树保险经纪平台，大树保险经纪平台指我们运营的网络平台，包括但不限于官网、微信小程序、微信公众号等。
    </p>
    <p></p>
    <p><span>一、我们如何收集和使用您的个人信息</span></p>
    <p><span>（一）我们如何收集您的信息</span></p>

    <p>
      在您使用大树保险经纪服务的过程中，在以下情形中我们需要收集您的一些信息，用以向您提供服务、提升我们的服务质量、保障您的账户和资金安全以及符合国家法律法规及监管规定：
    </p>
    <p>1、依据法律法规及监管规定履行反洗钱义务及进行实名制管理</p>
    <p>
      （1）<span>在您注册</span>大树保险经纪账户<span>时，您需提供手机号作为您的有效联系方式。</span>如您不提供前述信息，可能无法注册大树保险经纪账户。根据相关法律法规的规定，<span>您需通过身份基本信息多重交叉验证后方可使用我们的部分服务，为保障您的利益，提高账户使用的安全性，我们建议您进行实名认证（银行卡认证、证件认证），您需要提供姓名、身份证号、身份证正反面照片、银行卡号。</span>如您不提供前述信息，可能无法使用需要通过多重交叉验证后方可使用的部分服务，但不影响您使用我们提供的其他服务。同时，为了验证您提供信息的准确性和完整性，我们会与合法留存您的前述信息的国家机关、金融机构、企事业单位进行核对。
    </p>
    <p>
      您也可以使用第三方账号登录并使用大树保险经纪服务，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称、地区、性别信息以及其他页面提示的信息），用于与大树保险经纪账号绑定，使您可以通过第三方账号直接登录并使用大树保险经纪以及相关服务。我们将在您授权同意的范围内使用您的相关信息。
    </p>
    <p>
      （2）<span>我们需根据《支付机构反洗钱和反恐怖融资管理办法》等反洗钱相关法律法规及监管要求，提示您适时提供有效身份证件的彩色影印件或照片以供我们核对并留存；</span>如您不提供前述彩色影印件、照片或不同意我们留存，可能无法使用与监管要求相关的部分服务，但不影响您使用我们提供的其他服务。
    </p>
    <p>2、向您提供服务</p>
    <p>具体而言：</p>
    <p>
      （1）<span>保险经纪服务：</span>在您申购保险产品、申请修改保单、申请保险理赔、进行保险咨询时，我们会使用您的个人信息，这些信息可能包括：姓名、性别、生日、身份证号码、财产信息、风险测评信息、其他必要信息。
    </p>
    <p>
      （2）<span>在线客服：</span>为了更好的达成我们向您提供的服务，我们向您提供在线客服服务。
    </p>
    <p>
      （3）<span>个性化推送服务：</span>为提升您的服务体验及改进服务质量，我们可能会根据您的授权提供个性化推送业务。推送业务可能需要获取并使用您的<span>个人基本信息、设备信息。</span>如您不提供前述信息，您将不会收到该等个性化服务推荐，但不影响您使用我们提供的其他服务。如果您不想接收我们发送的营销信息，您可以通过短信回复或拨打我们的服务电话取消个性化推送业务。
    </p>

    <p>
      <span>（4）其他业务：</span
      >我们将会不断更新保险产品和服务，基于该服务,我们需要收集、使用您的信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息;如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
    </p>
    <p>3、安全管理</p>
    <p>
      为了保障向您提供的服务的安全稳定运行，预防交易和资金风险，我们需要记录您使用的服务类别、方式及相关操作信息，例如：<span>IP地址、所在地区、您的浏览器版本信息、网络使用习惯以及其他与大树保险经纪服务相关的日志信息。</span>如您不同意我们记录前述信息，可能无法完成风控验证。
    </p>
    <p>
      <span
        >4、根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:</span
      >
    </p>
    <p><span>（1）与个人信息控制者履行法律法规规定的义务相关的；</span></p>
    <p><span>（2）与国家安全、国防安全直接相关的；</span></p>
    <p><span>（3）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
    <p><span>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
    <p>
      <span
        >（5）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；</span
      >
    </p>
    <p><span>（6）所收集的个人信息是您自行向社会公众公开的；</span></p>
    <p><span>（7）根据您的要求签订和履行合同所必需的；</span></p>
    <p>
      <span
        >（8）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；</span
      >
    </p>
    <p>
      <span
        >（9）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置服务的故障；</span
      >
    </p>
    <p><span>（10）法律法规规定的其他情形。</span></p>
    <p>5、其他</p>
    <p>
      如您选择使用我们提供的其他服务，基于该服务我们需要收集您的信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
    </p>
    <p><span>（二）我们从第三方获得您个人信息的情形</span></p>
    <p>
      在取得您同意的前提下，我们可能从合法持有您个人信息的第三方机构获得您的个人信息，如技术、咨询服务供应商、金融机构等，这些合作机构会提供与我们服务相关的产品和服务，帮助检测和防止潜在的违法犯罪行为和违反我们政策的行为。
    </p>
    <p><span>（三）我们如何使用您的信息</span></p>
    <p>
      我们收集您信息的目的主要是为了向您提供安全、高效以及个性化的服务体验。我们会出于以下目的使用您的个人信息：
    </p>
    <p><span>1、进行身份证件认证、银行卡认证</span></p>
    <p>
      为了遵守个人用户实名制管理规定(反洗钱/反欺诈)和为您提供安全服务的需要，您同意并授权我们收集和使用您的<span>姓名、身份证号、身份证有效期限（开始时间-截止时间）、手机号码、银行卡号、银行卡有效期，</span>并以加密传输方式将其共享给提供验证服务的第三方机构进行一致性比对并输出核验结果；您同意并授权第三方机构使用您的个人信息用于验证服务并以加密传输的方式向我们返回核验结果。如果您不提供上述信息则无法使用根据中国相关法律法规必须进行实名制管理的相关服务。
    </p>
    <p><span>2、进行资格、信用审核</span></p>
    <p>
      <span
        >您在使用我们服务时，我们会使用您的个人基本信息、个人身份信息、个人财产信息及其他在具体业务开展过程中基于您的同意而采集的信息进行风险管理及控制，检测、预防及/或修复欺诈或其他潜在的非法活动。</span
      >如果您不提供上述信息，我们则无法在您使用服务过程中对您的身份、信用及偿付能力进行评估并决定是否提供您所需的服务，但不影响您使用我们提供的其他服务。
    </p>
    <p><span>3、为您提供您选择的相关服务</span></p>

    <p>
      <span
        >您在使用我们平台上的服务时，我们会使用您的个人身份信息、个人财产信息及其他在业务具体开展过程中基于您的同意而采集的信息对产品或服务进行适用性评估、验证服务真实性、处理产品或服务请求以及完成服务指令并向您发送服务通知等。</span
      >同时，为了遵守相关法律法规及监管规定，也为了便于您查询服务记录或历史状态，我们会将您使用的身份信息、服务信息及行为信息进行存档，并严格按照法律法规的规定对这些信息进行妥善保管。上述信息为开展我们服务所必需的信息，如果您不提供上述信息，将可能导致我们无法为您提供上述服务。
    </p>
    <p><span>4、提供客户服务及进行投诉处理</span></p>
    <p>
      <span
        >我们的在线客服会使用您的账号信息和服务信息。为保证您的账号安全，我们的在线客服会使用您的账号信息与您核验您的身份。</span
      >当您需要我们提供与您服务信息相关的客服与售后服务时，我们将会查询您的服务信息。
    </p>
    <p>
      为了保证您及他人的合法权益，如您被他人投诉或投诉他人，在必要时，我们会将您的<span>姓名及身份证号码、投诉相关内容</span>提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
    </p>
    <p><span>5、改进我们的产品以为您提供更为个性化的服务</span></p>
    <p>
      （1）我们会在采取脱敏、去标识化等方式对您的信息进行处理后再进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计服务及运营活动等；
    </p>
    <p>
      （2）为了提升您的服务体验，为您推荐更为优质或适合的服务，我们可能会根据您的信息形成特征标签，用于向您提供您可能感兴趣的营销活动通知、商业性电子信息或广告，<span
        >如您不希望接收此类信息，您可按照我们提示的方法选择退订，例如回复“TD”退订营销短信。</span
      >
    </p>
    <p>
      当我们要将信息用于本政策未载明的其他用途时，会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认动作等形式再次征得您的同意。
    </p>
    <p><span>6、保障服务安全所必须的功能</span></p>
    <p>
      <span
        >为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、服务信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接；我们也会收集您的设备信息对于我们系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</span
      >
    </p>
    <p><span>（四）您个人信息使用的规则</span></p>
    <p>
      1、我们会根据本政策的约定并为实现我们的产品与/或服务功能、履行协议、提供服务、解决争议、保障交易安全等目的对您所提供的以及我们收集的个人信息进行使用。
    </p>

    <p>
      2、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。您注销账号请求处理完成之后，我们将停止您使用该账号的权限并按照相关法律法规的要求删除您的个人信息或进行匿名化处理。
    </p>
    <p>
      3、当我们展示您的个人信息时，我们会根据《个人金融信息保护技术规范》（JR/T
      0171-2020）采用模糊化、不可逆等技术处理方式对您的信息进行脱敏，以保护您的信息安全。
    </p>
    <p>
      4、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
    </p>
    <p><span>二、我们如何使用Cookie、Beacon、Proxy等技术</span></p>
    <p>
      为使您获得更轻松的访问体验，您使用大树保险经纪提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。<span
        >这些数据文件可能是Cookie、Flash
        Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。</span
      >借助于 Cookie，在您通过浏览器访问大树保险经纪服务器后，服务器会传给浏览器一段特定的数据识别串。每次浏览器访问该服务器，都必须带上这段数据识别串进行校验，Cookie中不包括任何用户敏感信息。
    </p>
    <p>
      请您理解，我们的某些服务只能通过使用Cookie才可得到实现。<span>如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝大树保险经纪的Cookie。</span>多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据（例如：Flash
      Cookie）。但这一举动在某些情况下可能会影响您安全使用大树保险经纪提供的服务。
    </p>
    <p>
      我们网站上还可能包含一些电子图像（以下简称“网络Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集<span
        >您浏览网页活动的信息，例如：您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定</span
      >
    </p>
    <p>
      如您通过我们的网站，使用了由第三方而非大树保险经纪提供的服务时，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“Proxy技术”）。使用Proxy技术，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，例如：不安全路由器、非法基站等引起的广告注入、非法内容篡改等。在此过程中，我们也可能会获得和保存<span
        >关于您计算机的相关信息，例如：IP地址、硬件ID。</span
      >
    </p>
    <p><span>三、我们如何共享、转让、公开披露、委托处理您的个人信息</span></p>
    <p><span>（一）共享</span></p>

    <p>1、业务共享</p>
    <p>
      我们承诺对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括我们的关联公司以及其他合作伙伴。如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施，并且严格遵守相关法律法规与监管要求。
    </p>
    <p>
      事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
    </p>
    <p>2、第三方共享登录</p>
    <p>
      我们会将<span>您的登录信息如拉卡拉用户ID、注册手机号</span>共享给大树保险经纪关联方以便您可以使用同一账号登录多个拉卡拉平台。但我们只会共享必要的个人信息，且受本政策中所声明目的的约束。
    </p>
    <p>3、其它合作共享</p>
    <p>
      我们可能会向合作伙伴等第三方共享<span>您的服务信息、账户信息及设备信息，</span>以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：
    </p>
    <p>
      （1）提供技术、咨询服务的供应商。我们可能会将<span>您的手机号</span>等个人信息共享给支持我们提供服务的第三方。这些机构包括为我们提供基础设施技术服务、数据处理服务、电信服务、审计服务和法律服务等的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。
    </p>
    <p>
      （2）合作金融机构。这些机构可以向我们提供金融服务产品。除非您同意将这些信息用于其他用途，否则这些金融机构不得将此类信息用于相关产品之外的其他目的。
    </p>
    <p>
      4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
    </p>
    <p>
      5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护我们、您或其他我们客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，在取得您的同意后，我们可能会与银联、人民银行等监管机构或组织交换您的个人信息、服务信息及活动信息。不过,这并不代表我们会违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
    </p>
    <p>
      6、若您未能按照与我们及我们其他用户签订的协议等法律文本的约定履行应尽义务，我们有权将上述信息写入黑名单，且与必要第三方进行数据共享，以供我们平台及第三方审核、催收之用。
    </p>
    <p><span>（二）转让</span></p>
    <p>
      <span
        >我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span
      >
    </p>

    <p><span>1、事先获得您明确的同意或授权；</span></p>
    <p><span>2、根据法律法规或强制性的行政或司法要求；</span></p>
    <p>
      <span
        >3、在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束，如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。</span
      >
    </p>
    <p><span>（三）公开披露</span></p>
    <p>
      我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息。除此之外，原则上我们不会将您的信息进行公开披露。如确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。
    </p>
    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p>
      2、根据法律、法规的要求、强制性的行政执法、司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。
    </p>
    <p><span>（四）委托处理</span></p>
    <p>
      为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的我们的关联方或其他专业机构代表我们来处理信息。我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托公司不再保存个人信息。
    </p>
    <p>
      <span
        >（五）根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：</span
      >
    </p>
    <p><span>1、履行法律法规规定的义务相关的；</span></p>
    <p><span>2、与国家安全、国防安全直接相关的；</span></p>
    <p><span>3、与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
    <p><span>4、与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
    <p>
      <span
        >5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span
      >
    </p>
    <p><span>6、您自行向社会公众公开的个人信息；</span></p>
    <p>
      <span
        >7、从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道。</span
      >
    </p>
    <p><span>四、我们如何保护和保存您的个人信息</span></p>
    <p><span>（一）我们保护您个人信息的技术与措施</span></p>
    <p>
      我们非常重视个人信息安全，但互联网环境并非百分之百安全。我们承诺不会将您的信息恶意出售或免费共享给任何第三方且会尽量采取一切符合行业标准和惯例的措施保护您的个人信息。
    </p>
    <p>1、数据安全技术措施</p>
    <p>
      我们会采用符合业界标准的安全防护措施以及行业内通行的安全技术来防止您的个人信息遭到未经授权的访问、修改,避免您的个人信息泄露、损坏或丢失：
    </p>
    <p>（1）采取加密技术对您的个人信息进行加密存储。</p>
    <p>
      （2）我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保您的个人信息在传输过程中的安全。
    </p>
    <p>
      （3）在使用您的个人信息使用时，例如个人信息展示、个人信息关联计算，我们会根据《个人金融信息保护技术规范》（JR/T
      0171-2020）采用包括模糊化、不可逆在内的多种数据脱敏技术增强个人信息在使用中的安全性。
    </p>
    <p>
      （4）我们存储您个人数据的系统从安全管理、策略、过程、网络体系结构等诸多方面保障交易及个人信息安全。
    </p>
    <p>2、为保护个人信息采取的其他安全措施</p>
    <p>
      我们通过建立数据分类分级、数据安全策略、安全开发规范来管理规范个人信息的存储和使用：
    </p>
    <p>
      （1）我们采用严格的数据访问权限和多重身份认证技术控制和保护个人信息，通过与信息接触者签署严格的保密协议、监控和审计机制来对数据进行全面安全控制，避免数据被违规使用。
    </p>
    <p>（2）我们采用代码安全检查、数据访问日志分析技术进行个人信息安全审计。</p>
    <p>
      （3）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>3、安全事件处置</p>
    <p>
      （1）为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
    </p>
    <p>
      （2）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>
    <p><span>（二）您个人信息的保存</span></p>
    <p>
      1、我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如部分服务涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，向您说明个人信息出境的目的以及涉及的个人信息类型，征得您的同意，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。
    </p>
    <p>
      2、除非法律法规另有规定，您的个人信息将被我们保存至您账号注销之日，包括手机号、身份信息、实名认证信息、银行卡信息等。我们承诺这是为了保证您作为大树保险经纪用户的权益所必需的最短期间。当您的个人信息超出该期限后，我们会对您的个人信息进行删除或匿名化处理。
    </p>
    <p>
      3、如果我们终止服务或运营，我们会至少提前三十日通知您，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
    </p>
    <p><span>五、您如何管理您的个人信息</span></p>
    <p>
      我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括
    </p>
    <p><span>1、访问和更正您的个人信息</span></p>
    <p>
      除法律法规规定外，您有权随时访问下述您的个人信息并随时更正其中的部分信息，具体包括：
    </p>
    <p>（1）您的用户信息</p>
    <p>
      <span
        >进入官网首页，通过“我的大树→账户管理→账户安全”，可修改您的登录密码或接收手机验证码的手机号。</span
      >
    </p>
    <p><span>进入官网首页，通过“我的大树→账户管理→个人信息”。</span></p>
    <p>（2）您的服务信息</p>
    <p><span>订单信息：进入官网首页，通过“我的大树→订单管理”。</span></p>
    <p><span>保单信息：进入官网首页，通过“我的大树→订单管理→已支付”。</span></p>
    <p>
      （3）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时通过本政策中的联系方式联系我们。我们会在十五个工作日内进行处理。
    </p>
    <p>
      （4）您无法访问和/或更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和/或更正的服务，这些信息主要是为了保证交易安全满足相关强制性法律法规要求所收集的您的设备信息、您使用产品时产生的个人信息。上述信息我们会在您的授权范围内按照相关法律法规的规定进行使用，您无法变更或自主删除。
    </p>
    <p><span>2、删除您的个人信息</span></p>
    <p><span>在以下情形中，您可以向我们提出删除个人信息的请求:</span></p>
    <p><span>（1）如果我们处理个人信息的行为违反法律法规；</span></p>
    <p><span>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</span></p>
    <p><span>（3）如果我们处理个人信息的行为违反了与您的约定；</span></p>
    <p><span>（4）如果您注销了大树保险经纪账户；</span></p>
    <p><span>（5）如果我们终止服务及运营。</span></p>
    <p>
      若我们决定响应您的删除请求，我们会根据您的要求及相关法律法规的要求进行后续删除处理并向您进行结果反馈。
    </p>
    <p><span> 3、注销账户 </span></p>
    <p>
      <span
        >进入官网首页，通过“免费注册→注销”，您可以申请注销您的大树保险经纪账户。
      </span>
    </p>
    <p>
      <span>
        4、尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：
      </span>
    </p>
    <p><span>（1）与个人信息控制者履行法律法规规定的义务相关的； </span></p>
    <p><span>（2）与国家安全、国防安全直接相关的；</span></p>
    <p><span>（3）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
    <p><span>（4）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span></p>
    <p><span>（5）有充分证据表明您存在主观恶意或滥用权利的；</span></p>
    <p>
      <span
        >（6）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</span
      >
    </p>
    <p>
      <span
        >（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
      >
    </p>
    <p><span>（8）涉及商业秘密的。</span></p>
    <p>
      <span
        >（9）您的账户中存有未办结的业务，如账户还有余额或有债务未清偿。 </span
      >
    </p>
    <p><span>5、投诉与申诉</span></p>
    <p>
      您可以通过本政策提供的联系渠道联系我们，并就您在使用服务过程中发生的事项进行投诉，也可以通过该渠道就我们对您的信息进行的自动处理进行申诉。我们将在十五个工作日内进行处理。
    </p>
    <p>
      <span
        >您可以通过本政策提供的联系渠道联系我们，并就您在使用服务过程中发生的事项进行投诉，也可以通过该渠道就我们对您的信息进行的自动处理进行申诉。我们将在十五个工作日内进行处理。</span
      >
    </p>
    <p>
      1、我们非常重视对未成年人个人信息的保护。您应当为十八周岁以上、符合中华人民共和国法律规定的具有完全民事权利和民事行为能力，能够独立承担民事责任的人。如您不具备上述资格，您应立即停止在我们平台的注册程序、停止使用我们平台服务。
    </p>
    <p>
      2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
    </p>
    <p>
      3、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
    </p>
    <p><span>七、通知和修订</span></p>
    <p>
      1、为给您提供更好的服务以及随着我们业务的发展，本政策也会随之更新。但未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会通过在我们的平台、移动端上发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新，也请您访问我们的平台或移动端以便及时了解最新的隐私政策。
    </p>
    <p>
      2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于推送通知、公告、弹窗提示、发送邮件/短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。<span
        >本政策所指的重大变更包括但不限于：</span
      >
    </p>
    <p>
      <span
        >（1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；</span
      >
    </p>
    <p><span>（2）对外提供个人信息的对象、范围、目的发生变化；</span></p>
    <p><span>（3）您访问和管理个人信息的方式发生变化；</span></p>
    <p><span>（4）数据安全能力、信息安全风险发生变化；</span></p>
    <p>
      <span
        >（5）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；</span
      >
    </p>
    <p><span>（6）其他可能对您的个人信息权益产生重大影响的变化。</span></p>
    <p><span>八、如何联系我们</span></p>
    <p>
      我们设立了信息安全部作为个人信息保护的专职部门，并配有信息保护负责人。如果您对本政策有任何疑问、意见或建议，可以通过以下渠道联系我们的信息保护负责人：
    </p>
    <p><span>（1）客服热线021-60870045；</span></p>
    <p><span>（2）在线客服；</span></p>
    <p><span>（3）发送电子邮件至service@bigtreebroker.com。</span></p>
    <p>我们将尽快审核所涉问题，并在十五个工作日内予以回复。</p>
    <p><span>九、关键词说明</span></p>
    <p>
      <span
        >本政策所述的大树保险经纪关联方是指：拉卡拉支付股份有限公司、拉卡拉云商网络有限公司、拉卡拉云商科技有限公司、拉卡拉信息科技（上海）有限公司、广东云商网联信息服务有限公司、西藏考拉科技发展有限公司、西藏考拉金科网络科技服务有限公司、北京拉卡拉小额贷款有限责任公司、重庆市拉卡拉小额贷款有限公司、广州拉卡拉网络小额贷款有限责任公司、拉卡拉电子商务有限公司、深圳众赢维融科技有限公司、广州众赢科技有限公司、拉卡拉信用管理有限公司、北京云考拉信用管理有限公司、拉卡拉汇积天下技术服务（北京）有限公司、海南云商互联科技有限公司、云码智能（深圳）科技有限公司、江苏千米网络科技股份有限公司等。</span
      >
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class TrafficPolicy extends Vue {
  static name = "traffic-policy";
}
export default TrafficPolicy;
</script>
<style lang="scss" src="./index.scss" scoped></style>
