<template>
  <div>
    <van-form ref="form" v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'">
      <van-field
        readonly
        clickable
        class="form-item"
        v-if="value.date"
        :value="form.date"
        :name="value.date"
        :label="value.date"
        placeholder="点击选择日期"
      />
      <slot name="titleTwo"></slot>
      <van-calendar v-model="showCalendar" @confirm="onDateConfirm" />
      <van-field
        v-if="value.name"
        class="form-item"
        v-model="form.name"
        :name="value.name"
        :label="value.name"
        :placeholder="`请输入投保人${value.name}`"
        :rules="[
          {
            required: true,
            trigger: 'onChange',
            validator: validatorName,
            message: msgName,
          },
        ]"
      />
      <van-field
        v-if="value.idCard"
        class="form-item"
        v-model="form.idCard"
        :label="value.idCard"
        :name="value.idCard"
        :placeholder="`请输入投保人${value.idCard}`"
        :rules="[
          {
            required: true,
            message: msgIdCard,
            validator: validatorIdCard,
            trigger: 'onChange',
          },
        ]"
      />
      <van-field
        v-if="value.phone"
        class="form-item"
        v-model="form.phone"
        :label="value.phone"
        :name="value.phone"
        :placeholder="`请输入投保人${value.phone}`"
        :rules="[
          {
            required: true,
            message: msgPhone,
            validator: validatorPhone,
            trigger: 'onChange',
          },
        ]"
      />
      <van-field
        v-if="value.carNum && this.productInfo.productCode != 'H013'"
        class="form-item"
        v-model="form.carNum"
        :label="value.carNum"
        :name="value.carNum"
        :placeholder="`请输入投保人${value.carNum}`"
        :rules="[
          {
            message: msgEmail,
            validator: validatorCar,
            trigger: 'onChange',
          },
        ]"
      />
      <van-field
        v-if="value.carNum && this.productInfo.productCode == 'H013'"
        class="form-item"
        v-model="form.carNum"
        :label="value.carNum"
        :name="value.carNum"
        :placeholder="`请输入投保人${value.carNum}`"
        :rules="[
          {
            message: msgCarNUm,
            validator: validatorCar,
            trigger: 'onChange',
          },
        ]"
      />
      <slot name="titleThree"></slot>
      <ul v-if="value.relation" class="relation">
        <li class="active" @click="handler(item)">本人</li>
      </ul>
      <ul v-else class="relation">
        <li :class="active" @click="handler(item)">
          {{ this.form.relation }}
        </li>
      </ul>
      <div v-if="insuredVisible">
        <van-field
          v-if="value.inSuredName"
          class="form-item"
          v-model="form.inSuredName"
          :label="value.inSuredName"
          :name="value.inSuredName"
          :placeholder="`请输入被投保人${value.inSuredName}`"
          :rules="[
            {
              required: true,
              message: '请填写被投保人姓名',
            },
          ]"
        />
        <van-field
          v-if="value.inSuredIdCard"
          class="form-item"
          v-model="form.inSuredIdCard"
          :label="value.inSuredIdCard"
          :name="value.inSuredIdCard"
          :placeholder="`请输入被投保人${value.inSuredIdCard}`"
          :rules="[
            {
              required: true,
              message: msgInSuredIdCard,
              validator: validatorIdCard,
              trigger: 'onChange',
            },
          ]"
        />
        <van-field
          v-if="value.inSuredphone"
          class="form-item"
          v-model="form.inSuredphone"
          :label="value.inSuredphone"
          :name="value.inSuredphone"
          :placeholder="`请输入投保人${value.inSuredphone}`"
          :rules="[
            {
              required: true,
              message: msgPhone,
              validator: validatorPhone,
              trigger: 'onChange',
            },
          ]"
        />
      </div>
      <van-field
        v-if="value.address"
        v-model="form.address"
        class="form-item"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="showCascader = true"
        :rules="[{ required: true, message: '请选择地区' }]"
      />
      <van-popup v-model="showCascader" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="showCascader = false"
          @change="onAddressChange"
          @finish="onAddressFinish"
          ref="cascader"
        />
      </van-popup>
      <van-field
        v-if="value.address"
        class="form-item"
        v-model="form.detailAddress"
        :name="value.address"
        label="详细地址"
        :placeholder="`请输入详细地址`"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
    </van-form>
    <van-form ref="form" v-else>
      <van-field
        readonly
        clickable
        class="form-item"
        v-if="value.date"
        :value="form.date"
        :name="value.date"
        :label="value.date"
        placeholder="点击选择日期"
        @click="showCalendar = true"
      />
      <slot name="titleTwo"></slot>
      <van-calendar
        v-if="this.productInfo.productCode != 'H014'"
        v-model="showCalendar"
        @confirm="onDateConfirm"
      />
      <van-field
        v-if="value.name"
        class="form-item"
        v-model="form.name"
        :name="value.name"
        :label="value.name"
        :placeholder="`请输入投保人${value.name}`"
        :rules="[{ required: true, message: '请填写投保人姓名' }]"
      />
      <van-field
        v-if="value.idCard"
        class="form-item"
        v-model="form.idCard"
        :label="value.idCard"
        :name="value.idCard"
        :placeholder="`请输入投保人${value.idCard}`"
        :rules="[
          {
            required: true,
            message: msgIdCard,
            validator: validatorIdCard,
            trigger: 'onChange',
          },
        ]"
      />
      <van-field
        v-if="value.phone"
        class="form-item"
        v-model="form.phone"
        :label="value.phone"
        :name="value.phone"
        :placeholder="`请输入投保人${value.phone}`"
        :rules="[
          {
            required: true,
            message: msgPhone,
            validator: validatorPhone,
            trigger: 'onChange',
          },
        ]"
      />
      <van-field
        v-if="value.email && this.productInfo.productCode != 'H014'"
        class="form-item"
        v-model="form.email"
        :label="value.email"
        :name="value.email"
        :placeholder="`请输入投保人${value.email}`"
        :rules="[
          {
            message: msgEmail,
            validator: validatorEmail,
            trigger: 'onChange',
          },
        ]"
      />
      <slot name="titleThree"></slot>
      <ul
        v-if="value.relation && this.productInfo.productCode != 'H011'"
        class="relation"
      >
        <li
          v-for="item of relation"
          :key="item.id"
          :class="item.active ? 'active' : ''"
          @click="handler(item)"
        >
          {{ item.label }}
        </li>
      </ul>
      <ul v-else class="relation">
        <li :class="active" @click="handler(item)">
          {{ this.form.relation }}
        </li>
      </ul>
      <div v-if="insuredVisible">
        <van-field
          v-if="value.inSuredName"
          class="form-item"
          v-model="form.inSuredName"
          :label="value.inSuredName"
          :name="value.inSuredName"
          :placeholder="`请输入被投保人111${value.inSuredName}`"
          :rules="[
            {
              required: true,
              validator: validatorName,
              message: msgName,
            },
          ]"
        />
        <van-field
          v-if="value.inSuredIdCard"
          class="form-item"
          v-model="form.inSuredIdCard"
          :label="value.inSuredIdCard"
          :name="value.inSuredIdCard"
          :placeholder="`请输入被投保人${value.inSuredIdCard}`"
          :rules="[
            {
              required: true,
              message: msgInSuredIdCard,
              validator: validatorIdCard,
              trigger: 'onChange',
            },
          ]"
        />
        <van-field
          v-if="value.inSuredphone"
          class="form-item"
          v-model="form.inSuredphone"
          :label="value.inSuredphone"
          :name="value.inSuredphone"
          :placeholder="`请输入投保人${value.inSuredphone}`"
          :rules="[
            {
              required: true,
              message: msgPhone,
              trigger: 'onChange',
            },
          ]"
        />
      </div>
      <van-field
        v-if="value.address"
        v-model="form.address"
        class="form-item"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="showCascader = true"
        :rules="[{ required: true, message: '请选择地区' }]"
      />
      <van-popup v-model="showCascader" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="showCascader = false"
          @change="onAddressChange"
          @finish="onAddressFinish"
          ref="cascader"
        />
      </van-popup>
      <van-field
        v-if="value.address"
        class="form-item"
        v-model="form.detailAddress"
        :name="value.address"
        label="详细地址"
        :placeholder="`请输入详细地址`"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
    </van-form>
    
  </div>
</template>
<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import { components } from "./options";
import { areaSelect } from "@/api/product.js";
import { State, namespace } from "vuex-class";
import { Toast } from "vant";
const ProductModule = namespace("product");
const ProductState = ProductModule.State;
const ProductGetters = ProductModule.Getter;

@Component({
  components,
})
class InsureForm extends Vue {
  static name = "insure-form";
  @ProductState("productInfo") productInfo;
  @Prop({ type: Object, default: () => {} }) value;
  @State("product") product;
  // @ProductGetters("currentPlan") currentPlan;

  showCalendar = false;
  insuredVisible = false;
  columns = [];
  showPicker = false;
  houseAddress = "";
  showCascader = false;
  cascaderValue = "";
  options = [];
  provinceIndex = -1; //选择省的次序
  cityIndex = -1; //选择城市的次序
  form = {
    name: "",
    idCard: "",
    email: "",
    phone: "",
    carNum: "",
    date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${
      new Date().getDate() + 1
    }`,
    inSuredphone: "",
    inSuredName: "",
    inSuredIdCard: "",
    relation: "本人",
    visilbleForm: false,
    address: "",
    detailAddress: "",
    provinceCode: "",
    postCode: "",
    cityCode: "",
    areaCode: "",
    countryCode: "",
  };
  msgIdCard = "请输入投保人身份证号";
  msgName = "请输入投保人姓名";
  msgInSuredIdCard = "请输入被投保人身份证号";
  msgPhone = "请输入投保人手机号";
  msgEmail = "请填写投保人邮箱";
  msgCarNUm = "请填写投保人车牌号码";
  relation = [
    {
      label: "本人",
      id: "1",
      active: true,
    },
    {
      label: "配偶",
      id: "2",
      active: false,
    },
    {
      label: "父母",
      id: "3",
      active: false,
    },
    {
      label: "孩子",
      id: "4",
      active: false,
    },
  ];
  relations = [
    {
      label: "父亲",
      id: "3",
      active: false,
    },
    {
      label: "母亲",
      id: "4",
      active: false,
    },
    {
      label: "配偶父亲",
      id: "5",
      active: false,
    },
    {
      label: "配偶母亲",
      id: "6",
      active: false,
    },
    {
      label: "子女一",
      id: "7",
      active: false,
    },
    {
      label: "子女二",
      id: "8",
      active: false,
    },
  ];
  created() {
    this.insuredVisible = false;
    this.getAddress();
    console.log(this.product.productQuery.productCode);
    if (this.product.productQuery.productCode === "H007") {
      this.relation.splice(2, 2);
      this.relations.forEach((err) => {
        this.relation.push(err);
      });
      console.log(this.relation);
    }
  }
  setChildren(target) {
    return this.$set(target, "children", [{}]);
  }
  async getAddress() {
    const options = await areaSelect({ areaCode: "" });
    options.forEach(this.setChildren);
    this.options = options;
  }
  async onAddressChange(obj) {
    const { tabIndex, selectedOptions, value: areaCode } = obj;
    // 判断是否是最底层
    const lastOpt = selectedOptions[selectedOptions.length - 1] || {};
    const isLowest = lastOpt.isLowest === "Y";
    if (isLowest) {
      this.onAddressFinish(obj);
    } else {
      this.setChildren(selectedOptions[tabIndex]);
      const options = await areaSelect({ areaCode });
      obj.selectedOptions[tabIndex].children = options;
    }
  }
  async onAddressFinish({ selectedOptions }) {
    this.showCascader = false;
    this.form.provinceCode = selectedOptions[0].text;
    this.form.postCode = selectedOptions[0].code;
    this.form.cityCode = selectedOptions[1].text;
    this.form.areaCode = selectedOptions[1].code;
    if (selectedOptions.length > 2) {
      this.form.countryCode = selectedOptions[2].text;
    }
    this.form.address = selectedOptions.map((option) => option.text).join("");
  }
  onDateConfirm(date) {
    this.form.date = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    this.showCalendar = false;
  }
  onHouseAddressConfirm(value, index) {
    this.houseAddress = value;
    console.log(this.houseAddress);
    this.showPicker = false;
    this.form.houseAddressCode = index;
  }
  async onSubmit() {
    await this.$refs.form
      .validate()
      .then(() => {
        if (this.form.inSuredIdCard === this.form.idCard) {
          console.log(111);
        } else {
          if (!this.insuredVisible) {
            this.form.inSuredName = this.form.name;
            this.form.inSuredIdCard = this.form.idCard;
            if (this.value.inSuredphone)
              this.form.inSuredphone = this.form.phone;
          }
          this.form.visilbleForm = true;
        }
      })
      .catch(() => {});
  }
  handler(item) {
    console.log(item);
    if (this.product.productQuery.productCode === "H007") {
      if (item.id === "1") {
        Toast.fail("本人不可取消");
      }
      var ids = item.id - 1;
      this.relation[ids].active = !this.relation[ids].active;
      this.relation[0].active = true;
    } else if (item.id !== "1") {
      this.relation.map((item) => (item.active = false));
      item.active = true;
      this.insuredVisible = true;
      this.form.inSuredIdCard = "";
      this.form.inSuredName = "";
    } else {
      this.relation.map((item) => (item.active = false));
      item.active = true;
      this.insuredVisible = false;
    }

    this.form.relation = item.label;
  }
  validatorIdCard(val) {
    const reg =
      /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
    if (reg.test(val)) {
      return true;
    } else {
      this.msgIdCard = "身份证号格式不正确";
      return false;
    }
  }
  validatorName(val) {
    const reg = /^[\u4E00-\u9FA5]{2,10}$/;
    if (reg.test(val)) {
      return true;
    } else {
      this.msgName = "姓名格式不正确";
      return false;
    }
  }
  validatorInSuredIdCard(val) {
    const reg =
      /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
    if (reg.test(val)) {
      if (this.IdCard(val) < 14) {
        return true;
      } else {
        this.msgIdCardTwo = "被保人年龄要小于14岁";
        return false;
      }
    } else {
      this.msgIdCardTwo = "身份证号格式不正确";
      return false;
    }
  }
  validatorPhone(val) {
    const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (reg.test(val)) {
      return true;
    } else {
      this.msgPhone = "手机号格式不正确";
      return false;
    }
  }
  validatorEmail(val) {
    const reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
    if (reg.test(val)) {
      return true;
    } else {
      this.msgEmail = "电子邮箱格式不正确";
      return false;
    }
  }
  validatorCar(val) {
    const reg =
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
    if (reg.test(val)) {
      return true;
    } else {
      this.msgCarNUm = "车牌号格式不正确";
      return false;
    }
  }
  IdCard(UUserCard) {
    //获取年龄
    let myDate = new Date();
    let month = myDate.getMonth() + 1;
    let day = myDate.getDate();
    let age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    if (
      UUserCard.substring(10, 12) < month ||
      (UUserCard.substring(10, 12) == month &&
        UUserCard.substring(12, 14) <= day)
    ) {
      age++;
    }
    return age;
  }
}
export default InsureForm;
</script>
<style lang="scss" src="./index.scss" scoped></style>
