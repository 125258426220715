<template>
  <div class="document">
    <!-- 1 -->
    <img :src="this.image" alt="" width="100%" height="100%" />
    <p style="height: 1rem; width: 100%"></p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { getProduct } from "@/api/order";

@Component({})
class SinopecInfectious extends Vue {
  image = "";
  static name = "sinopec-infectious";
  created() {
    getProduct().then((res) => {
      console.log(res);
      this.image = res.clausePicture.fireInsurance;
    });
  }
}
export default SinopecInfectious;
</script>
<style lang="scss" src="./index.scss" scoped></style>
