<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加意外伤害救护车费用保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432322021122029513</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同可附加于各类个人类人身意外伤害保险合同（以下简称“主险合同”）。本保险合同由保险条款、投保单、保险单、保险凭证以及批单等组成。凡涉及本保险合同的约定，均应采用书面形式。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>
      &#12288;&#12288;第二条
      在本保险合同保险期间内，被保险人因遭受主险合同保险责任范围内的意外伤害事故，并自事故发生之时起24小时内发生的合理且必要的救护车费用，保险人在保险单载明的救护车费用保险金额内，按实际支出赔偿救护车费用。<span
        >此项赔偿不包括转院时发生的救护车费用。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >保险人对被保险人救护车费用的给付总额以保险单所载救护车费用保险金额为限。一次或累计给付的保险金达到救护车费用保险金额时，保险人对该被保险人的保险责任终止。</span
      >
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;责任免除</p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;第三条 本保险合同的责任免除如下：</p>
      <p>
        &#12288;&#12288;一、主险合同无效或失效，保险人不负任何给付保险金责任。
      </p>
      <p>
        &#12288;&#12288;二、主险合同中列明的“责任免除”事项，也适用于本保险合同。
      </p>
      <p>&#12288;&#12288;三、在下列情形下，保险人不负任何给付保险金责任：</p>
      <p>
        &#12288;&#12288;（一）被保险人非因主险合同保险责任范围内的意外伤害事故而发生的救护车费用；
      </p>
      <p>
        &#12288;&#12288;（二）被保险人送往非二级以上或保险人认可的医疗机构而发生的救护车费用，但意外伤害急救至就近医院不在此限。
      </p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额</p>
    <p>
      &#12288;&#12288;第四条　被保险人的救护车费用保险金额由投保人、保险人双方约定，并在保险单中载明。保险金额一经确定，中途不得变更。
    </p>
    <p>
      &#12288;&#12288;第五条　投保人应该按照合同约定向保险人交纳保险费。<span
        >保险费未交清前，本保险合同不生效，保险人不承担赔偿责任。</span
      >
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;受益人的指定和变更
    </p>
    <p>
      &#12288;&#12288;第六条　除另有指定外，本保险合同的受益人为被保险人本人。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;保险金的申请与给付
    </p>
    <p>
      &#12288;&#12288;第七条　保险金申请人向保险人申请给付保险金时，应提交以下材料。<span
        >保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span
      >
    </p>
    <p>&#12288;&#12288;一、保险金给付申请书；</p>
    <p>&#12288;&#12288;二、保险单原件；</p>
    <p>&#12288;&#12288;三、受益人身份证明；</p>
    <p>&#12288;&#12288;四、救护车费用收据原件；</p>
    <p>
      &#12288;&#12288;五、事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>&#12288;&#12288;六、保险金申请人所能提供的其他与本项申请相关的材料；</p>
    <p>
      &#12288;&#12288;七、若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>
      &#12288;&#12288;第八条
      被保险人须在二级（含）以上或保险人认可的医疗机构治疗。意外伤害急救可不受此限，但经急救情况稳定后，须转入二级（含）以上或保险人认可的医疗机构治疗。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;条款适用</p>
    <p>
      &#12288;&#12288;第九条
      本保险合同所记载事项，如与主险合同相抵触之处，以本保险合同为准，未尽事宜，适用主险合同的规定。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentAmbulance extends Vue {
  static name = "accident-ambulance";
}
export default AccidentAmbulance;
</script>
<style lang="scss" src="./index.scss" scoped></style>
