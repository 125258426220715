// loading状态
export const LOADING = "LOADING";
// 是否已经关闭
export const HAS_SHOW = "HAS_SHOW";

// 请求取消标示
export const SET_TOKEN = "SET_TOKEN";
export const CLEAN_TOKEN = "CLEAN_TOKEN";

// 保存产品编号
export const PRODUCT_QUERY = "PRODUCT_QUERY";
// 获取产品信息
export const GET_PRODUCT = "GET_PRODUCT";
// 保存产品信息
export const SET_PRODUCT = "SET_PRODUCT";
// 选择当前计划id
export const CURRENT_PLAN = "CURRENT_PLAN";

// 获取订单
export const GET_ORDER = "GET_ORDER";
// 设置订单信息
export const SET_ORDER = "SET_ORDER";
