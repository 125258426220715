<template>
  <div class="document">
    <p class="font-weight-bold text-align">投保须知</P>
    <p>【基本信息】</p>
    <p>
      1.本产品为阳光财险家庭财产险，由阳光财产保险股份有限公司承保，该公司在北京、山东、江苏、黑龙江、重庆、河南、上海、广东、深圳、天津、青岛、辽宁、河北、海南、广西、湖南、浙江、贵州、四川、青海、云南、陕西、湖北、吉林、新疆、宁波、山西、大连、甘肃、安徽、江西、福建、内蒙古、厦门、宁夏、西藏设有分公司。
    </p>
    <p>
      2.本产品适用条款：家庭财产综合保险A款条款（2015版）（阳光财险）（备-家财）[2015]（主）13号、附加管道破裂及水渍第三者责任保险A款条款（2015版）（阳光财险）（备-责任）[2015]（附）6号、附加管道破裂及水渍损失保险A款条款（2015版）（阳光财险）（备-家财）[2015]（附）5号。
    </p>
    <p>3.销售区域：本产品保障区域限于中国境内，不包括港澳台地区。</p>
    <p>【服务流程】</p>
    <p style="overflow-wrap:break-word;">
      1.保单形式：网上投保为你提供电子保单，根据《民法典》规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。或登录http://wecare.sinosig.com/common/new_customerservice/html/baodanfuwu/dzbd_index.html，对电子保单的真实性进行验证。
    </p>
    <p>
      2.发票形式：本产品提供电子发票，电子发票效力等同于纸质发票，报销可将电子发票打印后直接报销。如您需要发票，请拨打95510进行申请。
    </p>
    <p>
      3.承保形式：如你填写个人投保信息，阳光产险在收到投保信息后进行核保，若核保通过后，阳光产险收到保费后出具保单，保险合同成立；若核保不通过，则投保人无需支付保费，保险合同不成立。
    </p>
    <p>
      4.保单退保：请拨打95510进行申请，会有专人和您联系。请按要求提供相关资料，审核通过后完成办理。
    </p>
    <p>
      5.保单批改：请拨打95510进行申请，会有专人和您联系。请按要求提供相关资料，审核通过后完成办理。
    </p>
    <p>6.理赔流程：</p>
    <p>第1步：理赔报案</p>
    <p>
      发生事故后48小时内拨打阳光财险客服热线95510，阳光财险将为您提供理赔指引服务。
    </p>
    <p>第2步：提交材料</p>
    <p>根据阳光财险的要求准备理赔材料，将材料寄送至阳光财险。</p>
    <p>第3步：材料审核</p>
    <p>阳光财险收到完备的材料后，及时进行审核和调查。</p>
    <p>第4步：完成理赔</p>
    <p>审核通过后支付理赔款至保险金申请人指定账户。</p>
    <p>【投保规则】</p>
    <p>1.投保房屋结构仅限钢混、砖混，其他结构不予承保；</p>
    <p>
      2.免赔设置：管道破裂及水渍责任、水渍三者责任每次事故免赔500元，赔付比例80%；
    </p>
    <p>3.投保房屋仅限普通住宅；</p>
    <p>4.每个房屋限投保1份，投保时录入房屋地址精确到门牌号码。</p>
    <p>【免责条款】</p>
    <div class="font-weight-bold">
      <p>《家庭财产综合保险A款条款（2015版）》责任免除：</p>
      <p>第七条 下列原因造成的损失、费用，保险人不负责赔偿：</p>
      <p>
        （一）核辐射、核爆炸、核污染等任何放射性污染，大气污染、土地污染、水污染及其他各种非放射性污染，但因本保险合同责任范围内的事故造成的污染不在此限；
      </p>
      <p>（二）地震、海啸、火山爆发，及其次生灾害；</p>
      <p>
        （三）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、盗窃、抢劫；
      </p>
      <p>（四）行政行为或司法行为；</p>
      <p>
        （五）投保人、被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员的故意、违法行为，或未按使用说明、操作规范或相关国家标准擅自改装、改造保险标的的行为。
      </p>
      <p>第八条下列损失、费用，保险人也不负责赔偿：</p>
      <p>（一）保险标的遭受保险事故引起的任何间接损失；</p>
      <p>
        （二）家用电器因使用过度、超电压、短路、断路、漏电、自身发热、烘烤等原因所造成本身的损毁；
      </p>
      <p>
        （三）保险标的本身缺陷、保管不善、使用不当、变质、霉烂、受潮、虫咬、自然磨损、自然损耗、自燃、烘焙导致的本身的损失；
      </p>
      <p>
        （四）保险标的在保险单载明地址的房屋外遭受的损失，但安装在房屋外的空调器和太阳能热水器等家用电器的室外设备除外；
      </p>
      <p>
        （五）坐落在蓄洪区、行洪区，或在江河岸边、低洼地区以及防洪堤以外当地常年警戒水位线以下的家庭财产，由于洪水所造成的一切损失；
      </p>
      <p>
        （六）保险事故造成的玻璃单独破损，但与玻璃为一体的其他保险标的一并发生损失的情形除外；
      </p>
      <p>
        （七）本保险合同约定的免赔额或按本保险合同中载明的免赔率计算的免赔额；
      </p>
      <p>（八）其他不属于保险合同责任范围内的损失和费用。</p>
      <p>《附加管道破裂及水渍损失保险A款条款（2015版）》责任免除：</p>
      <p>第三条 下列原因造成的损失，保险人不负责赔偿：</p>
      <p>
        （一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
      </p>
      <p>
        （二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
      </p>
      <p>（三）水暖管安装、检修、试水、试压；</p>
      <p>（四）施工造成的水暖管破裂。</p>
      <p>第四条 下列损失、费用，保险人不负赔偿责任：</p>
      <p>（一）水暖管破裂致使供水中断造成的损失；</p>
      <p>
        （二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
      </p>
      <p>（三）其他不属于本附加险合同责任范围内的损失和费用。</p>
      <p>《附加管道破裂及水渍第三者责任保险A款条款（2015版）》责任免除：</p>
      <p>第三条 下列原因造成的损失，保险人不负责赔偿：</p>
      <p>
        （一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
      </p>
      <p>
        （二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
      </p>
      <p>（三）水暖管安装、检修、试水、试压；</p>
      <p>（四）施工造成的水暖管破裂。</p>
      <p>第四条 下列损失、费用，保险人不负赔偿责任：</p>
      <p>（一）水暖管破裂致使供水中断造成的损失；</p>
      <p>
        （二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
      </p>
      <p>（三）其他不属于本附加险合同责任范围内的损失和费用。</p>
    </div>
    <p>【重要告知】</p>
    <p>
      1.如实告知：请如实填写投保信息，如未履行如实告知义务的，保险公司有权依法解除保险合同，并对合同解除前发生的保险事故不承担保险责任。
    </p>
    <p>
      相关授权：如你投保此保险，视为你本人授权阳光产险出于提升保险服务质量之目的，合法的从任何单位、组织和个人获取本人有关的资料和证明，作为审核投保、处理理赔及提供与本保险有关其他的依据。
    </p>
    <p>
      2.偿付能力告知：阳光产险偿付充足率已达到监管要求，如需进一步查看，请登录官网（https://www.sinosig.com/）查询，该信息可作为您决定是否投保的参考信息。
    </p>
    <p>【其他信息】</p>
    <p>
      1.个人信息安全保障：本平台承诺对投保人/被保险人身份信息、投保交易信息和投保交易安全等保密事项依法进行保障。
    </p>
    <p>
      2.本人已阅读本《投保须知》和《保险条款》，尤其是对其中免除保险人责任的条款（包括不限于责任免除、投保人被保险人义务、保险金申请与给付等），本人已充分理解并接受上述内容。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class FamilyPropertyNotice extends Vue {
  static name = "family-property-notice";
}
export default FamilyPropertyNotice;
</script>
<style lang="scss" src="./index.scss" scoped></style>
