<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加公共场所个人责任保险条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：H00001430922017052422811</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同附加于各类人身保险合同（以下简称“主险合同”），依主险合同投保人的申请，经保险人审核同意而订立。主险合同效力终止，本保险合同效力亦终止；主险合同无效，本保险合同亦无效。主险合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以主险合同为准。
    </p>
    <p>
      &#12288;&#12288;第二条
      凡主险合同的被保险人，均可作为本保险合同的被保险人。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>
      &#12288;&#12288;第三条
      在保险期间内，被保险人本人于公共场所内因其疏忽或过失致使第三者遭受人身伤害或财产损失，依事故发生地法律规定应由被保险人承担的经济赔偿责任，保险人根据本保险合同的约定负责赔偿。
    </p>
    <p>
      &#12288;&#12288;第四条
      被保险人因保险事故而被提起仲裁或者诉讼的，事先经保险人书面同意、应由被保险人支付的必要的、合理的法律费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿。
    </p>
    <div class="font-weight-bold">
      <p class="text-align">&#12288;&#12288;责任免除</p>
      <p>
        &#12288;&#12288;第五条
        除主险合同规定的责任免除事项保险人不予赔偿外，对于下列损失、费用或责任，保险人也不予赔偿：
      </p>
      <p>&#12288;&#12288;（一）任何性质的间接损失；</p>
      <p>
        &#12288;&#12288;（二）被保险人因合同或协议所承担的赔偿责任。但即使无该项合同或协议存在时依法仍应由被保险人负赔偿责任的除外；
      </p>
      <p>
        &#12288;&#12288;（三）被保险人对租赁、代人保管、管理或控制的财物负有的损失赔偿责任；
      </p>
      <p>
        &#12288;&#12288;（四）被保险人因从事商业或与其职业相关事务导致的赔偿责任；
      </p>
      <p>
        &#12288;&#12288;（五）被保险人因所有或使用或管理飞机、船舶及依法应领有牌照的车辆导致的赔偿责任；
      </p>
      <p>
        &#12288;&#12288;（六）被保险人违反公共场所的管理规定进入或滞留公共场所期间产生的赔偿责任；
      </p>
      <p>
        &#12288;&#12288;（七）在同一份保险合同中的各个被保险人之间，及在一份保险合同中的投保人和被保险人之间的赔偿责任；
      </p>
      <p>
        &#12288;&#12288;（八）在共同预定了旅行并一起出行的人之间的赔偿责任；
      </p>
      <p>&#12288;&#12288;（九）由于使用任何武器导致的赔偿责任；</p>
      <p>&#12288;&#12288;（十）被保险人的故意行为；</p>
      <p>
        &#12288;&#12288;（十一）由于对土地，空气或者水（包括水域）的环境影响导致的赔偿责任及由此导致的其它所有赔偿责任；
      </p>
      <p>&#12288;&#12288;（十二）因各种传染疾病所导致的赔偿责任；</p>
      <p>
        &#12288;&#12288;（十三）被保险人从事竞技、比赛、特技表演所导致的赔偿责任；
      </p>
      <p>&#12288;&#12288;（十四）精神损害赔偿、罚款、罚金或惩罚性赔偿；</p>
      <p>
        &#12288;&#12288;（十五）保险单上载明的应由被保险人自行承担的免赔额（率）。
      </p>
    </div>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;赔偿限额与免赔额（率）
    </p>
    <p>&#12288;&#12288;第六条 赔偿限额包括每次事故赔偿限额和累计赔偿限额。</p>
    <p>
      &#12288;&#12288;各项赔偿限额由投保人与保险人协商确定，并在保险单上载明。
    </p>
    <p>
      &#12288;&#12288;第七条
      本保险合同的每次事故免赔额（率）由投保人与保险人在签订保险合同时协商确定，并在保险单上载明。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;一般事项</p>
    <p>
      &#12288;&#12288;第八条
      被保险人收到索赔人的损害赔偿请求时，应立即通知保险人。<span>未经保险人书面同意，被保险人对受害人及其代理人做出的任何承诺、拒绝、出价、约定、付款或赔偿，保险人不受其约束。对于被保险人自行承诺或支付的赔偿金额，保险人有权重新核定，不属于本保险责任范围或超出应赔偿限额的，保险人不承担赔偿责任。</span>在处理索赔过程中，保险人有权自行处理由其承担最终赔偿责任的任何索赔案件，被保险人有义务向保险人提供其所能提供的资料和协助。
    </p>
    <p>
      &#12288;&#12288;第九条
      被保险人获悉可能发生诉讼、仲裁时，应立即以书面形式通知保险人；接到法院传票或其他法律文书后，应将其副本及时送交保险人。保险人有权以被保险人的名义处理有关诉讼或仲裁事宜，被保险人应提供有关文件，并给予必要的协助。
    </p>
    <p>
      &#12288;&#12288;<span
        >对因未及时提供上述通知或必要协助导致扩大的损失，保险人不承担赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;第十条 被保险人请求赔偿时，应向保险人提供下列证明和资料：
    </p>
    <p>&#12288;&#12288;（一）提出索赔的书面报告和损失项目清单；</p>
    <p>&#12288;&#12288;（二）保险单正本和保险费交付凭证；</p>
    <p>&#12288;&#12288;（三）事故情况证明；</p>
    <p>
      &#12288;&#12288;（四）生效的法律文书（包括裁定书、裁决书、判决书、调解书等）；
    </p>
    <p>
      &#12288;&#12288;（五）投保人、被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料。
    </p>
    <p>
      &#12288;&#12288;<span
        >被保险人未履行前款约定的索赔材料提供义务，导致保险人无法核实损失情况的，保险人对无法核实部分不承担赔偿责任。</span
      >
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;赔偿处理</p>
    <p>
      &#12288;&#12288;第十一条
      发生保险事故后，保险人的赔偿以下列方式之一确定的被保险人的赔偿责任为基础：
    </p>
    <p>&#12288;&#12288;（一）被保险人与索赔人协商并经保险人确认；</p>
    <p>&#12288;&#12288;（二）仲裁机构裁决；</p>
    <p>&#12288;&#12288;（三）人民法院判决；</p>
    <p>&#12288;&#12288;（四）保险人认可的其他方式。</p>
    <p>
      &#12288;&#12288;第十二条
      发生保险事故造成第三者的人身伤害或财产损失的，保险人按以下方式计算赔偿：
    </p>
    <p>
      &#12288;&#12288;（一）对于每次事故造成的人身伤害及财产损失，保险人在保险单中载明的每次事故赔偿限额内计算赔偿；
    </p>
    <p>
      &#12288;&#12288;（二）在依据本条第（一）项计算的基础上，保险人在扣除保险单中载明的每次事故免赔额后进行赔偿；
    </p>
    <p>
      &#12288;&#12288;（三）在保险期间内，保险人对多次事故损失的赔偿金额累计不超过保险单中载明的累计赔偿限额。
    </p>
    <p>
      &#12288;&#12288;第十三条保险人对法律费用的赔偿在第十二条计算的赔偿金额以外另行计算，并且赔偿时不扣减每次事故免赔额（率），但每次事故的赔偿金额不超过保险单上载明的每次事故赔偿限额的10%。
    </p>
    <p>
      &#12288;&#12288;第十四条<span
        >被保险人给第三者造成损害，被保险人未向该第三者赔偿的，保险人不得向被保险人赔偿保险金。</span
      >
    </p>
    <p>
      &#12288;&#12288;第十五条
      发生保险事故时，如果被保险人的损失在有相同保障的其他保险项下也能够获得赔偿，则本保险人按照本保险合同的赔偿限额与其他保险合同及本保险合同的赔偿限额总和的比例承担赔偿责任。
    </p>
    <p>
      &#12288;&#12288;<span>其他保险人应承担的赔偿金额，本保险人不负责垫付。</span>若被保险人未如实告知导致保险人多支付赔偿金的，保险人有权向被保险人追回多支付的部分。
    </p>
    <p>
      &#12288;&#12288;第十六条
      发生保险责任范围内的损失，应由有关责任方负责赔偿的，保险人自向被保险人赔偿保险金之日起，在赔偿金额范围内代位行使被保险人对有关责任方请求赔偿的权利，被保险人应当向保险人提供必要的文件和所知道的有关情况。
    </p>
    <p>
      &#12288;&#12288;被保险人已经从有关责任方取得赔偿的，保险人赔偿保险金时，可以相应扣减被保险人已从有关责任方取得的赔偿金额。
    </p>
    <p>
      &#12288;&#12288;<span>保险事故发生后，在保险人未赔偿保险金之前，被保险人放弃对有关责任方请求赔偿权利的，保险人不承担赔偿责任；</span>保险人向被保险人赔偿保险金后，被保险人未经保险人同意放弃对有关责任方请求赔偿权利的，该行为无效；由于被保险人故意或者因重大过失致使保险人不能行使代位请求赔偿的权利的，保险人可以扣减或者要求返还相应的保险金。
    </p>
    <p>
      &#12288;&#12288;第十七条
      <span
        >保险赔偿结案后，保险人不再负责赔偿任何新增加的与该次保险事故相关的损失、费用或赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;当一次保险事故涉及多名第三者时，如果保险人和被保险人双方已经确认了其中部分第三者的赔偿金额，保险人可根据被保险人的申请予以先行赔付。先行赔付后，保险人不再负责赔偿与这些第三者相关的任何新增加的赔偿金。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>
      &#12288;&#12288;第十八条
      除另有约定外，本保险合同中的下列词语具有如下含义：
    </p>
    <p>
      &#12288;&#12288;公共场所：是指提供不同性别、年龄、职业、民族或国籍、不同健康状况、不同人际从属关系的个体组成的流动人群进行工作、学习、经济、文化、社交、娱乐、体育、参观、医疗、卫生、休息、旅游和满足部分生活需求所使用的一切公用建筑物、场所及其设施的总称。
    </p>
    <p>
      &#12288;&#12288;人身伤害：是指死亡、肢体残疾、组织器官功能障碍及其他影响人身健康的损伤。
    </p>
    <p>&#12288;&#12288;第三者：是指被保险人及其家庭成员以外的自然人。</p>
    <p>
      &#12288;&#12288;家庭成员：是指被保险人的直系血亲、配偶、养父母或有扶养关系的继父母、岳父母或公婆、养子女或有扶养关系的继子女、兄弟姐妹、同父异母或同母异父的兄弟姐妹、养兄弟姐妹或有扶养关系的继兄弟姐妹。
    </p>
    <p>
      &#12288;&#12288;每次事故：是指在保险期间内，一名或多名第三者或其他索赔权利人基于同一原因或理由，单独或共同向被保险人提出的，属于保险责任范围内的一项或一系列索赔或民事诉讼。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentLiability extends Vue {
     static name = "accident-liability";
}
export default AccidentLiability;
</script>
<style lang="scss" src="./index.scss" scoped></style>
