<template>
  <div class="document">
    <p class="font-weight-bold text-align">保险公司信息披露</p>
    <p>1 承保公司：本产品由中国太平洋财产保险股份有限公司上海分公司承保。</p>
    <p>
      2
      保单及发票形式:本产品投保成功后提供电子保单和电子发票，您可通过太平洋产险官方网站www.cpic.com.cn查询和下载电子保单及电子发票。数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力，请妥善保存。
    </p>
    <p>3 本产品支持的支付方式：a：信用卡；b：借记卡等</p>
    <p>【服务流程】</p>
    <p>
      4
      偿付能力告知:太平洋产险最近季度偿付能力符合监管要求，详情请参见太平洋产险官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      5
      风险综合评价:太平洋产险风险管理能力评估结果位居行业前列，详情请参见太平洋产险官网（http://property.cpic.com.cn/）公开信息披露。
    </p>
    <p>
      6
      客户服务与投诉：本产品投保咨询、保单查询、验真及客户投诉等，可拨打太平洋全国统一客服电话：021-95500，或登录太平洋保险官网：www.cpic.com.cn。
    </p>
    <p>7 理赔争议流程：</p>
    <p>
      (一)保险人收到被保险人或者受益人的赔偿请求后，应当及时作出核定；情形复杂的，应当在三十日内作出核定；保险人应当将核定结果通知被保险人或者受益人；对属于保险责任的，在与被保险人或者受益人达成赔偿协议后十日内，履行赔偿义务。保险合同对赔偿的期限有约定的，保险人应当按照约定履行赔偿义务。对于不属于保险责任的，应当自作出核定之日起三日内向被保险人或者受益人发出拒绝赔偿通知书，并说明理由。
    </p>
    <p>
      (二)争议处理：因履行本保险合同发生的争议，由当事人协商解决。协商不成的，提交保险单载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向中华人民共和国（不含港、澳、台地区，下同）法院起诉。
    </p>
    <p>
      (三)司法管辖：与本保险合同有关的以及履行本保险合同产生的一切争议处理适用中华人民共和国法律。
    </p>
    <p>
      (四)索赔时效：保险金申请人向保险人请求给付保险金的诉讼时效期间为二年，自其知道或者应当知道保险事故发生之日起计算。
    </p>
    <p>(五)未尽事宜：其余未详细描述事宜可详见条款</p>
    <p>
      8 保险公司分支机构：保险公司分支机构：北京市、上海市、天津市、重庆市、黑龙江省、吉林省、辽宁省、河北省、山西省、山东省、安徽省、江苏省、浙江省、福建省、江西省、广东省、海南省、广西壮族自治区、湖南省、湖北省、河南省、云南省、贵州省、四川省、陕西省、甘肃省、新疆维吾尔自治区、宁夏回族自治区、内蒙古自治区、青海省、西藏自治区。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class messagetwo extends Vue {
  static name = "messagetwo";
}
export default messagetwo;
</script>
<style lang="scss" src="./index.scss" scoped></style>
