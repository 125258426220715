<template>
  <div class="document">
    <p class="font-weight-bold text-align">责任免责条款说明书</p>
    <p>尊敬的客户您好：</p>
    <p>
      感谢您选择中国太平洋财产保险股份有限公司的保险产品，当您投保本保险产品后，我司将根据您选择的险种，按照保险合同的约定，承担相应的保险赔偿责任。
    </p>
    <p>
      风险无处不在，风险种类多种多样，并非所有风险给被保险人带来的损失均可获得赔偿，我司一般通过保险条款中保险责任及责任免除条款等对保险责任范围进行明确。通过免除保险人责任的条款内容把保险公司不承担保险责任的情形和事由予以明确。
    </p>
    <p>
      基于上述情况，为维护您的合法权益，我司就该产品在保险合同中的免责条款及相应内容作出如下说明，请您仔细阅读。
    </p>
    <p><span>&#xa0;</span></p>
    <div class="font-weight-bold">
      <p>个人账户资金损失保险条款免责事项：</p>
      <p>一、出现下列任一情形时，保险人不负责赔偿：</p>
      <p>
        （一）被保险人未按照规定使用动态密码、验证码、数字证书等安全保障工具；
      </p>
      <p>
        （二）被保险人发现账户、用户名、密码已被他人知悉或丢失数字证书、接收动态密码的手机后，未及时变更相关账户密码并且通知账户管理方冻结或挂失账户。
      </p>
      <p>二、由于下列原因造成的损失，保险人不负责赔偿：</p>
      <p>（一）被保险人的故意或重大过失行为；</p>
      <p>（二）被保险人同意他人使用其个人账户或被他人诈骗；</p>
      <p>（三）被保险人在未遭受暴力威胁的情况下向他人透露账号或密码；</p>
      <p>（四）被保险人未遵守银行账户及第三方支付账户使用规定；</p>
      <p>（五）账户管理方自身系统出现故障。</p>
      <p>三、对于下列损失，保险人不负责赔偿：</p>
      <p>（一）个人账户在挂失或冻结前72小时以外的损失；</p>
      <p>（二）利息；</p>
      <p>（三）间接损失；</p>
      <p>（四）保险单中载明的应由被保险人自行承担的每次事故免赔额。</p>
      <p>四、其他不属于本合同责任范围内的损失，保险人不负责赔偿。</p>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class PersonalPropertyDisclaimer extends Vue {
    static name = "personal-property-disclaimer";
}
export default PersonalPropertyDisclaimer;
</script>
<style lang="scss" src="./index.scss" scoped></style>
