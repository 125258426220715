import { Tab, Tabs } from "vant";
import BottomButton from "@/components/BottomButton";
import accidentLiability from "@/components/documents/accidentLiability";
import accidentDeath from "@/components/documents/accidentDeath";
import accidentInfectious from "@/components/documents/accidentInfectious";
import accidentInjury from "@/components/documents/accidentInjury";
import accidentHighRisk from "@/components/documents/accidentHighRisk";
import accidentFracture from "@/components/documents/accidentFracture";
import accidentAmbulance from "@/components/documents/accidentAmbulance";
import accidentMedical from "@/components/documents/accidentMedical";
import accidentBenefit from "@/components/documents/accidentBenefit";
import accidentInjuryA from "@/components/documents/accidentInjuryA";
import accidentDisclaimer from "@/components/documents/accidentDisclaimer";
import accidentNotice from "@/components/documents/accidentNotice";
import { registerComponent } from "@/utils";
import agreement from "@/components/documents/agreement";
import messagetwo from "@/components/documents/messagetwo";

export const components = registerComponent({
  BottomButton,
  Tab,
  Tabs,
  accidentLiability,
  accidentDeath,
  accidentInfectious,
  accidentInjury,
  accidentHighRisk,
  accidentFracture,
  accidentAmbulance,
  accidentMedical,
  accidentBenefit,
  accidentInjuryA,
  accidentDisclaimer,
  accidentNotice,
  agreement,
  messagetwo,
});

export default { components };
