<template>
  <van-overlay :z-index="999" :show="loading">
    <div class="wrapper">
      <van-loading color="#1989fa" size="34" type="circular" />
    </div>
  </van-overlay>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { State } from "vuex-class";

@Component
class LoadingOverlay extends Vue {
  static name = "loading-overlay";
  @State("loading") loading;
}
export default LoadingOverlay;
</script>

<style lang="scss" scope>
.wrapper {
  @include allCenter;
  height: 100vh;
  width: 100vw;
}
</style>
