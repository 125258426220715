import { Button, NavBar, Toast, SubmitBar } from "vant";
import { registerComponent } from "@/utils";
import LoadingOverlay from "@/components/LoadingOverlay";
import InsureTitle from "@/components/insure/insureTitle";
import InsureForm from "@/components/insure/insureForm";
import SubmitButton from "@/components/SubmitButton";

export const components = registerComponent({
  Button,
  NavBar,
  LoadingOverlay,
  InsureTitle,
  InsureForm,
  Toast,
  SubmitBar,
  SubmitButton,
});

export default { components };
