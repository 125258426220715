<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加意外伤害住院津贴保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432522021121011953</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同附加于含意外伤害保险责任的各类个人类人身保险合同（以下简称“主险合同”），依主险合同投保人的申请，经保险人审核同意而订立。主险合同效力终止，本保险合同效力亦终止；主险合同无效，本保险合同亦无效。主险合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以主险合同为准。
    </p>
    <p>
      &#12288;&#12288;第二条
      凡主险合同的被保险人，均可作为本保险合同的被保险人。
    </p>
    <p>
      &#12288;&#12288;第三条 除另有约定外，本保险合同的受益人为被保险人本人。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>
      &#12288;&#12288;第四条
      在本保险合同保险期间内，保险人按下列约定承担保险责任：
    </p>
    <p>
      &#12288;&#12288;一、被保险人遭受主险合同责任范围内的意外伤害，在保险人认可的医疗机构住院治疗，保险人对于被保险人每次住院的实际住院天数，扣除保险单约定的免赔天数后，按保险单约定的日津贴金额给付意外伤害住院津贴金。
    </p>
    <p>
      &#12288;&#12288;二、被保险人住院治疗，到保险期满仍未结束的，保险人继续给付住院津贴金，除另有约定外，最长可至本保险合同期满之日起第30日止。
    </p>
    <p>
      &#12288;&#12288;三、被保险人单次住院治疗的，住院津贴金的给付天数以保险单约定的单次给付天数为限；若被保险人多次住院，保险人对被保险人给付住院津贴金的，以保险单约定的累计给付天数为限。<span
        >实际给付天数达到保险单约定的累计给付天数，保险人对该被保险人的保险责任终止。住院津贴保险金单次给付天数最高不超过90天，累计给付天数最高不超过180天。</span
      >
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;责任免除</p>
    <div>
      <p>&#12288;&#12288;第五条 本保险合同的责任免除如下：</p>
      <p>
        &#12288;&#12288;一、主险合同无效或失效，保险人不负任何给付保险金责任。
      </p>
      <p>
        &#12288;&#12288;二、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。
      </p>
      <p>&#12288;&#12288;三、在下列情形下，保险人不负任何给付保险金责任：</p>
      <p>
        &#12288;&#12288;（一）被保险人非因主险合同所列意外伤害事故而发生的治疗；
      </p>
      <p>&#12288;&#12288;（二）被保险人矫形、整容、美容、器官移植的治疗；</p>
      <p>&#12288;&#12288;（三）被保险人体检、疗养、心理咨询或康复治疗；</p>
      <p>&#12288;&#12288;（四）被保险人在非保险人认可的医疗机构的治疗。</p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额和保险费</p>
    <p>&#12288;&#12288;第六条 保险金额是保险人承担给付保险金责任的最高限额。</p>
    <p>
      &#12288;&#12288;住院日津贴金额、单次给付天数、累计给付天数投保时由保险双方约定，并在保险单中载明。单次给付天数最高不超过90天，累计给付天数最高不超过180天。保险金额一经确定，中途不得变更。
    </p>
    <p>&#12288;&#12288;投保人应该按照合同约定向保险人交纳保险费。</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险期间</p>
    <p>
      &#12288;&#12288;第七条
      除另有约定外，本保险合同保险期间为一年，自保险单载明的起始日零时开始，至约定的终止日24时止。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;投保人解除保险合同
    </p>
    <p>
      &#12288;&#12288;第八条
      若投保人解除主险合同，本保险合同一并解除，合同解除后，保险人参照主险合同有关规定退还本保险合同的未满期净保险费。如投保人申请解除本保险合同，须填写解除合同申请书并向保险人提供下列资料：
    </p>
    <p>&#12288;&#12288;一、保险合同；</p>
    <p>&#12288;&#12288;二、投保人的有效身份证件。</p>
    <p>
      &#12288;&#12288;自保险人收到解除合同申请书时起，本保险合同终止。保险人自收到解除合同申请书之日起
      30 日内向投保人退还本保险合同的未满期净保险费。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>&#12288;&#12288;第九条 注意事项如下：</p>
    <p>
      &#12288;&#12288;一、被保险人须在保险人认可的医疗机构治疗。意外伤害急救不受此限，但经急救情况稳定后，须转入规定级别或保险人认可的医疗机构治疗。
    </p>
    <p>
      &#12288;&#12288;二、被保险人因医疗条件限制，确需转院治疗，必须有转出医院主治医师以上级别人员签署的会诊报告及转院证明。
    </p>
    <p>
      &#12288;&#12288;三、本保险合同所记载事项，如与主险合同相抵触之处，以本保险合同为准，未尽事宜，适用主险合同的规定。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金申请与给付</p>
    <p>
      &#12288;&#12288;第十条
      保险金申请人向保险人申请给付保险金时，应提交以下材料。保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。
    </p>
    <p>&#12288;&#12288;一、保险金给付申请书；</p>
    <p>&#12288;&#12288;二、保险单原件；</p>
    <p>&#12288;&#12288;三、保险金申请人的身份证明；</p>
    <p>&#12288;&#12288;四、保险人认可的医疗机构出具的病历和住院证明；</p>
    <p>
      &#12288;&#12288;五、事发当地政府有关部门出具的意外伤害事故证明或者中华人民共和国驻该国的使、领馆出具的意外伤害事故证明；
    </p>
    <p>
      &#12288;&#12288;六、保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;七、若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>
      &#12288;&#12288;第十一条
      除另有约定外，本保险合同中的下列词语具有如下含义：
    </p>
    <p>
      &#12288;&#12288;保险人：指与投保人签订本保险合同的中国太平洋财产保险股份有限公司。
    </p>
    <p>
      &#12288;&#12288;意外伤害：指以外来的、突发的、非本意的和非疾病的客观事件为直接且单独的原因致使身体受到的伤害。
    </p>
    <p>
      &#12288;&#12288;住院：指被保险人因意外伤害而入住医院的病房进行治疗，并办理入出院正式手续，不包括入住门诊观察室、家庭病床及其它不合理的住院。
    </p>
    <p>
      &#12288;&#12288;每次住院：指被保险人因意外伤害住院治疗，自入院日起至出院日止的期间，但若因同一原因再次住院，且前次出院与下次入院间隔未超过30天，视为同一次住院。
    </p>
    <p>
      &#12288;&#12288;未满期净保险费：未满期净保险费＝净保险费×[1-(保险单已经过天数/保险期间天数)]=保险费×（1-费用比例）×[1-(保险单已经过天数/保险期间天数)]。经过日数不足一日的按一日计算。
    </p>
    <p>
      &#12288;&#12288;净保险费指投保人所交纳的保险费扣除每张保险单平均承担的保险人各项费用（含营业费用、代理费、各项税金、保险保障基金等）后的余额，费用比例同主险规定。
    </p>
    <p>
      &#12288;&#12288;保险人认可的医疗机构:保险公司在保险单、批单或者批注中列明的医疗机构。未约定定点医院的，则指中华人民共和国境内（港、澳、台地区除外）合法经营的二级以上（含二级）公立医院。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentBenefit extends Vue {
  static name = "accident-benefit";
}
export default AccidentBenefit;
</script>
<style lang="scss" src="./index.scss" scoped></style>
