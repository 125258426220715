import Http from "@/utils/request";
import { takeEnv } from "@/utils/tools";
require("./glutton-saas.rr.min.js");
// require("../sdk/index.js");
const { gluttonUrl, isPrd } = takeEnv();
let started = false;
function getGluttonRequestToken() {
  // 获取访问Glutton后台服务的Token
  return Http.request({
    url: gluttonUrl,
    data: { id: "bigtree", credential: "w7LgN8slWK" },
    method: "POST",
  });
}

class Glutton {
  async run(Vue) {
    Vue.prototype.$Glutton = {};

    const data = await getGluttonRequestToken();
    // serverHost: 'https://www.bigtreebroker.com',//这里不写就默认https://glutton-demo.ebaocloud.com.cn
    // let params = isPrd ? { serverHost: "https://www.bigtreebroker.com" } : {};
    let params = { serverHost: "https://www.bigtreebroker.com" }
    const gluttonRecorder = new window.GluttonRecorder({
      firstToken: data.token,
      disableLogs: false,
      dataAutoPlay: false,
      ...params,
    });

    const startRecording = () => {
      if (started) {
        console.log("Started Already!");
      }
      started = true;
      console.log("Start Recording!");
      gluttonRecorder.startRecord();
    };

    const stopRecording = () => {
      if (!started) {
        console.log("No Started!");
      }
      console.log("End Recording!");
      gluttonRecorder.stopRecord();
    };
    Object.assign(Vue.prototype.$Glutton, {
      startRecording,
      stopRecording,
      ...takeEnv(),
    });
  }
}

export default new Glutton();
