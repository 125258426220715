<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加扩展高风险活动保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432322021122029953</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;第一部分 基本条款</p>
    <p class="font-weight-bold">&#12288;&#12288;第一条 合同效力</p>
    <p>
      &#12288;&#12288;本保险合同附加于含意外伤害保险责任的各类个人人身保险合同（以下简称“所附合同”），依所附合同投保人的申请，经保险人审核同意而订立。所附合同效力终止，本保险合同效力亦终止；所附合同无效，本保险合同亦无效。所附合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以所附合同为准。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;第二部分 保险责任和责任免除
    </p>
    <p class="font-weight-bold">&#12288;&#12288;第二条 保险责任</p>
    <p>
      &#12288;&#12288;在本保险合同的保险期间内，被保险人在国家旅游管理部门许可的旅游景点或合法娱乐场所或以保险单特别注明的职业运动员身份参加高风险活动时遭受主险合同保险责任范围内的意外伤害，保险人根据所附合同的约定承担保险责任。
    </p>
    <p>
      &#12288;&#12288;本保险合同所称高风险活动，指被保险人参加在洞穴、极地、火山、冰川、森林、峡谷、沙漠、海洋、太空或任何无人区进行的探险、考察和旅游，被保险人进行赛马或练习赛马、马术表演或练习、马球、车辆表演或竞赛、练习车辆表演或竞赛、赛艇、滑板表演或竞赛、滑水、跳水、戴水肺潜水、滑雪表演或竞赛、跳高滑雪、大雪撬、雪橇、滑冰表演或竞赛、冰球、攀岩、攀登海拔三千五百米以上独立山峰、蹦极跳、跳伞、武术、拳击、摔跤和跆拳道运动、翼装飞行，被保险人置身于任何飞行器或空中运输工具（包括但不限于滑翔机、滑翔翼、滑翔伞、动力伞、气球）期间，不包括以乘客身份搭乘普通商业航班者。
    </p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;第三条　责任免除</p>
      <p>
        &#12288;&#12288;一、所附合同中列明的“责任免除”事项（除本保险合同第二条所指的高风险活动外），也适用于本保险合同。
      </p>
      <p>&#12288;&#12288;二、下列情形下，保险人不负任何给付保险金责任：</p>
      <p>
        &#12288;&#12288;（一）被保险人参与可获得固定报酬的体育运动，被保险人以职业运动员身份参加的任何体育运动，但保险单特别注明的除外；
      </p>
      <p>
        &#12288;&#12288;（二）被保险人在国家旅游管理部门许可的旅游景点、合法娱乐场所以外的其他地点从事任何高风险活动；
      </p>
      <p>
        &#12288;&#12288;（三）在参与本保险合同所指的高风险活动之前，被保险人知道或应当知道其身体条件不适合参与该高风险活动。
      </p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;第三部分 其他事项</p>
    <p class="font-weight-bold">&#12288;&#12288;第四条 释义</p>
    <p>&#12288;&#12288;除另有约定外，本保险合同中的下列词语具有如下含义：</p>
    <p>
      &#12288;&#12288;保险人：指与投保人签订本保险合同的中国太平洋财产保险股份有限公司。
    </p>
    <p>
      &#12288;&#12288;所附合同：指本保险合同所附的主险合同及其项下的与本保险合同相关的附加险合同。
    </p>
    <p>
      &#12288;&#12288;潜
      水：指以辅助呼吸器材在江、河、湖、海、水库、运河等水域进行的水下运动。
    </p>
    <p>
      &#12288;&#12288;攀 岩：指攀登悬崖、楼宇外墙、人造悬崖、冰崖、冰山等运动。
    </p>
    <p>
      &#12288;&#12288;武
      术：指两人或两人以上对抗性柔道、空手道、跆拳道、散打、拳击等各种拳术及各种使用器械的对抗性比赛。
    </p>
    <p>
      &#12288;&#12288;探
      险：指明知在某种特定的自然条件下有失去生命或使身体受到伤害的危险，而故意使自己置身其中的行为。如江河漂流、徒步穿越沙漠或人迹罕见的原始森林等活动。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentHighRisk extends Vue {
    static name = "accident-high-risk";
}
export default AccidentHighRisk;
</script>
<style lang="scss" src="./index.scss" scoped></style>
