import createPersistedState from "vuex-persistedstate";
import { isEmpty } from "@/utils/tools";

const files = require.context(".", true, /\.js$/);
const plugins = [];
const modules = {};
files.keys().forEach((key) => {
  const path = key.replace(/\.\/|\.js/g, "");
  if (path == "index") return;
  let [namespace, type] = path.split("/");
  if (!modules[namespace]) {
    modules[namespace] = {
      namespaced: true,
    };
  }
  const fileContent = files(key).default;
  if (type == "state" && !isEmpty(fileContent)) {
    const modulePath = Object.keys(fileContent).map((c) => `${namespace}.${c}`);
    plugins.push(createPersistedState({ key: namespace, paths: modulePath }));
  }
  modules[namespace][type] = fileContent;
});
export { plugins };
export default modules;
