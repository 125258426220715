import * as TYPES from "@/store/action-types";
import { getProduct } from "@/api/product.js";
import { isEmpty } from "@/utils/tools";
import { Toast } from "vant";

const productActions = {
  async [TYPES.GET_PRODUCT]({ commit }, payload) {
    if (!isEmpty(payload)) {
      let productInfo = await getProduct();
      commit(TYPES.SET_PRODUCT, productInfo);
    } else {
      Toast.fail("参数缺失!");
    }
  },
};

export default productActions;
