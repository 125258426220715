<template>
  <div class="document">
    <p class="font-weight-bold text-align">责任免责条款说明书</p>
    <p>尊敬的客户您好：</p>
    <p>
      感谢您选择中国太平洋财产保险股份有限公司的保险产品，当您投保本保险产品后，我司将根据您选择的险种，按照保险合同的约定，承担相应的保险赔偿责任。
    </p>
    <p>
      风险无处不在，风险种类多种多样，并非所有风险给被保险人带来的损失均可获得赔偿，我司一般通过保险条款中保险责任及责任免除条款等对保险责任范围进行明确。通过免除保险人责任的条款内容把保险公司不承担保险责任的情形和事由予以明确。
    </p>
    <p>
      基于上述情况，为维护您的合法权益，我司就该产品在保险合同中的免责条款及相应内容作出如下说明，请您仔细阅读。
    </p>
    <p class="font-weight-bold">个人人身意外伤害保险 A款（互联网）条款</p>
    <p>
      一、因下列原因之一，直接或间接造成被保险人身故、残疾的，保险人不负任何给付保险金责任：
    </p>
    <p>（一）投保人对被保险人的故意杀害或伤害；</p>
    <p>
      （二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；
    </p>
    <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>
    <p>（四）被保险人违法、犯罪或者抗拒依法采取的刑事强制措施；</p>
    <p>
      （五）被保险人因疾病导致的伤害，包括但不限于猝死（D计划除外）、食物中毒、高原反应、中暑、病毒和细菌感染（意外伤害导致的伤口感染不在此限）；
    </p>
    <p>
      （六）被保险人因妊娠、流产、分娩导致的伤害，但意外伤害所致的流产或分娩不在此限；
    </p>
    <p>
      （七）被保险人因药物过敏、整容手术、内外科手术或其他医疗行为导致的伤害；
    </p>
    <p>（八）被保险人未遵医嘱私自服用、涂用、注射药物；</p>
    <p>
      （九）被保险人因意外伤害、自然灾害事故以外的原因失踪而被法院宣告死亡的；
    </p>
    <p>
      （十）被保险人从事高风险运动、参加任何职业或半职业体育运动期间；
    </p>
    <p>
      （十一）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；
    </p>
    <p>（十二）恐怖袭击。</p>
    <p>
      二、下列任一情形下，保险人对被保险人身故、残疾不负任何给付保险金责任：
    </p>
    <p>（一）被保险人精神失常或精神错乱期间；</p>
    <p>（二）战争、军事行动、暴动或武装叛乱期间；</p>
    <p>（三）被保险人醉酒或受毒品、管制药物的影响期间；</p>
    <p>
      （四）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无有效行驶证的机动车期间。
    </p>
    <p></p>
    <p class="font-weight-bold">附加意外伤害医疗保险（互联网）条款</p>
    <p>
    一、主险合同无效或失效，保险人不负任何给付保险金责任。
    </p>
    <p>二、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。</p>
    <p>三、在下列情形下，保险人不负任何给付保险金责任：</p>
    <p>（一）被保险人非因主险合同所列意外伤害事故而发生的治疗；</p>
    <p>
      （二）被保险人矫形、整容、美容、器官移植的治疗；
    </p>
    <p>（三）被保险人体检、疗养、心理咨询或康复治疗；</p>
    <p>（四）被保险人在非保险人认可的医疗机构的治疗。</p>

    <p></p>
    <p class="font-weight-bold">附加意外伤害医疗保险（互联网）条款</p>
    <p>一、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。</p>
    <p二、下列情形或者下列费用，保险人不负任何给付保险金责任：</p>
    <p>（一）非因主险合同所列意外伤害事故而发生的治疗；</p>
    <p>（二）用于矫形、整容、美容、器官移植或修复、安装及购买残疾用具（如轮椅、假肢、助听器、假眼、配镜等）的费用；</p>
    <p>
      （三）被保险人体检、疗养、心理咨询或康复治疗的费用；
    </p>
    <p>
      （四）被保险人在非保险人认可的医疗机构的治疗费用
    </p>
    <p>（五）交通费、食宿费、生活补助费，及被保险人的误工补贴费。</p>
    <p></p>
    <p class="font-weight-bold">附加意外伤害救护车费用保险（互联网）条款</p>
    <p>一、主险合同无效或失效，保险人不负任何给付保险金责任。</p>
    <p>二、主险合同中列明的“责任免除”事项，也适用于本保险合同。</p>
    <p>三、下列情形下，保险人不负任何给付保险金责任：</p>
    <p>（一）被保险人非因主险合同保险责任范围内的意外伤害事故而发生的救护车费用</p>
    <p>
      （二）被保险人送往非二级以上或保险人认可的医疗机构而发生的救护车费用，但意外伤害急救至就近医院不在此限。
    </p>
   
    <p></p>
    <p class="font-weight-bold">
      附加君安行人身意外伤害保险（互联网）条款（附加险）条款免责事项：
    </p>
    <p>（一）投保人对被保险人的故意杀害或伤害；</p>
    <p>
    （二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；
    </p>
    <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>
    <p>
      （四）被保险人违法、犯罪或者抗拒依法采取的刑事强制措施；
    </p>
    <p>
     （五）被保险人因疾病导致的伤害，包括但不限于猝死、食物中毒、高原反应、中暑、病毒和细菌感染（意外伤害导致的伤口感染不在此限）；
    </p>
    <p>
      （六）被保险人因妊娠、流产、分娩导致的伤害，但意外伤害所致的流产或分娩不在此限；
    </p>
    <p>（七）被保险人因药物过敏、整容手术、内外科手术或其他医疗行为导致的伤害；</p>
    <p>（八）被保险人未遵医嘱私自服用、涂用、注射药物；</p>
    <p>（九）被保险人因意外伤害、自然灾害事故以外的原因失踪而被法院宣告死亡的；</p>
    <p>（十）被保险人从事高风险运动、参加任何职业或半职业体育运动，包括但不限于各种车辆表演、车辆竞赛或训练等；</p>
    <p>（十一）被保险人不遵守有关安全驾驶或乘坐的规定；</p>
    <p>（十二）被保险人驾驶超载机动车辆，因车辆超载引起的意外事故而遭受的伤害；</p>
    <p>（十三）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；</p>
    <p>（十四）恐怖袭击。</p>
    <p>二、下列任一情形下，保险人对被保险人身故、残疾不负任何给付保险金责任：</p>
    <p>（一）被保险人精神失常或精神错乱期间；</p>
    <p>（二）战争、军事行动、暴动或武装叛乱期间；</p>
    <p>（三）被保险人醉酒或受毒品、管制药物的影响期间；</p>
    <p>（四）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无有效行驶证的机动车期间。</p>


    <p></p>
    <p class="font-weight-bold">
     附加意外伤害骨折保险（互联网）条款：
    </p>
    <p>一、主险合同无效或失效，保险人不负任何给付保险金责任。</p>
    <p>
     二、主险合同中列明的“责任免除”事项，也适用于本保险合同。
    </p>
    <p>三、下列情形下，保险人不负任何给付保险金责任：</p>
    <p>
      （一）被保险人投保前已有的既往症； 
    </p>
    <p>（二）被保险人先天性关节脱位、病理性脱位、习惯性脱位、陈旧性脱位或复发性脱位； </p>
    <p>（三）被保险人病理性骨折（指有病骨骼遭受轻微外力即发生的骨折）或被诊断为骨质疏松并因该病症而导致的骨折或关节脱位。</p>
    <p>
      
    </p>
    <p  class="font-weight-bold">附加扩展高风险活动保险（互联网）条款</p>
    <p>
     一、所附合同中列明的“责任免除”事项（除本保险合同第二条所指的高风险活动外），也适用于本保险合同。
    </p>
    <p>
   二、下列情形下，保险人不负任何给付保险金责任：
    </p>
    <p>（一）被保险人参与可获得固定报酬的体育运动，被保险人以职业运动员身份参加的任何体育运动，但保险单特别注明的除外；</p>
    <p>
      （二）被保险人在国家旅游管理部门许可的旅游景点、合法娱乐场所以外的其他地点从事任何高风险活动；；
    </p>
    <p>
      （三）在参与本保险合同所指的高风险活动之前，被保险人知道或应当知道其身体条件不适合参与该高风险活动。
    </p>
    <p></p>
    <p  class="font-weight-bold">
      附加个人特定传染病住院津贴保险（互联网）条款
    </p>
    <p>
      一、主险合同无效或失效，保险人不承担保险责任。
    </p>
    <p>二、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。</p>
    <p>
     三、在下列情形下，保险人不承担保险责任：
    </p>
    <p>（一）投保人故意造成被保险人身故或疾病；</p>
    <p>（二）被保险人故意自伤、自杀、故意犯罪或抗拒依法采取的刑事强制措施；</p>
    <p>（三）被保险人投保前已有的身体损害、未告知既往症的合并症及并发症，以及保险单特别约定除外的疾病；</p>
    <p>
      （四）被保险人违法、犯罪行为；
    </p>
    <p>（五）被保险人未遵医嘱私自服用、涂用、注射药物</p>
    <p>（六）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；</p>
    <p>（七）被保险人先天性畸形、变形和染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD－10）》为准）；</p>
    <p>（八）被保险人患性病、精神和行为障碍（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD－10）》为准）；</p>
  <p>（九）被保险人患职业病；</p>
<p>（十）被保险人未经释义医院或疾病预防控制机构确诊感染法定传染病的，非本合同约定类型的特定传染病；</p>
  <p>（十一）本保险合同约定保险责任范围以外的其他情形。</p>
    <p></p>
    <p class="font-weight-bold">附加猝死保险（互联网）条款</p>
   
    <p>
      一、主险合同中列明的“责任免除”事项（但涉及本保险合同所指的猝死责任除外），也适用于本附加险合同；
    </p>
    <p>
      二、被保险人在本附加险合同生效日之前罹患的已知或应该知道的有关疾病或症状，或已知慢性病的急性发作、复发、合并症及并发症；
    </p>
    <p>三、被保险人罹患特定传染病、职业病、地方病、心理疾病；</p>
    <p>
    四、被保险人患有遗传性疾病、先天性畸形、变形或者染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD-10）》为准）。
    </p>
    <p></p>
    <p class="font-weight-bold">附加公共场所个人责任保险条款</p>
    <p>
      除主险合同规定的责任免除事项保险人不予赔偿外，对于下列损失、费用或责任，保险人也不予赔偿：
    </p>
    <p>（一）任何性质的间接损失；</p>
    <p>
      （二）被保险人因合同或协议所承担的赔偿责任。但即使无该项合同或协议存在时依法仍应由被保险人负赔偿责任的除外；
    </p>
    <p>（三）被保险人对租赁、代人保管、管理或控制的财物负有的损失赔偿责任；</p>
    <p>（四）被保险人因从事商业或与其职业相关事务导致的赔偿责任；</p>
    <p>
      （五）被保险人因所有或使用或管理飞机、船舶及依法应领有牌照的车辆导致的赔偿责任；
    </p>
    <p>
      （六）被保险人违反公共场所的管理规定进入或滞留公共场所期间产生的赔偿责任；
    </p>
    <p>
      （七）在同一份保险合同中的各个被保险人之间，及在一份保险合同中的投保人和被保险人之间的赔偿责任；
    </p>
    <p>（八）在共同预定了旅行并一起出行的人之间的赔偿责任；</p>
    <p>（九）由于使用任何武器导致的赔偿责任；</p>
    <p>（十）被保险人的故意行为；</p>
    <p>
      （十一）由于对土地，空气或者水（包括水域）的环境影响导致的赔偿责任及由此导致的其它所有赔偿责任；
    </p>
    <p>（十二）因各种传染疾病所导致的赔偿责任；</p>
    <p>（十三）被保险人从事竞技、比赛、特技表演所导致的赔偿责任；</p>
    <p>（十四）精神损害赔偿、罚款、罚金或惩罚性赔偿；</p>
    <p>（十五）保险单上载明的应由被保险人自行承担的免赔额（率）。</p>
    <p></p>
    <!-- <p class="font-weight-bold">附加意外伤害住院津贴保险条款免责事项：</p>
    <p>本保险合同的责任免除如下：</p>
    <p>一、主险合同无效或失效，保险人不负任何给付保险金责任。</p>
    <p>
      二、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。
    </p>
    <p>三、在下列情形下，保险人不负任何给付保险金责任：</p>
    <p>（一）被保险人非因主险合同所列意外伤害事故而发生的治疗；</p>
    <p>（二）被保险人矫形、整容、美容、器官移植的治疗；</p>
    <p>（三）被保险人体检、疗养、心理咨询或康复治疗；</p>
    <p>（四）被保险人在非保险人认可的医疗机构的治疗。</p>
    <p></p>
    <div class="font-weight-bold">
      <p>产品特别约定：</p>
      <p>
        1、本产品意外伤害医疗费用保障扣除100元人民绝对免赔后，在保险责任范围内（按条款规定属于医保范围内可报销合理必要用药）按照100%的比例赔付。
      </p>
      <p>
        2、本保险适用范围为中国
        境内（不含港澳台）,各类交通工具意外仅承保以乘客身份乘坐客运机动车、客运轨道交通车辆以及乘坐非营运的机动车的自然人，否则不予理赔。
      </p>
      <p>
        3、本保险仅限16-65周岁，职业类别为1-3类，且身体健康，能正常出行的人士投保。
      </p>
      <p>
        4、意外骨折保险金按照本保险合同所附《意外伤害骨折和关节脱位类型与保险金给付比例表》按比例赔付。
      </p>
      <p>
        5、任何年龄在18周岁以下的被保险人，如果其以死亡为给付责任的保险金额（包括在所有商业保险公司所购买的保险）超出中国保监会所规定的限额（对于被保险人不满10周岁的，死亡给付保险金额总和不得超过人民币20万元；被保险人已满10周岁但未满18周岁的，死亡给付保险金额总和不得超过人民币50万元，航空意外死亡保险金不受此限），保险公司就超过限制部分不承担保险金给付责任。
      </p>
      <p>
        6、本产品同一被保险人只可购买一份。同一被保险人购买多份的，保险人对该被保险人承担的保险责任以一份为限。
      </p>
      <p>
        7、本产品中针对意外伤害住院津贴保险保障，其中计划A，每天赔偿限额50元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过30天；计划B，每天赔偿限额50元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；计划C，每天赔偿限额80元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；计划D，每天赔偿限额100元，免赔4天，单次赔偿天数不超过15天，累计赔偿天数不超过60天；
      </p>
      <p>
        8、医院限定：本保单约定的医疗机构是指在中华人民共和国境内二级以上（含二级）公立医院，但不包括：1）北京市的平谷区、密云区、怀柔区所有医院；2）江苏省的徐州市、南通市所有医院；3）天津市的滨海、静海地区所有医院；4）辽宁省的铁岭市所有医院、辽宁中医药大学附属第二医院、中国人民解放军第二0五医院；5）吉林省的长春中医药大学附属医院、长春市中医院、四平市第一人民医院、四平市中医医院、四平市梨树县第一人民医院；6）黑龙江省中医医院；7）河北省的青县、青龙县、东光县、廊坊市固安县所有医院、邯郸市馆陶县人民医院；8）河南省的信阳市所有医院、洛阳市中心医院、洛阳市第十一人民医院、洛阳市第二中医院、洛阳东方医院（河南科技大学第三附属医院）、洛阳市新安县人民医院、焦作市博爱县中医院、郑州市中牟县中医院、新乡市中医院、新乡市原阳县中医院、新乡市原阳县人民医院；9）山东省的禹城市、栖霞市、潍坊市高密县的所有医院、莱州市人民医院、莱州市中医院、莱州市郭家店中心卫医院、滨州市中心医院、青岛市即墨区中医医院、莱阳市中医医院、济宁市金乡县人民医院；10）四川省的宜宾市所有医院、邛崃市医疗中心医院、内江市中医医院、内江市第二人民医院、雅安市雨城区人民医院、雅安市第二人民医院；11）贵州省的遵义市绥阳县中医院；12）广东省的湛江市第二中医医院、茂名市电白区人民医院；13）湖南省的衡阳市中医正骨医院；14）河北省承德市兴隆县中医院；15）所有私营社保定点医院。
      </p>
    </div> -->
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class AccidentDisclaimer extends Vue {
  static name = "accident-disclaimer";
}
export default AccidentDisclaimer;
</script>
<style lang="scss" src="./index.scss" scoped></style>
