import Vue from "vue";
import Vuex from "vuex";
import modules, { plugins } from "./modules/index.js";
import global from "./global";

Vue.use(Vuex);
export default new Vuex.Store({
  ...global,
  modules,
  plugins,
});
