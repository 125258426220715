export function isEmpty(target) {
  if (!target) return true;
  if (JSON.stringify(target) === "{}") return true;
  if (JSON.stringify(target) === "[]") return true;
  return false;
}

export function takeEnv() {
  const env = window.location.origin.indexOf("bigtree") > -1 ? `prd` : `dev`;
  const isPrd = env === "prd";
  const mainUrls = {
    dev: `https://mobile-test.chinapay.com/insureAPP/index`,
    prd: `https://bxpt.95516.com/insureAPP/index`,
  };

  // url:'http://10.177.86.235/glutton/api/account/sign-in',UAT环境
  // url:'https://www.bigtreebroker.com/glutton/api/account/sign-in',//正式环境
  const gluUrl = {
    // https://glutton-demo.ebaocloud.com.cn/glutton/api/account/sign-in
    dev: `https://www.bigtreebroker.com/glutton/api/account/sign-in`,
    prd: `https://www.bigtreebroker.com/glutton/api/account/sign-in`,
  };
  return {
    gluttonUrl: gluUrl[env],
    mainUrl: mainUrls[env],
    env,
    isPrd,
  };
}
