<template>
  <div>
    <div
      v-if="
        this.productInfo.productCode == 'H011' ||
        this.productInfo.productCode == 'H015'
      "
      class="home-page"
    >
      <!-- 头部图片 -->
      <img :src="this.productInfo.titlePicture[0]" width="100%" height="auto" />
      <!-- 输入框 -->
      <div class="inputStyle">
        <div>
          <p>
            <span>姓名</span>
            <input type="text" v-model="form.name" @change="nameChange" />
          </p>
          <font class="border"></font>
          <font class="mass one" v-show="inputOne == 2">请输入正确姓名</font>
        </div>
        <div>
          <p>
            <span>身份证号码</span>
            <input type="text" v-model="form.idCard" @change="idCardChange" />
          </p>
          <font class="border"></font>
          <font class="mass two" v-show="inputTwo == 2"
            >请输入正确身份证号码</font
          >
        </div>
        <div>
          <p>
            <span>手机号码</span>
            <input type="text" v-model="form.phone" @change="phoneChange" />
          </p>
          <font class="border"></font>
          <font class="mass three" v-show="inputThree == 2"
            >请输入正确手机号码</font
          >
        </div>
        <div>
          <p>
            <span>车牌号码</span>
            <input type="text" v-model="form.carCode" @change="carCodeChange" />
          </p>
          <font class="border"></font>
          <font class="mass four" v-show="inputFour == 2"
            >请输入正确车牌号码</font
          >
        </div>
      </div>
      <div class="shengming">
        <van-checkbox
          v-model="checked"
          checked-color="#ee0a24"
          shape="square"
        />
        <div class="worlds" style="padding: 0 0.6rem">
          投保前请您仔细阅读
          <span style="color: #2e72d3" @click="handleNotice"
            >《投保须知及声明》</span
          >
          <span style="color: #2e72d3" @click="handleClause">《保险条款》</span>
          <span style="color: #2e72d3" @click="handleDisclaimer"
            >《免责条款》</span
          >
          <span style="color: #2e72d3" @click="handleDisclosure"
            >《信息披露》</span
          >
          <span style="color: #2e72d3" @click="handlePrivacyPolicy"
            >《隐私政策》</span
          >
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <button class="btnSure" @click="insureAgree">领取</button>
      </div>
      <div style="width: 100%">
        <ul
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.4rem;
            height: 1rem;
            font-weight: bold;
            font-size: 0.4rem;
          "
        >
          <li>保障计划</li>
          <li>
            <router-link
              style="display: block; float: right"
              :to="'/particulars'"
              >查看保障详情</router-link
            >
          </li>
        </ul>
        <ul
          v-for="(value, index) in this.productInfo.productPlans[0].planDetails"
          :key="index"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.5rem;
            font-size: 0.37rem;
          "
        >
          <li style="margin-bottom: 0.3rem">
            {{ value.clauseDetail.clauseName }}
          </li>
          <li style="margin-bottom: 0.3rem">{{ value.clauseAmount }}</li>
        </ul>
      </div>
      <p style="text-align: center; color: #666">
        本产品由北京大树保险经纪有限责任公司提供保险经纪服务
      </p>
    </div>
    <div v-else class="home-page">
      <template v-if="productInfo">
        <img
          v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
          :src="coverUrl[0]"
          alt
          class="image"
          @click="imageBtn"
        />
        <img v-else :src="coverUrl[0]" alt class="image" />
        <img
          v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
          :src="coverUrl[1]"
          alt
          class="image"
          style="margin-top: 10px"
        />
        <van-overlay :lock-scroll="true" :show="modulShow" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block">
              <div>
                <p><span @click="modulShow = false">X</span></p>
              </div>
              <div>
                <div>
                  <p>手机号码</p>
                  <input
                    type="text"
                    v-model="telText"
                    placeholder="请输入手机号码进行领取"
                    @change="telChange"
                  />
                </div>
                <p class="hrLine"></p>
                <p class="mass" v-show="telShow">请输入正确手机号码</p>
              </div>
              <p style="color: #666">
                注：活动期间一个手机号码只能领取一次，使用规则以服务商为准。
              </p>
              <div>
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="modulSuer"
                  style="margin-left: 15px; padding: 0 35px; height: 1rem"
                  >免费领取</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <van-overlay :lock-scroll="true" :show="modulShow1" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block1">
              <div>
                <h3 style="color: red">领取成功，权益兑换码如下：</h3>
                <div>
                  <p
                    style="
                      font-size: 18px;
                      color: red;
                      font-weight: bold;
                      margin: 20px 0;
                    "
                  >
                    {{ verificationCode }}
                  </p>
                </div>
                <p>请截图保存权益兑换码</p>
              </div>
              <div>
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="modulShow1 = false"
                  >取消</van-button
                >
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="hrefBtn"
                  style="margin-left: 15px"
                  >去兑换</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <van-overlay :lock-scroll="true" :show="modulShow2" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block2">
              <div class="oneBox">
                <p>即将跳转到第三方服务商链接</p>
              </div>
              <div class="twoBox">
                <van-button
                  type="danger"
                  size="small"
                  round
                  @click="modulShow2 = false"
                  >取消</van-button
                >
                <van-button
                  type="danger"
                  size="small"
                  round
                  @click="modulSuer2"
                  style="margin-left: 15px"
                  >继续</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <!-- 付款权益码查询 -->
        <van-overlay :lock-scroll="true" :show="modulShow3" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block3">
              <div>
                <p><span @click="modulShow3 = false">X</span></p>
              </div>
              <div>
                <div>
                  <p>手机号码</p>
                  <input
                    type="text"
                    v-model="telText1"
                    placeholder="请输入手机号码进行查看"
                    @change="telChange1"
                  />
                </div>
                <p class="hrLine"></p>
                <p class="mass" v-show="telShow1">请输入正确手机号码</p>
              </div>
              <div>
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="modulSuer3"
                  style="margin-left: 15px; padding: 0 35px; height: 1rem"
                  >立即查看</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <van-overlay :lock-scroll="true" :show="modulShow4" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block1">
              <div>
                <h3 style="color: red">查询成功，权益兑换码如下：</h3>
                <div>
                  <p
                    style="
                      font-size: 18px;
                      color: red;
                      font-weight: bold;
                      margin: 20px 0;
                    "
                  >
                    {{ verificationCode }}
                  </p>
                </div>
                <p>请截图保存权益兑换码</p>
              </div>
              <div>
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="modulShow4 = false"
                  >取消</van-button
                >
                <van-button
                  type="danger"
                  round
                  size="small"
                  @click="hrefBtn4"
                  style="margin-left: 15px"
                  >去兑换</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <van-overlay :lock-scroll="true" :show="modulShow5" z-index="999">
          <div class="wrapper" @click.stop>
            <div class="block2">
              <div class="oneBox">
                <p>即将跳转到第三方服务商链接</p>
              </div>
              <div class="twoBox">
                <van-button
                  type="danger"
                  size="small"
                  round
                  @click="modulShow5 = false"
                  >取消</van-button
                >
                <van-button
                  type="danger"
                  size="small"
                  round
                  @click="modulSuer5"
                  style="margin-left: 15px"
                  >继续</van-button
                >
              </div>
            </div>
          </div>
        </van-overlay>
        <div
          class="titleone tabsBox"
          style="margin-top: -25px"
          v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
        >
          <van-tabs
            v-if="productInfo.isShowPlans"
            title-style="height:100px"
            class="total"
            v-model="current"
            @click="falseIs"
          >
            <van-tab v-for="(item, index) in productPlans" :key="index">
              <template #title>
                <div>
                  <img :src="item.image" alt="" width="80px" height="auto" />
                </div>
              </template>
              <div class="safeguard">
                <div class="particulars">
                  <span style="font-size: 15px; font-weight: bold"
                    >保障计划</span
                  >
                  <router-link
                    style="display: block; float: right"
                    v-if="
                      productInfo.insuranceCompanyCode == 'sunshine001' ||
                      productInfo.insuranceCompanyCode == 'sunshine002' ||
                      productInfo.insuranceCompanyCode == 'pingan001' ||
                      productInfo.insuranceCompanyCode == 'pingan003'
                    "
                    :to="'/particulars'"
                    >查看保障详情</router-link
                  >
                </div>
                <div
                  v-for="(clause, index) in item.planDetails.slice(0, -1)"
                  :key="index"
                >
                  <van-cell
                    :border="false"
                    :title="clause.clauseDisplayName"
                    :value="clause.clauseAmount"
                  />
                </div>
                <div>
                  <van-cell
                    v-for="(clause, index) in item.planDetails.slice(-1)"
                    :key="index + 1"
                    style="font-weight: bold"
                    :border="false"
                    :title="clause.clauseDisplayName"
                    :value="clause.clauseAmount"
                  />
                </div>
                <p
                  v-if="sjfalse"
                  style="text-align: center; color: #ff8c00"
                  @click="falseclick"
                >
                  {{ titlevalue }}
                </p>
              </div>
            </van-tab>
          </van-tabs>
          <div v-if="!productInfo.isShowPlans">
            <div
              class="tablstwo"
              v-for="(item, index) in productPlans"
              :key="index"
            >
              <div class="particulars">
                <span style="font-size: 15px; font-weight: bold">保障计划</span>
                <router-link
                  style="display: block; float: right"
                  v-if="
                    productInfo.insuranceCompanyCode == 'sunshine001' ||
                    productInfo.insuranceCompanyCode == 'pingan001' ||
                    productInfo.insuranceCompanyCode == 'sunshine002' ||
                    productInfo.insuranceCompanyCode == 'pingan003'
                  "
                  :to="'/particulars'"
                  >查看保障详情</router-link
                >
              </div>
              <div v-for="(clause, index) in item.planDetails" :key="index + 1">
                <!--  v-if="clause.bold == true ? boldStyle : ''" -->
                <span
                  :style="{ 'font-weight': clause.bold ? 'bold' : 'none' }"
                  >{{ clause.clauseDisplayName }}</span
                >
                <span
                  :style="{ 'font-weight': clause.bold ? 'bold' : 'none' }"
                  class="spantwo"
                  >{{ clause.clauseAmount }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="tabsBox" v-else>
          <van-tabs
            v-if="productInfo.isShowPlans"
            title-style="height:100px"
            class="total"
            v-model="current"
            @click="falseIs"
          >
            <van-tab v-for="(item, index) in productPlans" :key="index">
              <template #title>
                <div>
                  <div style="font-weight: bold; white-space: nowrap">
                    {{ item.planAmount }}元起
                  </div>
                  <div>{{ item.productName }}</div>
                </div>
              </template>
              <div class="safeguard">
                <div class="particulars">
                  <span style="font-size: 15px; font-weight: bold"
                    >保障计划</span
                  >
                  <router-link
                    style="display: block; float: right"
                    v-if="
                      productInfo.insuranceCompanyCode == 'sunshine001' ||
                      productInfo.insuranceCompanyCode == 'sunshine002' ||
                      productInfo.insuranceCompanyCode == 'pingan001' ||
                      productInfo.insuranceCompanyCode == 'pingan003'
                    "
                    :to="'/particulars'"
                    >查看保障详情</router-link
                  >
                </div>
                <div
                  v-for="(clause, index) in item.planDetails.slice(0, 4)"
                  :key="index"
                >
                  <van-cell
                    :border="false"
                    :title="clause.clauseDisplayName"
                    :value="clause.clauseAmount"
                  />
                </div>
                <div v-if="titlefalse">
                  <van-cell
                    v-for="(clause, index) in item.planDetails.slice(4, 1000)"
                    :key="index + 1"
                    :border="false"
                    :title="clause.clauseDisplayName"
                    :value="clause.clauseAmount"
                  />
                </div>
                <p
                  v-if="sjfalse"
                  style="text-align: center; color: #ff8c00"
                  @click="falseclick"
                >
                  {{ titlevalue }}
                </p>
              </div>
            </van-tab>
          </van-tabs>
          <div v-if="!productInfo.isShowPlans">
            <div
              class="tablstwo"
              v-for="(item, index) in productPlans"
              :key="index"
            >
              <div class="particulars">
                <span style="font-size: 15px; font-weight: bold">保障计划</span>
                <router-link
                  style="display: block; float: right"
                  v-if="
                    productInfo.insuranceCompanyCode == 'sunshine001' ||
                    productInfo.insuranceCompanyCode == 'pingan001' ||
                    productInfo.insuranceCompanyCode == 'sunshine002' ||
                    productInfo.insuranceCompanyCode == 'pingan003' ||
                    productInfo.insuranceCompanyCode == 'pingan005'
                  "
                  :to="'/particulars'"
                  >查看保障详情</router-link
                >
              </div>
              <div v-for="(clause, index) in item.planDetails" :key="index + 1">
                <!--  v-if="clause.bold == true ? boldStyle : ''" -->
                <span
                  :style="{ 'font-weight': clause.bold ? 'bold' : 'none' }"
                  >{{ clause.clauseDisplayName }}</span
                >
                <span
                  :style="{ 'font-weight': clause.bold ? 'bold' : 'none' }"
                  class="spantwo"
                  >{{ clause.clauseAmount }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="insure">
          <van-tabs v-model="active" scrollspy sticky>
            <van-tab
              v-for="(item, indexe) in productIntroduces"
              :key="indexe"
              :title="item.name"
            >
              <div class="insure-content">
                <!-- <div class="title">{{ item.name }}</div> tabs下的名字-->
                <!-- 产品特色 -->
                <template v-if="item.contentType == 'pic'">
                  <div class="pics" v-for="(c, i) in item.content" :key="i">
                    <img
                      :src="c"
                      alt
                      @click="item.name == '权益使用' ? imgCheckPhoneBtn() : ''"
                    />
                  </div>
                </template>
                <!-- list渲染 -->
                <template v-if="item.contentType == 'list'">
                  <div class="list" v-for="(c, i) in item.content" :key="i">
                    <p class="problemstyle">
                      <span class="qstyle">Q</span>
                      {{ c.question }}
                    </p>
                    <span class="centerstyle">{{ c.answer }}</span>
                    <!-- 常见问题 -->
                  </div>
                </template>
                <template v-if="item.contentType == 'message'">
                  <div class="list" v-for="(c, i) in item.content" :key="i">
                    <p class="problemstyle">
                      <!-- <span class="qstyle">Q</span> -->
                      {{ c.title }}
                      <van-button
                        @click="showPopup(i)"
                        class="particulars"
                        size="mini"
                        round
                        plain
                        type="info"
                        >&nbsp; 详 情 &nbsp;</van-button
                      >
                    </p>
                    <!-- 理赔指引 -->
                  </div>
                </template>
              </div>
              <div class="van-action-sheet__gap"></div>
            </van-tab>
            <!-- 详情弹出层 -->
            <van-popup
              closeable
              class="popup"
              :round="true"
              v-model="showpopup"
            >
              <p>{{ popuptitle }}</p>
              {{ popupcenter }}
            </van-popup>
            <div class="insure-content">
              <div class="title">投保提示</div>
              <div class="notify">
                <div
                  class="worlds"
                  v-if="
                    this.productInfo.productCode == 'H005' ||
                    this.productInfo.productCode == 'H007' ||
                    this.productInfo.productCode == 'H010'
                  "
                >
                  投保前请您仔细阅读
                  <span @click="handleNotice">《投保须知及声明》</span>
                  <span @click="handleClause">《责任描述》</span>
                  <span @click="handleDisclaimer">《常见问题》</span>
                  <span @click="handleDisclosure">《责任免除》</span>
                  <span @click="handlePrivacyPolicy">《理赔说明》</span>
                  <span @click="handlePrivacy">《隐私政策》</span>
                </div>
                <div
                  class="worlds"
                  v-else-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
                >
                  投保前请您仔细阅读
                  <span @click="handleNotice">《投保须知及声明》</span>
                  <span @click="handleClause">《保险条款》</span>
                  <span @click="handleDisclaimer">《权益服务规则》</span>
                  <span @click="handleDisclosure">《信息披露》</span>
                  <span @click="handlePrivacyPolicy">《隐私政策》</span>
                </div>
                <div class="worlds" v-else>
                  投保前请您仔细阅读
                  <span @click="handleNotice">《投保须知及声明》</span>
                  <span @click="handleClause">《保险条款》</span>
                  <span @click="handleDisclaimer">《免责条款》</span>
                  <span @click="handleDisclosure">《信息披露》</span>
                  <span @click="handlePrivacyPolicy">《隐私政策》</span>
                </div>
              </div>
            </div>
          </van-tabs>
          <div
            class="van-action-sheet__gap"
            style="
              height: 25px;
              line-height: 25px;
              text-align: center;
              color: gray;
              padding: 10px;
            "
          >
            本产品由北京大树保险经纪有限责任公司提供保险经纪服务
          </div>
        </div>
        <van-popup
          v-if="
            this.productInfo.productCode != 'H005' ||
            this.productInfo.productCode != 'H007' ||
            this.productInfo.productCode != 'H010' ||
            this.productInfo.productCode != 'H012'
          "
          position="bottom"
          :style="{ height: '50%' }"
          v-model="showbotton"
        >
          <van-tabs v-model="activebottom" swipeable>
            <van-tab title="投保须知">
              <accident-notice
                v-if="
                  this.productInfo.productCode == 'H001' ||
                  this.productInfo.productCode == 'H008'
                "
              ></accident-notice>
              <sinopec-notice
                v-if="this.productInfo.productCode == 'H012'"
              ></sinopec-notice>
              <travel-notice
                v-if="
                  this.productInfo.productCode == 'H004' ||
                  this.productInfo.productCode == 'H006'
                "
              ></travel-notice>
              <family-property-notice
                v-if="this.productInfo.productCode == 'H002'"
              ></family-property-notice>
              <personal-property-notice
                v-if="
                  this.productInfo.productCode == 'H003' ||
                  this.productInfo.productCode == 'H009'
                "
              ></personal-property-notice>
              <carInsurance-disclaimer
                v-if="this.productInfo.productCode == 'H014'"
              ></carInsurance-disclaimer>
            </van-tab>
            <van-tab title="保险条款">
              <insuranceClausetwo
                v-if="
                  this.productInfo.productCode == 'H001' ||
                  this.productInfo.productCode == 'H008'
                "
              ></insuranceClausetwo>
              <family-property-clause
                v-if="this.productInfo.productCode == 'H002'"
              ></family-property-clause>
              <personal-property-clause
                v-if="
                  this.productInfo.productCode == 'H003' ||
                  this.productInfo.productCode == 'H009'
                "
              ></personal-property-clause>
              <van-tabs
                v-if="
                  this.productInfo.productCode == 'H004' ||
                  this.productInfo.productCode == 'H006'
                "
                swipeable
              >
                <van-tab
                  title="平安产险附加法定传染病集中治疗观察津贴保险（互联网版）条款"
                >
                  <travel-infectious></travel-infectious>
                </van-tab>
                <van-tab title="平安产险特定危重型传染病保险（互联网版）条款">
                  <travel-critically></travel-critically>
                </van-tab>
              </van-tabs>
              <van-tabs v-if="this.productInfo.productCode == 'H012'" swipeable>
                <van-tab title="平安家庭财产保险（火险）">
                  <sinopec-infectious></sinopec-infectious>
                </van-tab>
                <van-tab title="平安附加居家责任保险">
                  <sinopec-critically></sinopec-critically>
                </van-tab>
                <van-tab title="平安法定产染病身故保险">
                  <sinopec-disease></sinopec-disease>
                </van-tab>
              </van-tabs>
              <van-tabs v-if="this.productInfo.productCode == 'H014'" swipeable>
                <van-tab title="平安个人出行不便损失补偿保险条款">
                  <carInsurance-infectious></carInsurance-infectious>
                </van-tab>
                <van-tab title="平安驾驶人员意外伤害保险条款">
                  <carInsurance-critically></carInsurance-critically>
                </van-tab>
                <van-tab title="平安法定传染病身故保险条款">
                  <carInsurance-disease></carInsurance-disease>
                </van-tab>
              </van-tabs>
            </van-tab>
            <van-tab title="免责条款">
              <sinopec-disclaimer
                v-if="this.productInfo.productCode == 'H012'"
              ></sinopec-disclaimer>
              <accident-disclaimer
                v-if="
                  this.productInfo.productCode == 'H001' ||
                  this.productInfo.productCode == 'H008'
                "
              ></accident-disclaimer>
              <family-property-disclaimer
                v-if="this.productInfo.productCode == 'H002'"
              ></family-property-disclaimer>
              <personal-property-disclaimer
                v-if="
                  this.productInfo.productCode == 'H003' ||
                  this.productInfo.productCode == 'H009'
                "
              ></personal-property-disclaimer>
              <travel-disclaimer
                v-if="
                  this.productInfo.productCode == 'H004' ||
                  this.productInfo.productCode == 'H006'
                "
              ></travel-disclaimer>
              <carInsurance-disclaimer
                v-if="this.productInfo.productCode == 'H014'"
              ></carInsurance-disclaimer>
            </van-tab>
            <van-tab title="信息披露">
              <sinopec-disclosure
                v-if="this.productInfo.productCode == 'H012'"
              ></sinopec-disclosure>
              <disclosuresafe
                v-if="
                  this.productInfo.productCode == 'H001' ||
                  this.productInfo.productCode == 'H003' ||
                  this.productInfo.productCode == 'H008' ||
                  this.productInfo.productCode == 'H009'
                "
              ></disclosuresafe>
              <message v-if="this.productInfo.productCode == 'H002'"></message>
              <travel-disclosure
                v-if="
                  this.productInfo.productCode == 'H004' ||
                  this.productInfo.productCode == 'H006'
                "
              ></travel-disclosure>
              <carInsurance-disclosure
                v-if="this.productInfo.productCode == 'H014'"
              ></carInsurance-disclosure>
            </van-tab>
            <van-tab title="隐私政策">
              <travel-policy></travel-policy>
            </van-tab>
            <van-goods-action class="box">
              <van-goods-action-button
                @click="action"
                type="primary"
                :text="'我已逐页阅读并同意' + lists + '/5'"
              />
            </van-goods-action>
          </van-tabs>
        </van-popup>
        <van-popup
          v-if="
            this.productInfo.productCode == 'H005' ||
            this.productInfo.productCode == 'H007' ||
            this.productInfo.productCode == 'H010'
          "
          position="bottom"
          :style="{ height: '50%' }"
          v-model="showbotton"
        >
          <van-tabs v-model="activebottoms" swipeable @change="callback">
            <van-tab title="投保须知">
              <vaccine-information
                v-if="this.productInfo.productCode == 'H005' || 'H010'"
              ></vaccine-information>
              <bankcard-information
                v-if="this.productInfo.productCode == 'H007'"
              ></bankcard-information>
            </van-tab>
            <van-tab title="责任描述">
              <vaccine-description
                v-if="this.productInfo.productCode == 'H005' || 'H010'"
              ></vaccine-description>
              <bankcard-description
                v-if="this.productInfo.productCode == 'H007'"
              ></bankcard-description>
            </van-tab>
            <van-tab title="常见问题">
              <vaccine-disclaimer
                v-if="this.productInfo.productCode == 'H005' || 'H010'"
              ></vaccine-disclaimer>
              <bankcard-disclaimer
                v-if="this.productInfo.productCode == 'H007'"
              ></bankcard-disclaimer>
            </van-tab>
            <van-tab title="责任免除">
              <vaccine-disclosure
                v-if="this.productInfo.productCode == 'H005' || 'H010'"
              ></vaccine-disclosure>
              <bankcard-disclosure
                v-if="this.productInfo.productCode == 'H007'"
              ></bankcard-disclosure>
            </van-tab>
            <van-tab title="理赔说明">
              <vaccine-policy
                v-if="this.productInfo.productCode == 'H005' || 'H010'"
              ></vaccine-policy>
              <bankcard-policy
                v-if="this.productInfo.productCode == 'H007'"
              ></bankcard-policy>
            </van-tab>
            <van-tab title="隐私政策">
              <travel-policy></travel-policy>
            </van-tab>
            <van-goods-action class="box">
              <van-goods-action-button
                @click="actions"
                type="primary"
                :text="'我已逐页阅读并同意' + lists + '/6'"
              />
            </van-goods-action>
          </van-tabs>
        </van-popup>
        <van-popup
          v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
          position="bottom"
          :style="{ height: '50%' }"
          v-model="showbotton"
        >
          <van-tabs v-model="activebottom" swipeable>
            <van-tab title="投保须知">
              <traffic-notice></traffic-notice>
            </van-tab>
            <van-tab title="保险条款"
              ><traffic-infectious></traffic-infectious>
            </van-tab>
            <van-tab title="信息披露">
              <traffic-disclosure></traffic-disclosure>traffic-disclosure>
            </van-tab>
            <van-tab title="隐私政策">
              <traffic-policy></traffic-policy>
            </van-tab>
            <van-goods-action class="box">
              <van-goods-action-button
                @click="actionse"
                type="primary"
                :text="'我已逐页阅读并同意' + lists + '/4'"
              />
            </van-goods-action>
          </van-tabs>
        </van-popup>
        <submit-button
          @action="submit"
          :price="currentPlan.planAmount"
        ></submit-button>
        <van-action-sheet
          title="消费者权益保障服务"
          v-model="show"
          cancel-text="知道了"
          @closed="close"
        >
          <div class="van-action-sheet__gap"></div>
          <div class="pop-content">
            <div class="pop-top">
              <span>✔15天无理由退</span>
              <span>✔理赔无忧</span>
              <span>✔客服协助</span>
              <span>✔风险提示</span>
            </div>
            <p class="content-center">
              你已进入投保流程, 本产品由{{ productInfo.insuranceCompany }}承保,
              北京大树保险经纪有限责任公司代理销售
            </p>
            <p class="content-tit">
              请仔细阅读
              <span @click="handleClause">保险条款、</span>
              <span @click="handleNotice">投保须知、</span>
              <span @click="handleClient">客户告知书</span>
              等内容为了保障你的权益，我们将会安全记录你的操作
            </p>
          </div>
        </van-action-sheet>
      </template>
      <template v-else>
        <loading-overlay></loading-overlay>
        <van-empty description="产品不存在" v-if="!loading">
          <van-button
            type="primary"
            @click="goBack"
            square
            class="bottom-button"
            >请返回首页</van-button
          >
        </van-empty>
      </template>
    </div>
  </div>
</template>

<script>
import { Vue, Component, VModel, Watch, Prop } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { GET_PRODUCT } from "@/store/action-types";
import options from "./options";
import { getProduct } from "@/api/order";
import { Toast } from "vant";
import {
  receivePingan,
  receivesPingan,
  telNumber,
  payTelNumber,
} from "@/api/product.js";

const ProductModule = namespace("product");
const ProductAction = ProductModule.Action;
const ProductMutation = ProductModule.Mutation;
const ProductState = ProductModule.State;
const ProductGetters = ProductModule.Getter;

@Component({
  ...options,
})
class Home extends Vue {
  verificationCode = "";
  tel = "";
  isShowPlans = "";
  dataList = [];
  active = "1";
  activebottom = 0;
  activebottoms = 0;
  lists = 1;
  show = true;
  modulShow = false;
  modulShow1 = false;
  modulShow2 = false;
  modulShow3 = false;
  modulShow4 = false;
  modulShow5 = false;
  isHshow = true;
  showpopup = false;
  checked = false;
  sjfalse = false;
  popuptitle = "";
  telText = "";
  telText1 = "";
  popupcenter = "";
  titlevalue = "查看更多▾";
  tabsshow = true;
  telShow = false;
  telShow1 = false;
  showbotton = false;
  titlefalse = false;
  particularsindex = 0;
  // categories = "travel";
  inputOne = 0;
  inputTwo = 0;
  inputThree = 0;
  inputFour = 0;
  titles = "";
  current = 0;
  form = {
    name: "",
    idCard: "",
    phone: "",
    carCode: "",
  };
  productCode = {
    H001: "accident",
    H002: "familyProperty",
    H003: "personalProperty",
    H004: "travel",
    H005: "vaccine",
    H006: "travel",
    H007: "bankcard",
    H008: "accident",
    H009: "personalProperty",
    H010: "vaccine",
    H011: "receive",
    H012: "sinopec",
    H013: "traffic",
    H014: "carInsurance",
    H015: "receive",
    H016: "traffic",
  };
  @State("loading") loading;
  @State("hasShow") hasShow;
  @ProductAction(GET_PRODUCT) getProduct;

  @ProductState("productInfo") productInfo;
  @ProductState("planIndex") planIndex;
  @ProductState("productQuery") productQuery;

  @ProductGetters("coverUrl") coverUrl;
  @ProductGetters("productPlans") productPlans;
  @ProductGetters("currentPlan") currentPlan;
  @ProductGetters("productIntroduces") productIntroduces;

  @Watch("current")
  @ProductMutation("CURRENT_PLAN")
  noop1() {}

  @Watch("show")
  @Mutation("HAS_SHOW")
  noop2() {}

  nameChange() {
    const temp = /^[\u4e00-\u9fa5]{0,}$/;
    if (temp.test(this.form.name)) {
      this.inputOne = 1;
      sessionStorage.setItem("name", this.form.name);
    } else {
      this.inputOne = 2;
      sessionStorage.removeItem("name");
    }
  }
  idCardChange() {
    const temp =
      /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
    if (temp.test(this.form.idCard)) {
      this.inputTwo = 1;
      sessionStorage.setItem("idCard", this.form.idCard);
    } else {
      this.inputTwo = 2;
      sessionStorage.removeItem("idCard");
    }
  }
  phoneChange() {
    const temp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (temp.test(this.form.phone)) {
      this.inputThree = 1;
      sessionStorage.setItem("phone", this.form.phone);
    } else {
      this.inputThree = 2;
      sessionStorage.removeItem("phone");
    }
  }
  carCodeChange() {
    const temp =
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
    if (temp.test(this.form.carCode)) {
      this.inputFour = 1;
      sessionStorage.setItem("carCode", this.form.carCode);
    } else {
      this.inputFour = 2;
      sessionStorage.removeItem("carCode");
    }
  }
  insureAgree() {
    const one = /^[\u4e00-\u9fa5]{0,}$/;
    const two =
      /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
    const three = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    const four =
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
    if (this.checked) {
      const name = sessionStorage.getItem("name");
      const idCard = sessionStorage.getItem("idCard");
      const phone = sessionStorage.getItem("phone");
      const carCode = sessionStorage.getItem("carCode");
      if (
        name != "" ||
        null ||
        (undefined && idCard != "") ||
        null ||
        (undefined && phone != "") ||
        null ||
        (undefined && carCode != "") ||
        null ||
        undefined
      ) {
        if (
          one.test(name) &&
          two.test(idCard) &&
          three.test(phone) &&
          four.test(carCode)
        ) {
          const parms = {
            insuredIdNumber: idCard,
            insuredName: name,
            insuredPhone: phone,
            vehicleLicenceCode: carCode,
            policyHolderIdNumber: idCard,
            policyHolderName: name,
            policyHolderPhone: phone,
          };
          if (this.productInfo.productCode == "H011") {
            receivePingan(parms).then((res) => {
              this.$router.push({
                path: "/succer",
              });
            });
          } else if (this.productInfo.productCode == "H015") {
            receivesPingan(parms).then((res) => {
              this.$router.push({
                path: "/succer",
              });
            });
          }
        } else {
          Toast.fail("请填写正确信息!");
        }
      } else {
        Toast.fail("请填写正确信息!");
      }
    } else {
      Toast.fail("请先勾选协议条款!");
    }
  }
  created() {
    this.current = this.planIndex;
    this.show = this.hasShow && this.show;
    //H013token存储校验
    if (this.productInfo.productCode == "H013") {
      sessionStorage.setItem("token", window.location.href.split("token=")[1]);
    }
    if (this.productInfo.insuranceCompanyCode != "pingan001") {
      this.tabsshow = true;
    } else if (this.productInfo.insuranceCompanyCode == "pingan001") {
      this.tabsshow = false;
    }
    this.falseIs();
    const name = sessionStorage.getItem("name");
    if (name != "" || null || undefined) {
      this.form.name = name;
    }
    const idCard = sessionStorage.getItem("idCard");
    if (idCard != "" || null || undefined) {
      this.form.idCard = idCard;
    }
    const phone = sessionStorage.getItem("phone");
    if (phone != "" || null || undefined) {
      this.form.phone = phone;
    }
    const carCode = sessionStorage.getItem("carCode");
    if (carCode != "" || null || undefined) {
      this.form.carCode = carCode;
    }
  }

  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    this.getProduct(this.$route.query);
    this.falseIs();
  }
  beforeunloadHandler(e) {
    this.$Glutton.stopRecording();
  }
  close() {
    console.log("开始可回溯");
    this.$Glutton.startRecording();
    if (!this.productInfo) return;
    window.GluttonContext = { productName: this.productInfo.insuranceCompany };
  }
  falseIs() {
    this.productPlans.forEach((v, i) => {
      this.dataList = v.planDetails;
      v.planDetails.forEach((val, key) => {
        if (key > 4) {
          this.sjfalse = true;
        }
      });
    });
    this.titlefalse = false;
    if (this.titlefalse == true) {
      this.titlevalue = "收起▴";
    } else {
      this.titlevalue = "查看更多▾";
    }
  }
  falseclick() {
    this.titlefalse = !this.titlefalse;
    if (this.titlefalse == true) {
      this.titlevalue = "收起▴";
    } else {
      this.titlevalue = "查看更多▾";
    }
  }
  submit() {
    console.log(this.checked);
    // if (this.checked == true) {
    this.showbotton = true;
    // this.$router.push("/insure");
    // } else {
    // this.$toast("请仔细阅读并勾选投保须知内容及条款!");
    // }
  }
  action() {
    switch (this.activebottom) {
      case 0:
        this.activebottom = 1;
        this.lists = 2;
        break;
      case 1:
        this.activebottom = 2;
        this.lists = 3;
        break;
      case 2:
        this.activebottom = 3;
        this.lists = 4;
        break;
      case 3:
        this.activebottom = 4;
        this.lists = 5;
        break;
      case 4:
        this.$router.push({
          path: "/insure",
          query: {
            code: this.productInfo.productCode,
          },
        });
        break;
    }
  }
  actions() {
    switch (this.activebottoms) {
      case 0:
        this.activebottoms = 1;
        this.lists = 2;
        break;
      case 1:
        this.activebottoms = 2;
        this.lists = 3;
        break;
      case 2:
        this.activebottoms = 3;
        this.lists = 4;
        break;
      case 3:
        this.activebottoms = 4;
        this.lists = 5;
        break;
      case 4:
        this.activebottoms = 5;
        this.lists = 6;
        break;
      case 5:
        this.$router.push({
          path: "/insure",
          query: {
            code: this.productInfo.productCode,
          },
        });
        break;
    }
  }
  actionse() {
    switch (this.activebottom) {
      case 0:
        this.activebottom = 1;
        this.lists = 2;
        break;
      case 1:
        this.activebottom = 2;
        this.lists = 3;
        break;
      case 2:
        this.activebottom = 3;
        this.lists = 4;
        break;
      case 3:
        this.$router.push({
          path: "/insure",
          query: {
            code: this.productInfo.productCode,
          },
        });
        break;
    }
  }
  //领取模态框
  imageBtn() {
    this.modulShow = true;
  }
  modulSuer() {
    const temp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (temp.test(this.telText)) {
      telNumber({
        phoneNumber: this.telText,
      }).then((res) => {
        this.modulShow = false;
        this.verificationCode = res.giftCardNumber;
        this.modulShow1 = true;
      });
    } else {
      this.telShow = true;
    }
    this.telText = "";
  }
  modulSuer3() {
    const temp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (temp.test(this.telText1)) {
      payTelNumber({
        phoneNumber: this.telText1,
      }).then((res) => {
        this.modulShow3 = false;
        this.verificationCode = res.giftCardNumber;
        this.modulShow4 = true;
      });
    } else {
      this.telShow1 = true;
    }
    this.telText1 = "";
  }
  telChange() {
    const temp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (temp.test(this.telText)) {
      this.telShow = false;
    } else {
      this.telShow = true;
    }
  }
  telChange1() {
    const temp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if (temp.test(this.telText1)) {
      this.telShow1 = false;
    } else {
      this.telShow1 = true;
    }
  }
  imgCheckPhoneBtn() {
    this.modulShow3 = true;
  }
  hrefBtn() {
    this.modulShow1 = false;
    this.modulShow2 = true;
  }
  hrefBtn4() {
    if (this.productInfo.productCode == "H014") {
      this.$router.push({
        path: "/toReceive",
      });
    } else {
      this.modulShow4 = false;
      this.modulShow5 = true;
    }
  }
  modulSuer1() {
    this.modulShow1 = false;
    this.telText = "";
  }
  modulSuer2() {
    this.modulShow2 = false;
    window.location.href =
      "https://xinl.bigtreebroker.com/h5/20220615/#/pages/index/index";
    this.telText = "";
  }
  modulSuer5() {
    this.modulShow5 = false;
    window.location.href =
      "https://xinl.bigtreebroker.com/h5/20220615/#/pages/index/index";
    this.telText = "";
  }
  goBack() {
    this.$router.back();
  }
  callback(key) {
    console.log(key);
  }
  // 保险投款
  handleClause() {
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/insuranceClause"
    );
  }

  // 投保须知
  handleNotice() {
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/insuranceNotice"
    );
  }
  // 《免责条款》
  handleDisclaimer() {
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/disclaimer"
    );
  }

  // 《信息披露》
  handleDisclosure() {
    // console.log(this.productCode[this.productQuery.productCode]);
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/disclosure"
    );
  }
  // 《隐私政策》
  handlePrivacyPolicy() {
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/policy"
    );
  }
  // 《隐私政策》 005 和 007的
  handlePrivacy() {
    this.$router.push(
      "/" + this.productCode[this.productQuery.productCode] + "/privacy"
    );
  }
  // 客户告知书
  handleClient() {
    this.$router.push("/common/informed");
  }

  showPopup(i) {
    this.popupcenter = this.productIntroduces[2].content[i].titleMessage;
    this.popuptitle = this.productIntroduces[2].content[i].title;
    this.particularsindex = i;
    this.showpopup = true;
  }
}
export default Home;
</script>

<style lang="scss" src="./index.scss">
</style>
