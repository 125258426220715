import store from "@/store";
import * as TYPES from "@/store/action-types";

export const beforeEachHooks = {
  cleanToken: (to, form, next) => {
    document.title = to.meta.title;
    store.commit(TYPES.CLEAN_TOKEN);
    next();
  },
  productQuery: (to, form, next) => {
    const { productCode, insuranceCompanyCode } = to.query;
    if (productCode && insuranceCompanyCode) {
      store.commit(`product/${TYPES.PRODUCT_QUERY}`, to.query);
    }
    next();
  },
};
