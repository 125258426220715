<template>
  <!-- 银行卡险家庭版理赔说明 -->
  <div class="document">
    <img :src="this.image" alt="" width="100%" height="100%" />
    <p style="height: 1rem; width: 100%"></p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { getProduct } from "@/api/order";
@Component({})
class BankcardPolicy extends Vue {
  image = "";
  static name = "bankcard-policy";
  created() {
    getProduct().then((res) => {
      this.image = res.clausePicture.claimDeclare;
    });
  }
}
export default BankcardPolicy;
</script>
<style lang="scss" scoped>
</style>
