<template>
  <div class="document">
    <p class="font-weight-bold text-align">阳光财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">家庭财产综合保险A款条款（2015版）</p>
    <p class="font-weight-bold text-align">总 则</p>
    <p>
      &#12288;&#12288;<span>第一条 </span
      >本保险合同由保险条款、投保单、保险单、保险凭证以及批单组成。凡涉及本保险合同的约定，均应采用书面形式。
    </p>
    <p class="font-weight-bold text-align">保险标的</p>
    <p>
      &#12288;&#12288;<span>第二条 </span
      >本保险合同可以承保被保险人所有或使用并坐落于保险单载明地址内的下列家庭财产（以下简称“保险标的”），投保人可自由选择投保本条各项保险标的，并在保险单上载明：
    </p>
    <p>
      &#12288;&#12288;（一）房屋及其室内附属设备（如固定装置的水暖、气暖、卫生、供水、管道煤气及供电设备、厨房配套的设备、固化在墙壁或屋顶的灯具等）；
    </p>
    <p>&#12288;&#12288;（二）室内装潢；</p>
    <p>&#12288;&#12288;（三）室内财产：</p>
    <p>&#12288;&#12288;1．家具；</p>
    <p>
      &#12288;&#12288;2．家用电器（包括安装在房屋外的空调器和太阳能热水器等家用电器的室外设备）；
    </p>
    <p>&#12288;&#12288;3．床上用品、衣物、鞋帽、箱包、手表；</p>
    <p>
      &#12288;&#12288;4．文体娱乐用品，具体包括文具、书籍、球具、棋牌、电子游戏设备、遥控汽车、航模、健身器具、野外旅行帐篷、攀岩用具等装备；
    </p>
    <p>
      &#12288;&#12288;5．存放在室内的家庭成员（包括成人及儿童）的非机动车类代步车辆或工具。
    </p>
    <p>
      &#12288;&#12288;<span>第三条 </span
      >下列财产经投保人与保险人特别约定，并在保险合同中载明，可列入本保险合同的保险标的范围内：
    </p>
    <p>
      &#12288;&#12288;（一）存放于被保险人院内、室内的非机动农机具、农用工具及存放于室内的粮食及农副产品；
    </p>
    <p>
      &#12288;&#12288;（二）属于被保险人代他人保管或者与他人共有而由被保险人负责管理的本保险条款第二条载明的财产；
    </p>
    <p>&#12288;&#12288;（三）经保险人同意承保的被保险人所有的其他家庭财产。</p>
    <div class="font-weight-bold">
      <p>
        &#12288;&#12288;<span>第四条 </span
        >下列家庭财产不属于本保险合同的保险标的：
      </p>
      <p>
        &#12288;&#12288;（一）金银、首饰、珠宝、货币、有价证券、票证、邮票、古玩、古币、古书籍、玉石、字画、艺术品、稀有金属等珍贵财物；
      </p>
      <p>
        &#12288;&#12288;（二）文件、账册、技术资料、图表、电脑软件及资料，各种磁带、磁盘、移动储存设备、激光盘及其内存储的电子资料，以及其他无法鉴定价值的财产；
      </p>
      <p>&#12288;&#12288;（三）日用消耗品、动物、植物、机动车辆类；</p>
      <p>
        &#12288;&#12288;（四）阳台、露台以及露天存放的财产，或用芦席、稻草、油毛毡、麦杆、芦苇、竹竿、帆布、塑料布、纸板等为外墙、屋顶的简陋屋棚及柴房、禽畜棚以及其内存放的财产；与保险房屋不成一体的厕所、围墙以及其内存放的财产；
      </p>
      <p>&#12288;&#12288;（五）用于从事工商业生产、经营活动的房屋和财产；</p>
      <p>
        &#12288;&#12288;（六）政府有关部门征用、占用的房屋及其内的财产，违章建筑、危险建筑、非法占用的财产或处于紧急危险状态下的财产；
      </p>
      <p>
        &#12288;&#12288;（七）不属于国家建筑规定钢结构，钢、钢筋混凝土结构，钢筋混凝土结构，混合结构以及砖木结构的其他结构房屋及其内存放的财产；
      </p>
      <p>
        &#12288;&#12288;（八）其他不属于本保险条款第二条、第三条所列明的家庭财产。
      </p>
    </div>
    <p class="font-weight-bold text-align">保险责任</p>
    <p>
      &#12288;&#12288;<span>第五条 </span
      >在保险期间内，由于下列原因造成保险标的的损失，保险人依照本保险合同的约定负责赔偿：
    </p>
    <p>&#12288;&#12288;（一）火灾、爆炸，包括但不限于：</p>
    <p>
      &#12288;&#12288;1.家庭燃气用具、电器、用电线路以及其他内部或外部火源引起的火灾；
    </p>
    <p>&#12288;&#12288;2.家庭燃气用具、液化气罐以及燃气泄露引起的爆炸。</p>
    <p>
      &#12288;&#12288;（二）台风、暴风、龙卷风、雷击、暴雨、洪水、冰雹、暴雪、崖崩、冰凌、泥石流、突发性滑坡和自然灾害引起地陷或下沉。
    </p>
    <p>
      &#12288;&#12288;（三）飞行物体及其他空中运行物体坠落，外来不属于被保险人所有或使用的建筑物和其他固定物体的倒塌。
    </p>
    <p>
      &#12288;&#12288;保险事故发生时，为抢救保险标的或防止灾害蔓延，采取必要的、合理的措施而造成保险标的的损失，保险人按照本保险合同的约定也负责赔偿。
    </p>
    <p>
      &#12288;&#12288;<span>第六条 </span
      >保险事故发生后，被保险人为防止或者减少保险标的的损失所支付的必要的、合理的费用，保险人按照本保险合同的约定也负责赔偿(以下简称“施救费用”）。
    </p>
    <div class="font-weight-bold">
      <p class="text-align">责任免除</p>
      <p>
        &#12288;&#12288;<span>第七条 </span
        >下列原因造成的损失、费用，保险人不负责赔偿：
      </p>
      <p>
        &#12288;&#12288;（一）核辐射、核爆炸、核污染等任何放射性污染，大气污染、土地污染、水污染及其他各种非放射性污染，但因本保险合同责任范围内的事故造成的污染不在此限；
      </p>
      <p>&#12288;&#12288;（二）地震、海啸、火山爆发，及其次生灾害；</p>
      <p>
        &#12288;&#12288;（三）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、盗窃、抢劫；
      </p>
      <p>&#12288;&#12288;（四）行政行为或司法行为；</p>
      <p>
        &#12288;&#12288;（五）投保人、被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员的故意、违法行为，或未按使用说明、操作规范或相关国家标准擅自改装、改造保险标的的行为。
      </p>
      <p>
        &#12288;&#12288;<span>第八条 </span>下列损失、费用，保险人也不负责赔偿：
      </p>
      <p>&#12288;&#12288;（一）保险标的遭受保险事故引起的任何间接损失；</p>
      <p>
        &#12288;&#12288;（二）家用电器因使用过度、超电压、短路、断路、漏电、自身发热、烘烤等原因所造成本身的损毁；
      </p>
      <p>
        &#12288;&#12288;（三）保险标的本身缺陷、保管不善、使用不当、变质、霉烂、受潮、虫咬、自然磨损、自然损耗、自燃、烘焙导致的本身的损失；
      </p>
      <p>
        &#12288;&#12288;（四）保险标的在保险单载明地址的房屋外遭受的损失，但安装在房屋外的空调器和太阳能热水器等家用电器的室外设备除外；
      </p>
      <p>
        &#12288;&#12288;（五）坐落在蓄洪区、行洪区，或在江河岸边、低洼地区以及防洪堤以外当地常年警戒水位线以下的家庭财产，由于洪水所造成的一切损失；
      </p>
      <p>
        &#12288;&#12288;（六）保险事故造成的玻璃单独破损，但与玻璃为一体的其他保险标的一并发生损失的情形除外；
      </p>
      <p>
        &#12288;&#12288;（七）本保险合同约定的免赔额或按本保险合同中载明的免赔率计算的免赔额；
      </p>
      <p>&#12288;&#12288;（八）其他不属于保险合同责任范围内的损失和费用。</p>
    </div>
    <p class="font-weight-bold text-align">保险金额与免赔额（率）</p>
    <p>
      &#12288;&#12288;<span>第九条 </span>本保险合同的保险金额按以下方式确定：
    </p>
    <p>
      &#12288;&#12288;（一）房屋及室内附属设备、室内装潢的保险金额由投保人在投保时与保险人协商确定并在保险单中载明；
    </p>
    <p>
      &#12288;&#12288;（二）室内财产的保险金额由投保人在投保时与保险人协商，分项目确定，并在保险单中载明。如保险单未分项目载明的，则本保险将按照以下比例确定各项室内财产的保险金额：即家具占室内财产保险金额的30%；衣物、床上用品、鞋帽、箱包及手表等占
      30%；家用电器以及文体娱乐用品占30%；非机动车类代步车辆或工具占10%。
    </p>
    <p>
      &#12288;&#12288;<span>第十条 </span
      >每次事故的免赔额（率）由投保人与保险人在订立保险合同时协商确定，并在保险合同中载明。
    </p>
    <p class="font-weight-bold text-align">保险期间</p>
    <p>
      &#12288;&#12288;<span>第十一条 </span
      >本保险合同的保险期间由投保人与保险人协商确定，并在保险单上载明。
    </p>
    <p class="font-weight-bold text-align">保险费</p>
    <p>
      &#12288;&#12288;<span>第十二条 </span
      >本保险合同的保险费由保险人根据保险金额及被保险人具体风险状况等因素确定，并在保险单上载明其金额。
    </p>
    <p class="font-weight-bold text-align">保险人义务</p>
    <p>
      &#12288;&#12288;<span>第十三条 </span
      >订立保险合同时，采用保险人提供的格式条款的，保险人向投保人提供的投保单应当附格式条款，保险人应当向投保人说明保险合同的内容。对保险合同中免除保险人责任的条款，保险人在订立保险合同时应当在投保单、保险单或者其他保险凭证上做出足以引起投保人注意的提示，并对该条款的内容以书面或者口头形式向投保人做出明确说明；未作提示或者明确说明的，该条款不产生效力。
    </p>
    <p>
      &#12288;&#12288;<span>第十四条 </span
      >本保险合同成立后，保险人应当及时向投保人签发保险单或其他保险凭证。
    </p>
    <p>
      &#12288;&#12288;<span>第十五条 </span
      >保险人依据本保险条款相关约定所取得的保险合同解除权，自保险人知道有解除事由之日起，超过三十日不行使而消灭。自保险合同成立之日起超过二年的，保险人不得解除保险合同；发生保险事故的，保险人承担赔偿责任。
    </p>
    <p>
      &#12288;&#12288;保险人在保险合同订立时已经知道投保人未如实告知的情况的，保险人不得解除保险合同；发生保险事故的，保险人应当承担赔偿责任。
    </p>
    <p>
      &#12288;&#12288;<span>第十六条 </span
      >保险人按照本保险合同的约定，认为被保险人提供的有关索赔的证明和资料不完整的，应当及时一次性通知投保人、被保险人补充提供。
    </p>
    <p>
      &#12288;&#12288;<span>第十七条 </span
      >保险人收到被保险人的赔偿保险金的请求后，应当及时做出是否属于保险责任的核定；情形复杂的，应当在三十日内做出核定，但保险合同另有约定的除外。
    </p>
    <p>
      &#12288;&#12288;保险人应当将核定结果通知被保险人；对属于保险责任的，在与被保险人达成赔偿保险金的协议后十日内，履行赔偿保险金义务。保险合同对赔偿保险金的期限有约定的，保险人应当按照约定履行赔偿保险金的义务。保险人依照前款约定做出核定后，对不属于保险责任的，应当自做出核定之日起三日内向被保险人发出拒绝赔偿保险金通知书，并说明理由。
    </p>
    <p>
      &#12288;&#12288;<span>第十八条 </span
      >保险人自收到赔偿保险金的请求和有关证明、资料之日起六十日内，对其赔偿保险金的数额不能确定的，应当根据已有证明和资料可以确定的数额先予支付；保险人最终确定赔偿的数额后，应当支付相应的差额。
    </p>
    <p class="font-weight-bold text-align">投保人、被保险人义务</p>
    <p>
      &#12288;&#12288;<span>第十九条 </span
      >订立保险合同，保险人就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。
    </p>
    <p>
      &#12288;&#12288;<span
        >投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险人决定是否同意承保或者提高保险费率的，保险人有权解除保险合同。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >投保人故意不履行如实告知义务的，保险人对于保险合同解除前发生的保险事故，不承担赔偿保险金的责任，并不退还保险费。</span
      >
    </p>
    <p>
      &#12288;&#12288;投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对于保险合同解除前发生的保险事故，不承担赔偿保险金的责任，但应当退还保险费。
    </p>
    <p>
      &#12288;&#12288;<span>第二十条 </span>投保人应按保险合同的约定缴纳保险费。
    </p>
    <p>
      &#12288;&#12288;本保险合同约定一次性交付保险费或对保险费交付方式、交付时间没有约定的，投保人应在保险期间起始日前一次性交付保险费。<span
        >投保人未按本款约定交付保险费的，对保险费交付前发生的保险事故，保险人不承担保险赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >如果发生投保人未按期足额交付保险费或不按约定日期交付第二期或以后任何一期保险费的违约情形，从违约之日起，保险人按保险事故发生前投保人已付保险费占保险单中载明的总保险费的比例承担保险责任。违约情形消除后发生保险事故的，保险人继续按本保险合同的约定承担保险责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十一条 </span
      >被保险人应当遵照国家有关消防、安全方面的规定，维护保险标的的安全。
    </p>
    <p>
      &#12288;&#12288;保险人可以对保险标的的安全状况进行检查，向投保人、被保险人提出消除不安全因素和隐患的书面建议，被保险人应该认真付诸实施。
    </p>
    <p>
      &#12288;&#12288;投保人、被保险人未按照约定履行其对保险标的的安全应尽责任的，保险人有权要求增加保险费或者解除保险合同。
    </p>
    <p>
      &#12288;&#12288;<span>第二十二条 </span
      >保险标的转让的，被保险人或者受让人应当及时通知保险人。
    </p>
    <p>
      &#12288;&#12288;因保险标的转让导致危险程度显著增加的，保险人自收到前款规定的通知之日起三十日内，可以按照保险合同规定增加保险费或者解除保险合同。
    </p>
    <p>
      &#12288;&#12288;<span
        >被保险人、受让人未履行本条规定的通知义务的，因转让导致保险标的危险程度显著增加而发生的保险事故，保险人不承担赔偿保险金的责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十三条 </span
      >在保险合同有效期内，保险标的的危险程度显著增加的，被保险人应当按照保险合同规定及时通知保险人，保险人可以按照保险合同规定增加保险费或者解除保险合同。
    </p>
    <p>
      &#12288;&#12288;被保险人未履行前款规定的通知义务的，因保险标的的危险程度显著增加而发生的保险事故，保险人不承担赔偿保险金的责任。
    </p>
    <p>&#12288;&#12288;<span>第二十四条 </span>知道保险事故发生后：</p>
    <p>
      &#12288;&#12288;（一）被保险人应尽力采取必要、合理的措施，防止或减少损失，否则，<span
        >对因此扩大的损失，保险人不承担赔偿责任；</span
      >
    </p>
    <p>
      &#12288;&#12288;（二）投保人、被保险人应及时通知保险人，并书面说明事故发生的原因、经过和损失情况，涉及违法、犯罪的，应立即向公安部门报案；<span>故意或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险人对无法确定的部分，不承担赔偿保险金的责任，</span>但保险人通过其他途径已经及时知道或者应当及时知道保险事故发生的除外；
    </p>
    <p>
      &#12288;&#12288;（三）被保险人应保护事故现场，允许并且协助保险人进行事故调查。<span
        >对于拒绝或者妨碍保险人进行事故调查导致无法确定事故原因或核实损失情况的，保险人对无法核实的部分不承担赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十五条 </span
      >被保险人请求赔偿时，应向保险人提供下列证明和资料：
    </p>
    <p>&#12288;&#12288;（一）索赔申请书；</p>
    <p>&#12288;&#12288;（二）保险单或保险凭证正本；</p>
    <p>&#12288;&#12288;（三）损失清单；</p>
    <p>&#12288;&#12288;（四）有关保险标的购置发票、凭证、费用单据及资料；</p>
    <p>&#12288;&#12288;（五）有关部门的证明；</p>
    <p>
      &#12288;&#12288;（六）投保人、被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料。
    </p>
    <p>
      &#12288;&#12288;<span
        >被保险人未履行前款约定的索赔材料提供义务，导致保险人无法核实损失情况的，保险人对无法核实的部分不承担赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十六条 </span
      >投保人住所或通讯地址变更时，应及时以书面形式通知保险人。投保人未以书面形式通知的，保险人按本保险合同所载的最后住所或通讯地址发送的有关通知，均视为已发送给投保人。
    </p>
    <p class="font-weight-bold text-align">赔偿处理</p>
    <p>
      &#12288;&#12288;<span>第二十七条 </span
      ><span
        >保险事故发生时，被保险人对保险标的不具有保险利益的，不得向保险人请求赔偿保险金。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十八条 </span
      >保险标的发生保险责任范围内的损失，保险人有权选择按下列方式赔偿：
    </p>
    <p>
      &#12288;&#12288;（一）货币赔偿：根据受损标的的实际损失和本保险合同的约定，以支付保险金的方式赔偿；
    </p>
    <p>
      &#12288;&#12288;（二）实物赔偿：保险人以实物替换受损保险标的，该实物应具有保险标的出险前同等的类型、结构、状态和性能；
    </p>
    <p>
      &#12288;&#12288;（三）实际修复：保险人自行或委托他人修理修复受损标的。
    </p>
    <p>
      &#12288;&#12288;<span
        >对受损标的在替换或修复过程中，被保险人进行的任何变更、性能增加或改进所产生的额外费用，保险人不负责赔偿。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span>第二十九条 </span
      >保险标的发生保险责任范围内的损失时，保险人按照以下约定赔偿：
    </p>
    <p>&#12288;&#12288;（一）受损保险标的不止一项时，应分项计算赔偿。</p>
    <p>
      &#12288;&#12288;其中保险标的的保险金额等于或高于出险时实际价值时，保险人按照保险标的的实际损失，在扣除本保险合同约定的免赔额后，计算赔偿，但最高不超过出险时保险标的的实际价值；
    </p>
    <p>
      &#12288;&#12288;保险标的的保险金额低于保险标的出险时实际价值时，保险人按照保险标的的实际损失，在扣除本保险合同约定的免赔额后，在保险标的的保险金额范围内计算赔偿。
    </p>
    <p>
      &#12288;&#12288;（二）保险标的遭受损失后，如果有残余价值，应由双方协商处理。如折归被保险人，由双方协商确定其价值，并在保险赔款中扣除。
    </p>
    <p>
      &#12288;&#12288;（三）被保险人为防止或减少保险标的的损失所支付的必要、合理的施救费用,
      在该项保险标的损失赔偿金额以外另行计算。
    </p>
    <p>
      &#12288;&#12288;其中保险标的的保险金额大于或等于其出险时实际价值时，被保险人为防止或减少保险标的的损失所支付的必要的、合理的费用，在保险标的损失赔偿金额之外另行计算，最高不超过被施救保险标的的出险时实际价值；
    </p>
    <p>
      &#12288;&#12288;保险标的的保险金额小于其出险时实际价值时，被保险人为防止或减少保险标的的损失所支付的必要的、合理的费用，在保险标的损失赔偿金额之外另行计算，最高不超过被施救保险标的的保险金额；
    </p>
    <p>
      &#12288;&#12288;被施救的财产中，含有本保险合同未承保财产的，按被施救保险标的的出险时实际价值与全部被施救财产价值的比例分摊施救费用。
    </p>
    <p>
      &#12288;&#12288;<span>第三十条 </span
      >保险事故发生时，如果存在重复保险，保险人按照本保险合同的相应保险金额与其他保险合同及本保险合同相应保险金额总和的比例承担赔偿责任。
    </p>
    <p>
      &#12288;&#12288;若保险标的的赔偿金额因重复保险的存在而减少时，保险人对于施救费用的赔偿金额也以同样的比例为限。
    </p>
    <p>
      &#12288;&#12288;<span>其他保险人应承担的赔偿金额，本保险人不负责垫付。</span>若被保险人未如实告知导致保险人多支付赔偿金的，保险人有权向被保险人追回多支付的部分。
    </p>
    <p>
      &#12288;&#12288;<span>第三十一条 </span
      >保险标的在保险期间内发生部分损失，保险人履行赔偿义务后，本保险合同的保险金额自损失发生之日起按保险人的赔偿金额相应减少，保险人不退还保险金额减少部分的保险费。如被保险人请求恢复保险金额，应按原约定的保险费率另行支付恢复部分从投保人请求的恢复日期起至保险期间届满之日止按日比例计算的保险费，由保险人出具批单批注。
    </p>
    <p>
      &#12288;&#12288;<span>第三十二条 </span
      >发生保险责任范围内的损失，应由有关责任方负责赔偿的，保险人自向被保险人赔偿保险金之日起，在赔偿金额范围内代位行使被保险人对有关责任方请求赔偿的权利，被保险人应当向保险人提供必要的文件和所知道的有关情况。
    </p>
    <p>
      &#12288;&#12288;被保险人已经从有关责任方取得赔偿的，保险人赔偿保险金时，可以相应扣减被保险人已从有关责任方取得的赔偿金额。
    </p>
    <p>
      &#12288;&#12288;（二）投保人、被保险人应及时通知保险人，并书面说明事故发生的原因、经过和损失情况，涉及违法、犯罪的，应立即向公安部门报案；<span>故意或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险人对无法确定的部分，不承担赔偿保险金的责任，</span>但保险人通过其他途径已经及时知道或者应当及时知道保险事故发生的除外；
    </p>
    <p>
      &#12288;&#12288;<span>保险事故发生后，在保险人未赔偿保险金之前，被保险人放弃对有关责任方请求赔偿权利的，保险人不承担赔偿责任；</span>保险人向被保险人赔偿保险金后，被保险人未经保险人同意放弃对有关责任方请求赔偿权利的，该行为无效；由于被保险人故意或者因重大过失致使保险人不能行使代位请求赔偿的权利的，保险人可以扣减或者要求返还相应的保险金。
    </p>
    <p>
      &#12288;&#12288;<span>第三十三条 </span
      >保险人受理报案、进行现场查勘、核损定价、参与案件诉讼、向被保险人提供建议等行为，均不构成保险人对赔偿责任的承诺。
    </p>
    <p>
      &#12288;&#12288;<span>第三十四条 </span
      >被保险人向保险人请求赔偿保险金的诉讼时效期间为二年，自其知道或者应当知道保险事故发生之日起计算。
    </p>
    <p class="font-weight-bold text-align">争议处理和法律适用</p>
    <p>
      &#12288;&#12288;<span>第三十五条 </span
      >因履行本保险合同发生的争议，由当事人协商解决。协商不成的，提交保险单载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向人民法院起诉。
    </p>
    <p>
      &#12288;&#12288;<span>第三十六条 </span
      >与本保险合同有关的以及履行本保险合同产生的一切争议，适用中华人民共和国法律（不包括港、澳、台地区法律）。
    </p>
    <p class="font-weight-bold text-align">其他事项</p>
    <p>
      &#12288;&#12288;<span>第三十七条 </span
      >投保人和保险人可以协商变更保险合同内容。
    </p>
    <p>
      &#12288;&#12288;变更保险合同的，应当由保险人在保险单或者其他保险凭证上批注或附贴批单，或者投保人和保险人订立变更的书面协议。
    </p>
    <p>
      &#12288;&#12288;<span>第三十八条 </span
      >投保人可随时书面申请解除本保险合同，投保人退保的，需提交保险单正本、保险费发票。本保险合同自保险人收到投保人的书面申请之日的二十四时起终止。
    </p>
    <p>
      &#12288;&#12288;保险责任开始前，投保人要求解除合同的，保险人扣除5%手续费后，将剩余部分的保险费退还投保人；保险责任开始后，投保人要求解除合同的，对保险责任开始之日起至合同解除之日止期间的保险费，保险人按日比例计收，剩余部分退还投保人。
    </p>
    <p>
      &#12288;&#12288;保险人亦可解除本保险合同。保险责任开始前，保险人要求解除合同的，不得向投保人收取手续费并应退还已收取的保险费；保险责任开始后，保险人可提前十五天通知投保人解除合同，对保险责任开始之日起至合同解除之日止期间的保险费，按日比例计收，剩余部分退还投保人。
    </p>
    <p>
      &#12288;&#12288;保险标的发生部分损失的，投保人或保险人按照本条规定解除合同的，保险人仅将保险标的未受损部分的保险费，按照合同约定扣除自保险责任开始之日起至合同解除之日止应收的部分后，退还投保人。
    </p>
    <p class="font-weight-bold text-align">释义</p>
    <p>
      &#12288;&#12288;<span>【家庭成员】</span>指与被保险人一起居住、生活的配偶、子女、父母，以及与之有抚养、赡养或者扶养关系的家庭其他成员、近亲属。
    </p>
    <p>
      &#12288;&#12288;<span>【暂居人员】</span>指与被保险人相识的或具有雇佣关系的，且经被保险人同意与其共同居住在保险单载明地址的房屋内，并连续居住超过5天的人员。
    </p>
    <p class="font-weight-bold text-align">
      附加管道破裂及水渍损失保险A款条款（2015版）
    </p>
    <p class="font-weight-bold text-align">总则</p>
    <p>
      &#12288;&#12288;<span>第一条 </span
      >在投保阳光财产保险股份有限公司家庭财产类保险（以下简称“主险”）的基础上，投保人可以投保本附加险。主险合同效力终止，本附加险合同效力亦同时终止；主险合同无效，本附加险合同亦无效。本附加险合同未约定事项，以主险合同为准；主险合同与本附加险合同相抵触之处，以本附加险合同为准。凡涉及本附加险合同的约定，均应采用书面形式。
    </p>
    <p class="font-weight-bold text-align">保险责任</p>
    <p>
      &#12288;&#12288;<span>第二条 </span
      >投保人可以自由选择投保下列各项保险责任中的一项或多项，并在保险单中载明，<span
        >未载明的，保险人不承担保险责任：</span
      >
    </p>
    <p>
      &#12288;&#12288;（一）被保险人财产损失保险：即在本附加险保险期间内，因高压、碰撞、严寒或高温造成被保险房屋内、楼上住户、隔壁住户或属于业主共有部分的自来水管道、暖气管道（含暖气片）、下水管道以及太阳能热水器室内外管道（以下简称“水暖管”）破裂，造成主险保险标的遭受水浸、腐蚀导致的直接损失，保险人按本附加险合同的约定负责赔偿。
    </p>
    <p>
      &#12288;&#12288;（二）修复更换费用损失保险：即在本附加险保险期间内，因高压、碰撞、严寒、高温造成被保险房屋内水暖管破裂，因此产生的水暖管修复更换费用，保险人按本附加险合同的约定负责赔偿。
    </p>
    <div class="font-weight-bold">
      <p class="text-align">责任免除</p>
      <p>
        &#12288;&#12288;<span>第三条 </span
        >下列原因造成的损失，保险人不负责赔偿：
      </p>
      <p>
        &#12288;&#12288;（一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
      </p>
      <p>
        &#12288;&#12288;（二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
      </p>
      <p>&#12288;&#12288;（三）水暖管安装、检修、试水、试压；</p>
      <p>&#12288;&#12288;（四）施工造成的水暖管破裂。</p>
      <p>
        &#12288;&#12288;<span>第四条 </span>下列损失、费用，保险人不负赔偿责任：
      </p>
      <p>&#12288;&#12288;（一）水暖管破裂致使供水中断造成的损失；</p>
      <p>
        &#12288;&#12288;（二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
      </p>
      <p>
        &#12288;&#12288;（三）其他不属于本附加险合同责任范围内的损失和费用。
      </p>
    </div>
    <p class="font-weight-bold text-align">赔偿限额与免赔额（率）</p>
    <p>
      &#12288;&#12288;<span>第五条 </span
      >本附加险合同的总赔偿限额及各项保险责任的赔偿限额由投保人在投保时与保险人协商确定，并在保险单中分项载明。
    </p>
    <p>
      &#12288;&#12288;<span>第六条 </span
      >本附加险合同的每次事故的免赔额（率）由投保人与保险人在订立保险合同时协商确定，并在保险合同中载明。
    </p>
    <p class="font-weight-bold text-align">
      附加管道破裂及水渍第三者责任保险A款条款（2015版）
    </p>
    <p class="font-weight-bold text-align">总则</p>
    <p>
      &#12288;&#12288;<span>第一条 </span
      >在投保阳光财产保险股份有限公司家庭财产类保险（以下简称“主险”）的基础上，投保人可以投保本附加险。主险合同效力终止，本附加险合同效力亦同时终止；主险合同无效，本附加险合同亦无效。本附加险合同未约定事项，以主险合同为准；主险合同与本附加险合同相抵触之处，以本附加险合同为准。凡涉及本附加险合同的约定，均应采用书面形式。
    </p>
    <p>&#12288;&#12288;</p>
    <p class="font-weight-bold text-align">保险责任</p>
    <p>
      &#12288;&#12288;<span>第二条 </span
      >在本附加险保险期间内，被保险房屋内水暖管因高压、碰撞、严寒、高温造成爆裂，导致第三者人身伤亡或财产损失的，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本附加险合同约定负责赔偿。
    </p>
    <div class="font-weight-bold">
      <p class="text-align">责任免除</p>
      <p>
        &#12288;&#12288;<span>第三条 </span
        >下列原因造成的损失，保险人不负责赔偿：
      </p>
      <p>
        &#12288;&#12288;（一）被保险人房屋内的水暖管年久失修、自然磨损、腐蚀变质或未采取必要的防护措施；
      </p>
      <p>
        &#12288;&#12288;（二）被保险人及其家庭成员、其他亲属、暂居人员、雇佣人员擅自改动原管道设计或用途；
      </p>
      <p>&#12288;&#12288;（三）水暖管安装、检修、试水、试压；</p>
      <p>&#12288;&#12288;（四）施工造成的水暖管破裂。</p>
      <p>
        &#12288;&#12288;<span>第四条 </span>下列损失、费用，保险人不负赔偿责任：
      </p>
      <p>&#12288;&#12288;（一）水暖管破裂致使供水中断造成的损失；</p>
      <p>
        &#12288;&#12288;（二）本附加险合同约定的免赔额或按本附加险合同中载明的免赔率计算的免赔额；
      </p>
      <p>
        &#12288;&#12288;（三）其他不属于本附加险合同责任范围内的损失和费用。
      </p>
    </div>
    <p class="font-weight-bold text-align">赔偿限额与免赔额（率）</p>
    <p>
      &#12288;&#12288;<span>第五条 </span
      >本附加险合同的赔偿限额由投保人在投保时与保险人协商确定，并在保险单中分项载明。
    </p>
    <p>
      &#12288;&#12288;<span>第六条 </span
      >本附加险合同的每次事故的免赔额（率）由投保人与保险人在订立保险合同时协商确定，并在保险合同中载明。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class FamilyPropertyClause extends Vue {
  static name = "family-property-clause";
}
export default FamilyPropertyClause;
</script>
<style lang="scss" src="./index.scss" scoped></style>
