<template>
  <div class="document">
    <p class="font-weight-bold text-align">中国太平洋财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">个人账户资金损失保险条款</p>

    <p class="font-weight-bold text-align">总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同（以下简称为“本合同”）由投保单、保险单或其他保险凭证及所附条款，与本合同有关的投保文件、声明、批注、附贴批单及其他书面文件构成。凡涉及本合同的约定，均应采用书面形式。
    </p>
    <p>
      &#12288;&#12288;第二条
      本合同中个人账户是指自然人在经金融管理机构批准成立的商业银行等金融机构办理的存折账户、银行卡账户（包括借记卡、信用卡及附属卡）、手机银行账户、网上银行账户（以下简称为“网银账户”）、电话银行账户、微信银行账户或在中国人民银行批准的第三方支付机构设立的支付账户（包括支付宝、财付通等，以下简称为“第三方支付账户”）。
    </p>
    <p>
      &#12288;&#12288;本合同的被保险人为上述个人账户的所有人和信用卡附属卡的合法持有人。如果本合同对所承保的个人账户有具体约定的，以保险单载明为准。
    </p>

    <p class="font-weight-bold text-align">保险责任</p>
    <p>
      &#12288;&#12288;第三条
      在保险期间内，因下列原因导致被保险人个人账户内资金的损失，保险人根据本合同的约定，在保险单中载明的保险金额内负责赔偿：
    </p>
    <p>&#12288;&#12288;（一）被保险人的个人账户被他人盗刷、盗用、复制；</p>
    <p>
      &#12288;&#12288;（二）被保险人迫于暴力威胁将个人账户的账号及密码透露给他人。
    </p>

    <div class="font-weight-bold">
      <p class="text-align">责任免除</p>
      <p>&#12288;&#12288;第四条 出现下列任一情形时，保险人不负责赔偿：</p>
      <p>
        &#12288;&#12288;（一）被保险人未按照规定使用动态密码、验证码、数字证书等安全保障工具；
      </p>
      <p>
        &#12288;&#12288;（二）被保险人发现账户、用户名、密码已被他人知悉或丢失数字证书、接收动态密码的手机后，未及时变更相关账户密码并且通知账户管理方冻结或挂失账户。
      </p>
      <p>&#12288;&#12288;第五条 由于下列原因造成的损失，保险人不负责赔偿：</p>
      <p>&#12288;&#12288;（一）被保险人的故意或重大过失行为；</p>
      <p>&#12288;&#12288;（二）被保险人同意他人使用其个人账户或被他人诈骗；</p>
      <p>
        &#12288;&#12288;（三）被保险人在未遭受暴力威胁的情况下向他人透露账号或密码；
      </p>
      <p>
        &#12288;&#12288;（四）被保险人未遵守银行账户及第三方支付账户使用规定；
      </p>
      <p>&#12288;&#12288;（五）账户管理方自身系统出现故障。</p>
      <p>&#12288;&#12288;第六条 对于下列损失，保险人不负责赔偿：</p>
      <p>&#12288;&#12288;（一）个人账户在挂失或冻结前72小时以外的损失；</p>
      <p>&#12288;&#12288;（二）利息；</p>
      <p>&#12288;&#12288;（三）间接损失；</p>
      <p>
        &#12288;&#12288;（四）保险单中载明的应由被保险人自行承担的每次事故免赔额。
      </p>
      <p>
        &#12288;&#12288;第七条
        其他不属于本合同责任范围内的损失，保险人不负责赔偿。
      </p>
    </div>

    <p class="font-weight-bold text-align">保险期间</p>
    <p>
      &#12288;&#12288;第八条
      除另有约定外，保险期间为一年，以保险单载明的起讫时间为准。
    </p>

    <p class="font-weight-bold text-align">保险金额与免赔额</p>
    <p>
      &#12288;&#12288;第九条
      本合同的保险金额和每次事故免赔额由投保人与保险人协商确定，并在保险单中载明。累计赔款达到保险金额，本保险合同自动终止。
    </p>

    <p class="font-weight-bold text-align">一般事项</p>
    <p>
      &#12288;&#12288;第十条
      订立保险合同，保险人就保险财产或者被保险人的有关情况提出询问的，投保人应当如实告知，并如实填写投保单。
    </p>
    <p>
      &#12288;&#12288;投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险人决定是否同意承保或者提高保险费率的，保险人有权解除合同。
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;投保人故意不履行如实告知义务的，保险人对于保险合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费。
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对于保险合同解除前发生的保险事故，不承担赔偿责任，但应当退还保险费。
    </p>
    <p>
      &#12288;&#12288;保险人在合同订立时已经知道投保人未如实告知的情况的，保险人不得解除合同；发生保险事故的，保险人应当承担赔偿保险金的责任。
    </p>
    <p>
      &#12288;&#12288;第十一条
      除另有约定外，投保人应在合同成立时交清保险费。<span
        >保险费未交清前，本合同不生效，保险人不承担保险责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;第十二条
      被保险人应遵守个人账户交易安全的有关规定，妥善保管账号、密码、数字证书、接收动态密码的手机，发现账号、密码泄露，或者数字证书丢失、接收动态密码的手机丢失后，被保险人应立即采取必要、合理的措施，尽力避免保险事故的发生。
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;因被保险人未履行前款约定的义务而导致保险事故发生的，保险人有权拒绝赔偿；对因此而导致其损失扩大的，保险人有权对扩大的部分拒绝赔偿。
    </p>
    <p>
      &#12288;&#12288;第十三条
      在本合同有效期内，保险标的的危险程度显著增加的，被保险人应当及时通知保险人，保险人可以增加保险费或者解除本合同。
    </p>
    <p>
      &#12288;&#12288;被保险人未履行前款约定的通知义务的，因保险标的的危险程度显著增加而发生的保险事故，保险人不承担赔偿保险金的责任。
    </p>
    <p>
      &#12288;&#12288;第十四条
      本合同成立后，投保人要求解除本合同的，应当向保险人提出书面申请，本合同自保险人收到书面申请时终止。保险人根据《保险法》规定或者本合同约定要求解除本合同的，除《保险法》另有规定或本合同另有约定外，本合同自解除通知送达投保人最后所留通讯地址时终止。合同解除时，除另有约定外，保险人应向投保人退还未满期保险费。
    </p>

    <p class="font-weight-bold text-align">&#12288;&#12288;赔偿处理</p>
    <p>&#12288;&#12288;第十五条 发生本保险责任范围内的事故后，被保险人应该：</p>
    <p class="font-weight-bold">
      &#12288;&#12288;（一）被保险人应在知道存在资金损失的可能或者已经发生实际资金损失后及时通知个人账户管理方，对于已经发生实际资金损失的，还应及时向当地公安机关报案。由于被保险人过错未及时报案导致损失无法确定或损失扩大的，保险人有权对损失无法确定或扩大的部分拒绝赔偿。
    </p>
    <p>
      &#12288;&#12288;被保险人应在知道发生资金损失的24小时内通知保险人，并书面说明事故发生的原因、经过和损失程度；<span>故意或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险人对无法确定的部分，不承担保险责任，</span>但保险人通过其他途径已经及时知道或者应当及时知道保险事故发生的除外。
    </p>
    <p>
      &#12288;&#12288;（二）尽力采取必要、合理的措施，防止或减少损失，否则，<span
        >对因此扩大的损失，保险人不承担赔偿责任；</span
      >
    </p>
    <p>
      &#12288;&#12288;（三）保护事故现场，允许并且协助保险人进行事故调查；<span
        >对于拒绝或者妨碍保险人进行事故调查导致无法确定事故原因或核实损失情况的，保险人对无法确定或核实的部分不承担赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;第十六条 被保险人向保险人申请索赔时，必须提供下列单证：
    </p>
    <p>&#12288;&#12288;（一）保险单或保险凭证；</p>
    <p>&#12288;&#12288;（二）与个人账户被盗刷、盗用、复制等相关的交易记录；</p>
    <p>
      &#12288;&#12288;（三）有关损失资金的流向记录，比如涉及转账，需提供收款方姓名及账号等信息；
    </p>
    <p>&#12288;&#12288;（四）身份证明；</p>
    <p>&#12288;&#12288;（五）账户挂失或冻结时间证明；</p>
    <p>&#12288;&#12288;（六）公安机关证明；</p>
    <p>
      &#12288;&#12288;（七）投保人或被保险人所能提供的，与索赔有关的、必要的，并能证明损失性质、原因和程度的其他证明和资料。
    </p>
    <p>
      &#12288;&#12288;保险人认为被保险人提供的有关索赔的证明和资料不完整的，应当及时一次性通知投保人、被保险人补充提供。<span
        >被保险人未履行前款约定的索赔材料提供义务，导致保险人无法核实损失情况的，保险人对无法核实的部分不承担赔偿责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;第十七条
      发生保险责任范围内的损失，应由有关责任方负责赔偿的，保险人自向被保险人赔偿保险金之日起，在赔偿金额范围内代位行使被保险人对有关责任方请求赔偿的权利，被保险人应当向保险人提供必要的文件和所知道的有关情况。
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;被保险人已经从有关责任方取得赔偿的，保险人赔偿保险金时，可以相应扣减被保险人已从有关责任方取得的赔偿金额。
    </p>
    <p class="font-weight-bold">
      &#12288;&#12288;保险事故发生后，在保险人未赔偿保险金之前，被保险人放弃对有关责任方请求赔偿权利的，保险人不承担赔偿责任；保险人向被保险人赔偿保险金后，被保险人未经保险人同意放弃对有关责任方请求赔偿权利的，该行为无效；由于被保险人故意或者因重大过失致使保险人不能行使代位请求赔偿的权利的，保险人可以扣减或者要求返还相应的保险金。
    </p>
    <p>
      &#12288;&#12288;第十八条
      保险事故发生时，如果存在重复保险，保险人按照本合同的相应保险金额与其他保险合同及本合同相应保险金额总和的比例承担赔偿责任。
    </p>
    <p>
      &#12288;&#12288;<span>其他保险人应承担的赔偿金额，本保险人不负责垫付。</span>若被保险人未如实告知导致保险人多支付赔偿金的，保险人有权向被保险人追回多支付的部分。
    </p>
    <p>
      &#12288;&#12288;第十九条
      被保险人向保险人请求赔偿保险金的诉讼时效期间为二年，自其知道或者应当知道保险事故发生之日起计算。
    </p>

    <p class="font-weight-bold text-align">
      &#12288;&#12288;争议处理和法律适用
    </p>
    <p>
      &#12288;&#12288;第二十条
      因履行本合同发生的争议，由当事人协商解决。协商不成的，提交保险单载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向人民法院起诉。
    </p>
    <p>
      &#12288;&#12288;第二十一条
      与本合同有关的以及履行本合同产生的一切争议，适用中华人民共和国法律（不包括港澳台地区法律）。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>
      &#12288;&#12288;第二十二条
      本合同成立后，投保人可要求解除本合同。投保人要求解除本合同的，应当向保险人提出书面申请，本合同自保险人收到书面申请时终止。
    </p>
    <p>
      &#12288;&#12288;第二十三条
      本合同成立后，保险人根据保险法规定或者本合同约定要求解除本合同的，除保险法另有规定或本合同另有约定外，本合同自解除通知送达投保人最后所留通讯地址时终止。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>
      &#12288;&#12288;第二十四条 除另有约定外，本合同中的下列词语具有如下含义：
    </p>
    <p>&#12288;&#12288;保险人：是指中国太平洋财产保险股份有限公司。</p>
    <p>
      &#12288;&#12288;微信银行：指银行建立在手机微信平台上，可提供支付、查询等服务功能的银行业务服务方式。
    </p>
    <p>&#12288;&#12288;保险事故：是指本合同中约定的保险责任范围内的事故。</p>
    <p>
      &#12288;&#12288;未满期保险费：是指保险人应退还的剩余保险期间的保险费，未满期保险费按照以下公式计算：
    </p>
    <p>
      &#12288;&#12288;未满期保险费=保险费×（剩余保险期间天数/保险期间天数）×（保险金额－累计赔偿金额）/保险金额
    </p>
    <p>
      &#12288;&#12288;其中，累计赔偿金额是指在实际保险期间内，保险人已支付的保险赔偿金和已发生保险事故但还未支付的保险赔偿金之和。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class PersonalPropertyClause extends Vue {
   static name = "personal-property-clause";
}
export default PersonalPropertyClause;
</script>
<style lang="scss" src="./index.scss" scoped></style>
