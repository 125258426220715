<template>
  <div class="document">
    <p class="font-weight-bold text-align">
      &#12288;&#12288;中国太平洋财产保险股份有限公司
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;附加个人特定传染病住院津贴保险（互联网）条款
    </p>
    <p class="text-align">&#12288;&#12288;注册号：C00001432522021121011963</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;总则</p>
    <p>
      &#12288;&#12288;第一条
      本保险合同附加于各类个人人身保险合同（以下简称“主险合同”），依主险合同投保人的申请，经保险人审核同意而订立。主险合同效力终止，本保险合同效力亦终止；主险合同无效，本保险合同亦无效。主险合同与本保险合同相抵触之处，以本保险合同为准。本保险合同未约定事项，以主险合同为准。
    </p>
    <p>
      &#12288;&#12288;第二条
      凡主险合同的被保险人，均可作为本保险合同的被保险人。
    </p>
    <p>
      &#12288;&#12288;第三条
      除另有约定外，本保险合同的特定传染病住院津贴以及确诊保险责任受益人为被保险人本人，传染病身故保险金受益人为被保险人的继承人或依法享有保险金请求权的其他自然人。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>
      &#12288;&#12288;第四条
      在本保险合同保险期间内，保险人按下列约定承担保险责任：
    </p>
    <p>
      &#12288;&#12288;以下特定传染病住院津贴保险责任、特定传染病确诊保险责任和特定传染病身故保险责任等待期为15天（含）或者根据保险单约定的等待期（被保险人连续投保不受此限）
    </p>
    <p>&#12288;&#12288;<span>一、特定传染病住院津贴保险责任</span></p>
    <p>
      &#12288;&#12288;被保险人首次投保或非连续投保并自本附加险合同被指定医疗机构的专科医生确诊初次罹患《中华人民共和国传染病防治法》所规定的特定一种或多种甲类或乙类传染病（包括突发原因不明的传染病需要采取甲类传染病的预防控制措施的）并住院治疗，保险人按每次住院发生的合理住院天数扣除保险单载明的每次住院免赔天数后，乘以保险单载明的特定传染病住院津贴日额，向被保险人给付特定传染病住院津贴。具体计算公式如下：
    </p>
    <p>
      &#12288;&#12288;特定传染病住院津贴保险金=（实际住院日数-免赔天数）×特定传染病住院津贴日额
    </p>
    <p>
      &#12288;&#12288;特定传染病住院津贴日额由您与我们约定，并在保险单上载明。
    </p>
    <p>
      &#12288;&#12288;<span
        >在本合同的保险期间内，我们一次或累计给付特定传染病住院津贴保险金日数达到90天时或者保单约定的天数（最长不超过180天），本合同对该被保险人的该项保险责任终止。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >如果被保险人在保险期间内因本合同约定的特定传染病进行住院治疗，至保险期间届满住院治疗仍未结束的，本公司继续承担上述所列的保险责任至住院结束，但特定传染病住院津贴保险金的给付最长可至保险期间届满之日起第30日止。</span
      >
    </p>
    <p>&#12288;&#12288;<span>二、特定传染病确诊保险责任（可选）</span></p>
    <p>
      &#12288;&#12288;被保险人首次投保或非连续投保并自本附加险合同约定的等待期后（被保险人连续投保不受此限）被指定医疗机构的专科医生确诊初次罹患《中华人民共和国传染病防治法》所规定的特定一种或多种甲类或乙类传染病（包括突发原因不明的传染病需要采取甲类传染病的预防控制措施的），我们按本合同约定的特定传染病确诊保险金额给付特定传染病确诊保险金，本合同对该被保险人的该项保险责任终止。
    </p>
    <p>&#12288;&#12288;<span>三、特定传染病身故保险责任（可选）</span></p>
    <p>
      &#12288;&#12288;被保险人首次投保或非连续投保并自本附加险合同被指定医疗机构的专科医生确诊初次罹患《中华人民共和国传染病防治法》所规定的特定一种或多种甲类或乙类传染病（包括突发原因不明的传染病需要采取甲类传染病的预防控制措施的）导致身故，保险人给付特定传染病身故保险金，本附加险合同对该被保险人的保险责任终止。
    </p>
    <p>
      &#12288;&#12288;<span
        >上述责任为可选，如未在保险单中列明，保险人不承担赔偿责任。</span
      >
    </p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;责任免除</p>
      <p>&#12288;&#12288;第五条 本保险合同的责任免除如下：</p>
      <p>&#12288;&#12288;一、主险合同无效或失效，保险人不承担保险责任。</p>
      <p>
        &#12288;&#12288;二、主险合同中列明的“责任免除”事项，未列入本保险合同保险责任的，也适用于本保险合同。
      </p>
      <p>&#12288;&#12288;三、在下列情形下，保险人不承担保险责任：</p>
      <p>&#12288;&#12288;（一）投保人故意造成被保险人身故或疾病；</p>
      <p>
        &#12288;&#12288;（二）被保险人故意自伤、自杀、故意犯罪或抗拒依法采取的刑事强制措施；
      </p>
      <p>
        &#12288;&#12288;（三）被保险人投保前已有的身体损害、未告知既往症的合并症及并发症，以及保险单特别约定除外的疾病；
      </p>
      <p>&#12288;&#12288;（四）被保险人违法、犯罪行为；</p>
      <p>&#12288;&#12288;（五）被保险人未遵医嘱私自服用、涂用、注射药物；</p>
      <p>
        &#12288;&#12288;（六）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；
      </p>
      <p>
        &#12288;&#12288;（七）被保险人先天性畸形、变形和染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD－10）》为准）；
      </p>
      <p>
        &#12288;&#12288;（八）被保险人患性病、精神和行为障碍（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD－10）》为准）；
      </p>
      <p>&#12288;&#12288;（九）被保险人患职业病；</p>
      <p>
        &#12288;&#12288;（十）被保险人未经释义医院或疾病预防控制机构确诊感染法定传染病的，非本合同约定类型的特定传染病；
      </p>
      <p>&#12288;&#12288;（十一）本保险合同约定保险责任范围以外的其他情形。</p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额和保险费</p>
    <p>&#12288;&#12288;第六条 保险金额是保险人承担给付保险金责任的最高限额。</p>
    <p>
      &#12288;&#12288;本保险合同的特定传染病住院津贴日额，特定传染病确诊保险金额，特定传染病身故保险金额，由投保人和保险人双方协商确定，并在保险合同中载明。
    </p>
    <p>&#12288;&#12288;投保人应该按照合同约定向保险人交纳保险费。</p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险期间</p>
    <p>
      &#12288;&#12288;第七条
      除双方另有约定外，本保险合同保险期间为一年，以保险单载明的起讫时间为准。
    </p>
    <p class="font-weight-bold text-align">
      &#12288;&#12288;投保人解除保险合同
    </p>
    <p>
      &#12288;&#12288;第八条
      若投保人解除主险合同，本保险合同一并解除，合同解除后，保险人参照主险合同有关规定退还本保险合同的未满期净保险费。如投保人申请解除本保险合同，须填写解除合同申请书并向保险人提供下列资料：
    </p>
    <p>&#12288;&#12288;一、保险合同；</p>
    <p>&#12288;&#12288;二、投保人的有效身份证件。</p>
    <p>
      &#12288;&#12288;自保险人收到解除合同申请书时起，本保险合同终止。保险人自收到解除合同申请书之日起30日内向投保人退还本保险合同的未满期净保险费。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>&#12288;&#12288;第九条 注意事项如下：</p>
    <p>
      &#12288;&#12288;一、被保险人须在指定医疗机构治疗。急救不受此限，但经急救情况稳定后，须转入规定级别或指定医疗机构治疗。
    </p>
    <p>
      &#12288;&#12288;二、被保险人因医疗条件限制，确需转院治疗，必须有转出医院主治医师以上级别人员签署的会诊报告及转院证明。
    </p>
    <p>
      &#12288;&#12288;三、本保险合同所记载事项，如与主险合同相抵触之处，以本保险合同为准，未尽事宜，适用主险合同的规定。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金申请与给付</p>
    <p>
      &#12288;&#12288;第十条
      保险金申请人向保险人申请给付保险金时，应提交以下材料。<span
        >保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。保险金申请人未能提供有关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span
      >
    </p>
    <p>
      &#12288;&#12288;<span
        >一、特定传染病住院津贴保险金申请、特定传染病确诊保险金申请</span
      >
    </p>
    <p>&#12288;&#12288;（一）保险合同或电子保险单号；</p>
    <p>&#12288;&#12288;（二）保险金给付申请书、保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;（三）指定医疗机构出具的附有初诊门急诊病历、病理显微镜检查报告、血液检查及其他科学方法检验报告的病史资料及疾病诊断报告书；
    </p>
    <p>
      &#12288;&#12288;（四）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>&#12288;&#12288;<span>二、特定传染病身故保险金申请</span></p>
    <p>&#12288;&#12288;（一）保险合同或电子保险单号；</p>
    <p>&#12288;&#12288;（二）保险金给付申请书、保险金申请人的身份证明；</p>
    <p>
      &#12288;&#12288;（三）指定医疗机构出具的附有初诊门急诊病历、病理显微镜检查报告、血液检查及其他科学方法检验报告的病史资料及疾病诊断报告书；
    </p>
    <p>
      &#12288;&#12288;（四）公安部门出具的被保险人户籍注销证明、指定医疗机构出具的被保险人因确诊罹患特定传染病身故证明书；
    </p>
    <p>
      &#12288;&#12288;（五）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；
    </p>
    <p>
      &#12288;&#12288;（六）若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监护人代为申领保险金，并需要提供监护人的身份证明等资料。
    </p>
    <p>
      &#12288;&#12288;保险人认为保险金申请人提供的有关索赔的证明和资料不完整的，将及时一次性通知补充提供。
    </p>
    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>&#12288;&#12288;第十一条 释义：</p>
    <p>
      &#12288;&#12288;保险人：指与投保人签订本保险合同的中国太平洋财产保险股份有限公司。
    </p>
    <p>
      &#12288;&#12288;特定传染病：指《中华人民共和国传染病防治法》所规定的特定甲类或乙类传染病（包括突发原因不明的传染病需要采取甲类传染病的预防控制措施的）。
    </p>
    <p>&#12288;&#12288;专科医生：专科医生应当同时满足以下三项资格条件：</p>
    <p>&#12288;&#12288;一、具有有效的中华人民共和国《医师资格证书》；</p>
    <p>
      &#12288;&#12288;二、具有有效的中华人民共和国《医师执业证书》，并按期到相关部门登记注册；
    </p>
    <p>
      &#12288;&#12288;三、具有有效的中华人民共和国主治医师或主治医师以上职称的《医师职称证书》。
    </p>
    <p>
      &#12288;&#12288;住院：指被保险人因特定传染病而入住医院的病房进行治疗，并办理入出院正式手续，不包括入住门诊观察室、家庭病床及其它不合理的住院。
    </p>
    <p>
      &#12288;&#12288;每次住院：指被保险人因特定传染病住院治疗，自入院日起至出院日止的期间，但若因同一原因再次住院，且前次出院与下次入院间隔未超过30天，视为同一次住院。
    </p>
    <p>
      &#12288;&#12288;<span>指定医疗机构：</span>中国境内（不含香港、澳门和台湾地区）经国家卫生行政管理部门正式评定的公立二级以上（含二级）医院。
    </p>
    <p>
      &#12288;&#12288;指定医疗机构必须具有符合国家有关医院管理规则设置标准的医疗设备，且全天二十四小时有合格医师及护士驻院提供医疗及护理服务。不包括疗养院，护理院，康复中心以及无相应医护人员或设备的二级或二级以上的联合医院或联合病房。
    </p>
    <p>
      &#12288;&#12288;未满期净保险费：“保险费×[1-（保单已经过天数/保险期间天数）]×（1-费用比例）”。经过天数不足一天的按一天计算。费用比例同主险规定。
    </p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component
class AccidentInfectious extends Vue {
  static name = "accident-infectious";
}
export default AccidentInfectious;
</script>
<style lang="scss" src="./index.scss" scoped></style>
